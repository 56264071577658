import { Component } from "react";

class mappedInHelper extends Component {
  constructor(props) {
    super(props);
    this.mapView = [];
    this.venue = [];
    this.search = [];
    this.analytics = [];
    let $this = this;
    this.polygonedLocations = [];
    this.polygonedLocationsById = [];

    // Track which polygon belongs to which location
    this.locationsByPolygon = {};
    this.mapList = document.getElementsByClassName("map-level");
    this.mapsSortedByElevation = [];
    this.div = document.getElementsByClassName("map-content")[0];
    this.mapExpanded = false;

    this.loadLevels = props.levelCallback;
    this.loadStoreInfo = props.storeCallback;
    // options for Mappedin.getVenue
    // You will need to customize this with the data provided by Mappedin. Ask your representative if you don't have a key, secret, and slug.
    this.venueOptions = {
      // clientId: "Butuze6gohti9ieVaem1Shah6ji2feik" ,
      // clientSecret: "om0IeMur7Aixiem5",
      // venue: "qic-canberra-centre"
      perspective: "Website",
      things: {
        venue: ["slug", "name"],
        locations: [
          "name",
          "type",
          "description",
          "icon",
          "logo",
          "sortOrder",
          "externalId"
        ],
        categories: ["name"],
        maps: ["name", "elevation", "shortName"]
      },
      ...this.props.map
    };

    this.mapviewOptions = {
      antialias: "AUTO",
      mode: "TEST",
      onFirstMapLoaded: function() {
        console.log("First map fully loaded. No more pop in.");
      },
      onDataLoaded: function() {
        console.log(
          "3D data loaded, map usable. Could hide loading screen here, but things will be popping in. Now you can do things that interact with the 3D scene"
        );
        $this.onDataLoaded();
      }
    };

    // Options for search
    this.searchOptions = {
      key: "",
      secret: ""
    };

    // Combined all of them to use Mappedin.initalize
    this.options = {
      mapview: this.mapviewOptions,
      venue: this.venueOptions,
      search: this.searchOptions
    };

    console.log("mappedIn initiated....");

    this.init();
  }

  init() {
    this.Mappedin = window.Mappedin;
    let $this = this;
    this.Mappedin.initialize(this.options, this.div).then(
      function(data) {
        $this.mapView = data.mapview;
        $this.venue = data.venue;
        $this.search = data.search;
        $this.analytics = data.analytics;
      },
      function(error) {
        // window.alert("Mappedin " + error)
      }
    );
  }

  onPolygonClicked(polygonId) {
    this.mapView.clearAllPolygonColors();
    this.mapView.navigator.hideOverview();
    this.mapView.setPolygonColor(polygonId, this.mapView.colors.select);
    //this.mapView.focusOnPolygon(polygonId, true);
    console.log(polygonId + " clicked");
    let location = this.locationsByPolygon[polygonId];
    if (location != null) {
      console.log(
        location.name + " - " + location.externalId + " was selected."
      );
      this.analytics.locationSelected(location);
      this.loadStoreInfo(location.externalId);
      // this.loadStoreInfo(location.name);
    }
    return false;
  }

  onNothingClicked() {
    console.log("onNothingClicked");
    this.clearMap();
  }

  clearMap() {
    this.mapView.clearAllPolygonColors();
    this.mapView.navigator.hideOverview();
  }

  onDataLoaded() {
    this.mapView.controls.setTilt(1);
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      this.mapView.controls.setTilt(0);
    }
    this.mapView.controls.setZoom(4000);
    this.mapView.onPolygonClicked = this.onPolygonClicked.bind(this);
    this.mapView.onNothingClicked = this.onNothingClicked.bind(this);
    var locations = this.venue.locations;
    for (var j = 0, jLen = locations.length; j < jLen; ++j) {
      var location = locations[j];

      if (location.polygons.length > 0) {
        this.polygonedLocations.push(location);
        if (location.externalId) {
          this.polygonedLocationsById[location.externalId] = location;
        }
      }

      var locationPolygons = location.polygons;
      for (var k = 0, kLen = locationPolygons.length; k < kLen; ++k) {
        var polygon = locationPolygons[k];

        // A polygon may be attached to more than one location. If that is the case for your venue,
        // you will need some way of determinng which is the "primary" location when it's clicked on.
        var oldLocation = this.locationsByPolygon[polygon.id];
        if (oldLocation == null || oldLocation.sortOrder > location.sortOrder) {
          this.locationsByPolygon[polygon.id] = location;
        }
      }
    }

    this.mapView.addInteractivePolygonsForAllLocations(this.venue.locations);

    let newLevelData = [];
    let maps = this.venue.maps;
    for (var m = 0, mLen = maps.length; m < mLen; ++m) {
      let map = maps[m];
      // if (mapId == mapView.currentMap) {
      //     item.selected = true
      // }
      let newLevel = {
        levelId: map.id,
        shortLabel: map.shortName,
        longLabel: map.name
      };

      newLevelData.push(newLevel);
    }
    this.loadLevels(newLevelData);
    this.mapsSortedByElevation = this.venue.maps.sort(
      (a, b) => b.elevation - a.elevation
    );

    this.mapView.labelAllLocations({
      excludeTypes: [] // If there are certain Location types you don't want to have labels (like amenities), exclude them here)
    });
  }

  // Changes the map and updates the Map List
  setMap(map) {
    this.mapList.selectedIndex = this.mapList.namedItem(map).index;
    this.mapView.setMap(map);
  }

  // Changes the map in response to a Map List selection
  changeMap(mapId) {
    this.mapView.setMap(mapId, function() {
      console.log("Map changed to " + mapId);
    });
  }

  // // Returns list of maps used in directions, sorted by elevation
  // getMapsInJourney(directions) {
  //     let uniqueMapHash = {};
  //     directions.instructions.forEach((direction) => {
  //         uniqueMapHash[direction.node.map] = true;
  //     })
  //     let mapIds = new Array();
  //     for (let key in uniqueMapHash) {
  //         mapIds.push(key);
  //     }
  //     let sortedMapIds = this.mapsSortedByElevation.filter(map => mapIds.indexOf(map.id) !== -1);
  //     return sortedMapIds;
  // }

  focusOnStore(storeId) {
    // var location = this.polygonedLocationsById[storeId];
    //   if (location) {
    //       this.mapView.clearAllPolygonColors();
    //       for (var p = 0, pLen = location.polygons.length; p < pLen; p++) {
    //         this.mapView.setPolygonColor(location.polygons[p].id, this.mapView.colors.select);
    //       }
    //       this.mapView.focusOn({ polygons: location.polygons });
    //       this.analytics.locationSelected(location);
    //   }
    return false;
  }

  // showDirection(sourceId, destinationId, isAccessible) {
  //     let sourceLocation = this.polygonedLocationsById[sourceId];
  //     let destinationLocation = this.polygonedLocationsById[destinationId];

  //     //Options for drawing paths
  //     let pathOptions = {
  //         drawConnectionSegigments: true,
  //         connectionPathOptions: {
  //             color: this.mapView.colors.path
  //         }
  //     };

  //     let expandOptions = {
  //         focus: true,
  //         rotation: 0,
  //         duration: 600
  //     };

  //     // Some polygons don't have entrance nodes, need to check before getting directions
  //     if (sourceLocation != null && destinationLocation != null) {
  //         let $this = this;
  //         sourceLocation.directionsTo(destinationLocation, { accessible: isAccessible, directionsProvider: "offline" }, function (error, directions) {
  //             if (error || directions.path.length == 0) {
  //                 console.error(error);
  //                 return
  //             }

  //             $this.mapView.clearAllPolygonColors()
  //             if ($this.mapView.navigator.overviewVisible === true) {
  //                 $this.mapView.navigator.hideOverview()
  //             }

  //             for (var p = 0, pLen = sourceLocation.polygons.length; p < pLen; p++) {
  //                 $this.mapView.setPolygonColor(sourceLocation.polygons[p].id, $this.mapView.colors.path);
  //             }
  //             for (var p = 0, pLen = destinationLocation.polygons.length; p < pLen; p++) {
  //                 $this.mapView.setPolygonColor(destinationLocation.polygons[p].id, $this.mapView.colors.select);
  //             }

  //             try {
  //                 $this.mapView.navigator.setScale(1);
  //                 $this.mapView.navigator.showOverview(directions, { pathOptions, expandOptions })
  //                     .catch(e => console.error(e))
  //             } catch (e) {
  //                 console.error(e)
  //             }
  //         })
  //     } else {
  //         console.error('No entrances found for polygons');;
  //     }
  // }

  zoomIn() {
    this.mapView.controls.zoomIn();
  }

  zoomOut() {
    this.mapView.controls.zoomOut();
  }

  resetCamera() {
    this.mapView.resetCamera();
  }
}

export default mappedInHelper;
