import React from "react";
import { Text, RichText, Link } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";

const FeatureHeaderRetailerGenericPageHeader = props => {
  const {
    Subtext = null,
    Title = null,
    HeroCTA = null,
    SectionId = null
  } = props.fields || {};
  if (Title && Subtext) {
    return (
      <BaseComponent
        sectionId={SectionId && SectionId.value ? SectionId.value : ""}
      >
        <div className="retailer-generic-header-wrapper">
          <div className="title">
            <Text field={Title} />
          </div>
          <div className="subtext">
            <RichText field={Subtext} />
          </div>
          {HeroCTA && HeroCTA.value && HeroCTA.value.href &&
            <div className="button-wrapper">
              <Link
                field={HeroCTA}
                // onClick={() =>
                //   TagManager.dataLayer({
                //     dataLayer: {
                //       event: "clickedModuleCta",
                //       moduleName: "Generic Retailer Hero",
                //       moduleCtaLink:
                //         (HeroCTA &&
                //           HeroCTA.value &&
                //           HeroCTA.value.href) ||
                //         "",
                //     },
                //     dataLayerName: "dataLayer",
                //   })
                // }
                className="btn btn-large btn-light-primary"
              >
                <span className="btn-label">
                  <Text field={{ value: HeroCTA.value.text }} />
                </span>
                <i className="icon icon-chevron-up-2" />
              </Link>
            </div>
          }
        </div>
      </BaseComponent >
    );
  } else {
    return null;
  }
};

export default FeatureHeaderRetailerGenericPageHeader;
