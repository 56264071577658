import React from "react";

const LeasingStepComplete = props => {
  return (
    <div className="leasing-complete-wrapper">
      <div className="form-wrapper">
        <div className="container">
          <div className="row">
            <div className="col col-lg-7 title complete">
              Thanks {props.formData.firstName}. We'll be in contact with you
              soon.
            </div>
          </div>
          <div className="row">
            <div className="col col-lg-7 content">
              In the meantime, you can learn more about our business and what we do.
            </div>
          </div>
          <div className="row">
            <div className="col col-lg-7 learn-more">
              <a
                href={props.learnMore && props.learnMore.href ? props.learnMore.href : ""}
                className="btn btn-dark-secondary btn-small step-complete-btn">
                Learn more
              </a>
              <u
                onClick={() => {
                  props.onDone();
                }}>Done</u>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeasingStepComplete;
