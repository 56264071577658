import React, { Component, Fragment } from "react";

class FormDropdown extends Component {
    render() {
        return (
            <Fragment>
                <ul className="dropdown-list">
                    {
                        this.props.options && this.props.options.length &&
                        this.props.options.map((item, i) => {
                            return (
                                <li className={this.props.active === item.name ? "active" : ""} key={`optionId${i}`} onClick={() => this.props.selected(item.value)}>
                                    <span>{item.name}</span>
                                </li>
                            )
                        })
                    }
                </ul>
            </Fragment>
        );
    }
}

export default FormDropdown;
