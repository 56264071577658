import React from "react";
import { Text, Image } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import defaultImg from "../../../../assets/image/transparent.png";

class FeatureRetailerStoryAuthor extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      AuthorName = null,
      RoleCompany = null,
      Group = null,
      Picture = null
    } = this.props.fields || {};

    return (
      <BaseComponent>
        {
          (AuthorName || RoleCompany || Group || Picture) && (
            <div className="retailerstory-author">
              <div className="container">
                <div className="wrapper">
                  <div
                    className={`picture ${
                      Picture && Picture.value && Picture.value.src ? "show" : ""
                      }`}
                  >
                    <Image
                      media={{ ...Picture.value, src: defaultImg }}
                      data-src={Picture.value.src}
                      className="lazyload"
                    />
                  </div>
                  <div className="details">
                    <div className="name">
                      <Text field={AuthorName} />
                    </div>
                    <div className="role">
                      <Text field={RoleCompany} />
                    </div>
                    <div className="group">
                      <Text field={Group} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </BaseComponent>
    );
  }
}

export default FeatureRetailerStoryAuthor;
