import React from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";

class FeatureRetailerStoryStoryDetails extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      Title = null,
      Description = null,
      Category = null,
      PublishedDate = null,
      isAlternateFont = null
    } = this.props.fields || {};

    const markproFont = 'markpro';
    const requiresAlternateFont = isAlternateFont && isAlternateFont.value

    return (
      <BaseComponent>
        <div className="retailerstory-storydetails">
          <div className="title">
            <Text field={Title} />
          </div>
          <div className="category-and-date">
            <div className="category">
              <Text field={Category} />
            </div>
            {(Category &&
              Category.value &&
              PublishedDate &&
              PublishedDate.value) &&
              <span>|</span>
            }
            <div className="date">
              <Text field={PublishedDate} />
            </div>
          </div>
          <div className="description" style={{ fontFamily:  requiresAlternateFont && markproFont, lineHeight: requiresAlternateFont && '1.5'}}> 
             <RichText field={Description} />
          </div>
        </div>
      </BaseComponent>
    );
  }
}

export default FeatureRetailerStoryStoryDetails;
