import React, { Component } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import _ from "lodash";
import defaultImg from "../../../../assets/image/transparent.png";
import TagManager from "react-gtm-module";

class FeatureImageGalleryRetailerImageGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ImageGalleryListRaw: props.fields.ImageGalleryList,
      ImageGalleryList: [],
      activeItem: 0,
      viewedItem: 0,
    };
    this.scrollWrapper = React.createRef();
    this.minImage = +this.props.params.MinimumNumberOfImages;
    this.handleScroll = _.debounce(this.handleScroll, 150);
    this.prevScrollLeft = 0;
  }

  componentDidMount() {
    let data = [];
    for (let i = 0; i < this.state.ImageGalleryListRaw.length; i++) {
      if (
        i < this.minImage &&
        this.state.ImageGalleryListRaw[i] &&
        this.state.ImageGalleryListRaw[i].fields
      ) {
        if (
          this.state.ImageGalleryListRaw[i].fields.MediaType.value.indexOf(
            "video"
          ) > -1 ||
          this.state.ImageGalleryListRaw[i].fields.MediaType.value.indexOf(
            "image"
          ) > -1
        ) {
          data.push(this.state.ImageGalleryListRaw[i]);
        }
      } else {
        break;
      }
    }

    this.setState(
      {
        ImageGalleryList: data,
        activeItem: 1,
      },
      () => {
        if (
          this.state.ImageGalleryList &&
          this.state.ImageGalleryList.length >= 2 &&
          this.scrollWrapper.current
        ) {
          this.scrollWrapper.current.scrollLeft = 0;
        }
      }
    );

    window.onresize = function () {
      let imageGalleryWrapper = document.querySelector(".image-gallery-wrapper");
      if (imageGalleryWrapper) {
        imageGalleryWrapper.querySelector(".row").scrollLeft = 0;
      }
    };
  }

  handleScroll(scroll, e) {
    let activeItem = this.state.activeItem;
    if (window.outerWidth <= 767) {
      let activeScroll = 0;
      if (this.prevScrollLeft < scroll.current.scrollLeft) {
        activeScroll = Math.floor((scroll.current.scrollLeft + 285) / 295);
      } else {
        activeScroll = Math.floor(scroll.current.scrollLeft / 295);
      }
      scroll.current.scrollLeft = activeScroll * 295;
      this.setState({ activeItem: activeScroll + 1 });
      this.prevScrollLeft = scroll.current.scrollLeft;
      if (
        activeItem !== this.state.activeItem &&
        this.state.activeItem > this.state.viewedItem
      ) {
        this.setState({ viewedItem: this.state.activeItem });
        TagManager.dataLayer({
          dataLayer: {
            event: "imageGalleryScroll",
            imageGalleryItem:
              this.state.activeItem + "/" + this.state.ImageGalleryList.length,
          },
          dataLayerName: "dataLayer",
        });
      }
      return false;
    }

    if (scroll.current.scrollLeft == 0) {
      this.setState({ activeItem: 1 });
      this.prevScrollLeft = scroll.current.scrollLeft;
    } else {
      if (
        scroll.current.scrollLeft > this.prevScrollLeft &&
        this.state.activeItem < this.state.ImageGalleryList.length
      ) {
        /* handle scroll left */
        this.prevScrollLeft = scroll.current.scrollLeft;
        this.setState({ activeItem: activeItem + 1 });
      } else if (
        scroll.current.scrollLeft < this.prevScrollLeft &&
        this.state.activeItem === this.state.ImageGalleryList.length
      ) {
        this.prevScrollLeft = scroll.current.scrollLeft;
        this.setState({ activeItem: this.state.ImageGalleryList.length - 1 });
      } else if (
        scroll.current.scrollLeft < this.prevScrollLeft &&
        this.state.activeItem >= 1
      ) {
        /* handle scroll right */
        this.setState({ activeItem: activeItem - 1 });
        this.prevScrollLeft = scroll.current.scrollLeft;
      }
    }

    if (this.state.activeItem > this.state.viewedItem) {
      this.setState({ viewedItem: this.state.activeItem });
      TagManager.dataLayer({
        dataLayer: {
          event: "imageGalleryScroll",
          imageGalleryItem:
            this.state.activeItem + "/" + this.state.ImageGalleryList.length,
        },
        dataLayerName: "dataLayer",
      });
    }
  }

  scroll = (e, direction) => {
    if (direction === "left" && this.state.activeItem > 1) {
      this.displayImage(this.state.activeItem - 2);
    } else if (
      direction === "right" &&
      this.state.activeItem < this.state.ImageGalleryList.length
    ) {
      this.displayImage(this.state.activeItem);
    }
  };

  renderList = (withCaption) => {
    return this.state.ImageGalleryList.map((item, index) => {
      const { Caption, MediaType, Url, Height, Width } = item.fields;
      return (
        <div
          className={
            "image-gallery-tile" +
            (this.state.ImageGalleryList.length < 3
              ? " below-three-image"
              : "") +
            ((Height || Width) && parseInt(Height.value) > parseInt(Width.value)
              ? " is-vertical"
              : " is-horizontal")
          }
          key={`tileId-${index}`}
          onClick={(e) => this.gotoPicture(e, index)}
        >
          {MediaType.value.indexOf("video") > -1 && (
            <video controls>
              <source src={Url.value} type="video/mp4" />
              <source src={Url.value} type="video/ogg" />
              <source src={Url.value} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          )}
          {MediaType.value.indexOf("image") > -1 && (
            <img
              alt={Caption && Caption.value ? Caption.value : ""}
              src={defaultImg}
              data-src={Url.value}
              className="lazyload"
            />
          )}
          <div
            className={`${this.state.ImageGalleryList.length <= 2
              ? "no-scrollbar"
              : "has-scrollbar"
              } ${withCaption && !withCaption.value
                ? "no-image-caption"
                : Caption && Caption.value
                  ? "image-caption"
                  : "no-image-value"
              }`}
          >
            {withCaption && withCaption.value && <Text field={Caption} />}
          </div>
        </div>
      );
    });
  };

  setVisible = (imageGalleryList) => {
    if (
      !this.props.rendering.dataSource ||
      this.props.rendering.dataSource === "" ||
      imageGalleryList === null ||
      !imageGalleryList.length ||
      imageGalleryList.length === 0
    ) {
      return "hide";
    }
    return "show";
  };

  gotoPicture = (e, index) => {
    this.displayImage(index);
  };

  displayImage = (index) => {
    let totalScroll = 0;
    let addNum;

    if (window.outerWidth >= 768 && window.outerWidth < 992) {
      addNum = 13;
    } else if (window.outerWidth >= 992 && window.outerWidth < 1200) {
      addNum = 26;
    } else if (window.outerWidth >= 1200) {
      addNum = 30;
    } else {
      addNum = 15;
    }
    for (let i = 0; i < index; i++) {
      let imageTile = document
        .getElementsByClassName("image-gallery-wrapper")[0]
        .querySelector(".row")
        .querySelectorAll(".image-gallery-tile");
      totalScroll = totalScroll + imageTile[i].clientWidth + addNum;
    }
    this.scrollWrapper.current.scrollLeft = totalScroll;
  };

  displayItemBar(activeItem) {
    const total = activeItem * (100 / this.state.ImageGalleryList.length);
    return (
      <hr
        className="line"
        style={{
          backgroundImage: `-webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${total}%, rgb(48, 48, 48)), color-stop(${total}%, rgb(150, 150, 150)))`,
          background: `-ms-linear-gradient(left, rgba(48,48,48,1) ${total}%, rgba(150,150,150,1) ${total}%, rgba(150,150,150,1) 100%)`,
        }}
      />
    );
  }

  render() {
    const {
      SectionId = null,
      WithCaption = null,
      ImageGalleryList = null,
      BackgroundColor = null
    } = this.props.fields || {};
    let { RemoveDefaultMargin = "" } = this.props.params || {};
    let margin =
      RemoveDefaultMargin && RemoveDefaultMargin === "1" ? "" : "standard";

    return (
      this.setVisible(ImageGalleryList) === "show" && (
        <BaseComponent
          setVisible={this.setVisible(ImageGalleryList)}
          margin={margin}
          sectionId={SectionId && SectionId.value ? SectionId.value : ""}
        >
          <div className={`image-gallery-wrapper 
          ${BackgroundColor && BackgroundColor.value.toLowerCase() === "white" ? "is-bg-white" : ""}
          `}>
            <div className="container">
              <div
                className="row"
                ref={this.scrollWrapper}
                onScroll={(e) => this.handleScroll(this.scrollWrapper, e)}
              >
                {this.renderList(WithCaption)}
              </div>
              {this.state.ImageGalleryList &&
                this.state.ImageGalleryList.length >= 2 && (
                  <div
                    className={
                      "scroll-controls " +
                      (this.state.ImageGalleryList.length <= 2
                        ? "hide-controls "
                        : "") +
                      (WithCaption && WithCaption.value != true
                        ? "no-image-caption"
                        : "")
                    }
                  >
                    <div className="scroll-control-left">
                      <button
                        disabled={this.state.activeItem === 1 ? true : false}
                        className="btn btn-link"
                        type="button"
                        onClick={(e) => this.scroll(e, "left")}
                      >
                        <i className="icon icon-chevronleft" />
                      </button>
                    </div>
                    <div className="scroll-control-center">
                      <span>
                        {this.state.activeItem < 10 ? "0" : ""}
                        {this.state.activeItem}
                      </span>
                      {this.displayItemBar(this.state.activeItem)}
                      <Text
                        field={{
                          value: `${this.state.ImageGalleryList.length < 10 ? "0" : ""
                            }${this.state.ImageGalleryList.length}`,
                        }}
                      />
                    </div>
                    <div className="scroll-control-right">
                      <button
                        disabled={
                          this.state.activeItem ===
                            this.state.ImageGalleryList.length
                            ? true
                            : false
                        }
                        className="btn btn-link"
                        type="button"
                        onClick={(e) => this.scroll(e, "right")}
                      >
                        <i className="icon icon-chevronright" />
                      </button>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </BaseComponent>
      )
    );
  }
}

export default FeatureImageGalleryRetailerImageGallery;
