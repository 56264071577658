import React, { Fragment, createRef } from "react";
import LeasingStep1 from "./steps/LeasingStep1";
import LeasingStep2 from "./steps/LeasingStep2";
import LeasingStep3 from "./steps/LeasingStep3";
import LeasingStep4 from "./steps/LeasingStep4";
import StepComplete from "./steps/StepComplete";
import MediaStep1 from "./steps/MediaStep1";
import MediaStep2 from "./steps/MediaStep2";
import TagManager from "react-gtm-module";
import axios from "axios";
import { getUrlParameter } from "../../../../helper/UrlHelper.js";

class FeatureEnquireForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formStep: 1,
      formData: {
        formType: "", // leasing or media
        // step 1
        leaseDuration: "",
        advertisingOpportunity: "",
        // step 2
        country: "",
        state: "",
        preferredCentre: "",
        // step 3
        spaceRequirement: "",
        // step 4
        firstName: "",
        lastName: "",
        contactPreference: "email",
        email: "",
        phone: "",
        businessName: "",
        retailCategory: "",
        existingRetailer: "no",
        leasedWithQicgre: "no",
        advertisedWithQicgre: "no",
        tellUs: "",
        attachments: [],
        subscribe: "",
        LeasingRecipients: "",
        PageItemId: "",
        PageDbName: ""
      },
      formFieldData: {
        countries: [],
        states: {},
        preferredCentres: {},
        retailerCategories: [],
        centreContactNumber: {},
        centreRecipients: {}
      },
      submitted: false,
      fileErrorMessage: null,
      verifiedRecaptcha: false,
      expiredRecaptcha: false,
      load: false,
      recaptchaLoaded: false,
      formatValidation: {
        phone: "^[0-9 +()-]+$",
        email: "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$"
      }
    };
    this.enquireModal = createRef();
    this.enquireModalMedia = createRef();
  }

  componentDidMount() {
    const {
      Countries = null,
      Assets = null,
      RetailerCategories = null,
    } = this.props.fields || {};
    this.extractFromFieldData(Countries, Assets, RetailerCategories);

    if (document) {
      let enquireType = getUrlParameter("enquire").toLowerCase();
      if (enquireType) {
        let modal = "";
        if (enquireType === "leasing") {
          modal = document.getElementsByClassName("modal-enquire-form-leasing")[0];
          if (modal) {
            modal.classList.add("enquire-old");
            modal.style.display = "block";
          }
        } else if (enquireType === "media") {
          modal = document.getElementsByClassName("modal-enquire-form-media")[0];
          if (modal) {
            modal.style.display = "block";
          }
        }
      }
    }
  }

  handleCloseButton() {
    let modal = "";
    let enquiryType = "leasing";

    if (
      this.enquireModalMedia &&
      this.enquireModalMedia.current &&
      this.enquireModalMedia.current.classList.contains("enquire-modal-open")
    ) {
      enquiryType = "media";
    }
    TagManager.dataLayer({
      dataLayer: {
        event: "enquiryFormDropout",
        enquiryType: enquiryType,
        stepDropout: this.state.formStep
      },
      dataLayerName: "dataLayer"
    });

    modal = document.getElementsByClassName("modal-enquire-form-leasing")[0];
    modal.style.display = "none";
    modal.classList.remove("enquire-old");

    modal = document.getElementsByClassName("modal-enquire-form-media")[0];
    modal.style.display = "none";

    // let country = this.Model.get("Countries").map((item, i) => {
    //   if (i === 0) return item.fields.Name.value;
    // });

    // reset all states
    this.setState({
      ...this.state,
      formStep: 1,
      formData: {
        formType: "",
        leaseDuration: "",
        advertisingOpportunity: "",
        country: this.state.formData.country,
        state: this.state.formData.state,
        preferredCentre: this.state.formData.preferredCentre,
        spaceRequirement: "",
        firstName: "",
        lastName: "",
        contactPreference: "email",
        email: "",
        phone: "",
        businessName: "",
        retailCategory: "",
        existingRetailer: "no",
        leasedWithQicgre: "no",
        advertisedWithQicgre: "no",
        tellUs: "",
        attachments: [],
        subscribe: "",
        PageItemId: this.state.formData.PageItemId,
        PageDbName: this.state.formData.PageDbName
      },
      submitted: false,
      fileErrorMessage: null,
      verifiedRecaptcha: false,
      expiredRecaptcha: false,
      load: false,
      recaptchaLoaded: false,
    });
    // leasing
    if (
      this.enquireModal &&
      this.enquireModal.current &&
      this.enquireModal.current.classList.contains("enquire-modal-open")
    ) {
      this.enquireModal.current.classList.remove("enquire-modal-open");

      if (this.enquireModal.current.ownerDocument) {
        this.enquireModal.current.ownerDocument.body.classList.remove(
          "scroll-disabled-body"
        );
      }
    }
    // media
    if (
      this.enquireModalMedia &&
      this.enquireModalMedia.current &&
      this.enquireModalMedia.current.classList.contains("enquire-modal-open")
    ) {
      this.enquireModalMedia.current.classList.remove("enquire-modal-open");

      if (this.enquireModalMedia.current.ownerDocument) {
        this.enquireModalMedia.current.ownerDocument.body.classList.remove(
          "scroll-disabled-body"
        );
      }
    }
  }

  validateFormat(expression, value) {
    let re = new RegExp(expression);
    return re.test(String(value).toLowerCase());
  }

  handleStepChange(step, data) {
    if (
      (this.state.formData.formType === "leasing" && step === 5) ||
      (this.state.formData.formType === "media" && step === 3)
    ) {
      let isValid = true;
      if (
        !this.state.formData.firstName ||
        !this.state.formData.lastName ||
        !this.state.formData.email ||
        !this.state.formData.phone ||
        !this.state.formData.businessName
      ) {
        isValid = false;
      }

      if (
        this.state.formData.email &&
        !this.validateFormat(
          this.state.formatValidation.email,
          this.state.formData.email
        )
      ) {
        isValid = false;
      }

      if (
        this.state.formData.phone &&
        !this.validateFormat(
          this.state.formatValidation.phone,
          this.state.formData.phone
        )
      ) {
        isValid = false;
      }

      if (!isValid) {
        this.setState({
          submitted: true
        });
        return;
      }
    }

    const withData = data || {};

    this.setState(
      {
        ...this.state,
        formStep: step,
        formData: {
          ...this.state.formData,
          ...withData
        }
      },
      () => {
        if (
          (this.state.formData.formType === "leasing" &&
            this.state.formStep === 5) ||
          (this.state.formData.formType === "media" &&
            this.state.formStep === 3)
        ) {
          this.submitForm();
        }
      }
    );
  }

  handleFormDataChange(obj, fileErrorMessage) {
    this.setState({
      ...this.state,
      formData: {
        ...this.state.formData,
        ...obj
      },
      fileErrorMessage: fileErrorMessage
    });
  }

  extractFromFieldData(Countries, Assets, RetailerCategories) {
    let countries = [];
    let states = {};
    let preferredCentres = {};
    let centreContactNumber = {};
    let centreRecipients = {};
    let retailerCategories = [];
    let initialState = "";
    let initialCountry = "";
    let initialCentre = "";
    let initialAsset = "";

    if (window && window.location) {
      initialAsset = window.location.pathname
        .substr(window.location.pathname.lastIndexOf("/") + 1)
        .replace(/-/g, " ")
        .replace(/%20/g, " ");
    }

    Countries.map(country => {
      const { Name, States } = country.fields || {};

      // build countries
      countries.push({
        name: Name.value,
        value: Name.value
      });

      // build states
      let tempCountryStates = [];
      States.map(countryState => {
        tempCountryStates.push({
          name: countryState.fields.Name.value,
          value: countryState.fields.Name.value
        });
      });
      states[Name.value] = tempCountryStates;
    });

    // build preferred centres
    Assets.map(asset => {
      const {
        AssetName,
        EnquiryContactNumber,
        Country,
        State,
        LeasingRecipients
      } = asset.fields || {};
      let stateName =
        State && State.value && State.value !== "" ? State.value : "others";

      preferredCentres[stateName] = !preferredCentres[stateName]
        ? []
        : preferredCentres[stateName];
      preferredCentres[stateName].push({
        name: AssetName.value,
        value: AssetName.value
      });
      centreContactNumber[AssetName.value] = {
        contact:
          EnquiryContactNumber && EnquiryContactNumber.value
            ? EnquiryContactNumber.value
            : ""
      };
      centreRecipients[AssetName.value] = {
        LeasingRecipients:
          LeasingRecipients && LeasingRecipients.value
            ? LeasingRecipients.value
            : ""
      };
      if (AssetName.value.toLowerCase() === initialAsset.toLowerCase()) {
        initialCountry = Country.value;
        initialState = State.value;
        initialCentre = AssetName.value;
      }
    });
    // build reatailer categories
    RetailerCategories.map(category => {
      const { Name } = category.fields || {};

      retailerCategories.push({
        name: Name.value,
        value: Name.value
      });
    });

    let contextInfo = window.document.getElementsByName("ContextItem")[0];
    let PageItemId = contextInfo.value;
    let PageDbName = contextInfo.dataset.targetDb;

    this.setState({
      ...this.state,
      formFieldData: {
        countries,
        states,
        preferredCentres,
        retailerCategories,
        centreContactNumber,
        centreRecipients
      },
      formData: {
        ...this.state.formData,
        PageItemId,
        PageDbName,
        country: initialCountry ? initialCountry : countries[0].value,
        state: initialState,
        preferredCentre: initialCentre
      }
    });
  }

  getRecipient = () => {
    const { preferredCentre, leaseDuration } = this.state.formData;
    const { Assets = null } = this.props.fields || {};
    const selectedAsset = Assets.filter(
      item => item.fields.AssetName.value === preferredCentre
    );

    if (selectedAsset) {
      const {
        LeasingRecipients,
        ShortTermLeasingRecipients
      } = selectedAsset[0].fields;
      if (
        leaseDuration.toLowerCase() === "less than 12 months" &&
        ShortTermLeasingRecipients.value
      ) {
        return ShortTermLeasingRecipients.value;
      }
      return LeasingRecipients.value;
    }
  };

  submitForm = async () => {
    const url = `/api/sitecore/enquiry/sendenquiryformemail`;

    let postData = { ...this.state.formData };

    const formData = new FormData();
    Object.keys(postData).forEach(function (key) {
      if (key !== "attachments" && key !== "LeasingRecipients")
        return formData.append(key, postData[key]);
    });
    if (postData.preferredCentre) {
      formData.append("LeasingRecipients", this.getRecipient());
    }
    postData.attachments.map(file => {
      formData.append("attachments", file);
    });

    let response = await axios({
      method: "POST",
      url,
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } }
    });

    var withInput = 0;
    var completionRate;
    let duration = postData.leaseDuration;
    let checkFields = ["retailCategory", "subscribe", "attachments"];
    let emptyFields = "";

    if (postData.formType.toLowerCase() === "leasing") {
      checkFields.push("state", "preferredCentre");
      duration && duration.toLowerCase().indexOf("less") !== -1
        ? (duration = "Short term leasing")
        : (duration = "Long term leasing");
    } else {
      checkFields.push("tellUs");
    }

    checkFields.map(item => {
      if (postData[item] === true || postData[item].length) {
        withInput++;
      } else {
        emptyFields += " " + item;
      }
    });

    completionRate = ((withInput / checkFields.length) * 100).toFixed(2) + "%";

    TagManager.dataLayer({
      dataLayer: {
        event: "enquiryFormSubmit",
        enquiryType: postData.formType,
        enquiryCategory: duration ? duration : postData.advertisingOpportunity,
        enquiryCompletionRate: completionRate,
        enquiryEmptyFields: emptyFields
      },
      dataLayerName: "dataLayer"
    });

    if (response) {
      const { data } = response.data;
    }
  };

  render() {
    const {
      Static = null,
      Partnership = null,
      Digital = null,
      LearnMore = null,
      GoogleRecaptchaConfig = null
    } = this.props.fields || {};

    const {
      verifiedRecaptcha,
      expiredRecaptcha,
      load,
      recaptchaLoaded,
    } = this.state;

    console.log(GoogleRecaptchaConfig);

    return (
      <Fragment>
        <div
          className="enquire-form-wrapper modal-enquire-form-leasing"
          ref={this.enquireModal}
        >
          <div className="container">
            <div
              className={
                "row navigation-section " +
                (this.state.formStep === 1 || this.state.formStep === 5
                  ? "justify-content-end "
                  : "justify-content-between ") +
                (this.state.formStep === 2
                  ? "justify-content-end-new"
                  : "")
              }
            >
              {this.state.formStep !== 1 && this.state.formStep !== 5 && (
                <button
                  className="btn back-btn"
                  onClick={() => {
                    this.handleStepChange(this.state.formStep - 1);
                  }}
                >
                  <i className="icon icon-chevronleft" />
                  <span>BACK</span>
                </button>
              )}
              <button
                className="btn close-btn"
                onClick={() => {
                  this.handleCloseButton();
                }}
              >
                <span>CLOSE</span>
                <i className="icon icon-close" />
              </button>
            </div>
          </div>
          {this.state.formStep === 1 && (
            <LeasingStep1
              onStepChange={(step, data) => {
                this.handleStepChange(step, data);
              }}
              onFormDataChange={obj => {
                this.handleFormDataChange(obj);
              }}
            />
          )}
          {this.state.formStep === 2 && (
            <LeasingStep2
              onStepChange={step => {
                this.handleStepChange(step);
              }}
              countries={this.state.formFieldData.countries}
              states={
                this.state.formFieldData.states[this.state.formData.country]
              }
              preferredCentres={
                this.state.formFieldData.preferredCentres[
                this.state.formData.state
                ]
              }
              centreRecipients={this.state.formFieldData.centreRecipients}
              onFormDataChange={obj => {
                this.handleFormDataChange(obj);
              }}
              formData={this.state.formData}
            />
          )}
          {this.state.formStep === 3 && (
            <LeasingStep3
              onStepChange={(step, data) => {
                this.handleStepChange(step, data);
              }}
              onFormDataChange={obj => {
                this.handleFormDataChange(obj);
              }}
            />
          )}
          {this.state.formStep === 4 && (
            <LeasingStep4
              onStepChange={step => {
                this.handleStepChange(step);
              }}
              onFormDataChange={(obj, fileErrorMessage) => {
                this.handleFormDataChange(obj, fileErrorMessage);
              }}
              categories={this.state.formFieldData.retailerCategories}
              submitted={this.state.submitted}
              formatValidation={this.state.formatValidation}
              formData={this.state.formData}
              centreContactNumber={
                this.state.formData.preferredCentre &&
                  this.state.formFieldData.centreContactNumber[
                  this.state.formData.preferredCentre
                  ] &&
                  this.state.formFieldData.centreContactNumber[
                    this.state.formData.preferredCentre
                  ].contact
                  ? this.state.formFieldData.centreContactNumber[
                    this.state.formData.preferredCentre
                  ].contact
                  : ""
              }
              fileErrorMessage={this.state.fileErrorMessage}
              recapConfig={GoogleRecaptchaConfig}
            />
          )}
            
          {this.state.formStep === 5 && (
            <StepComplete
              formData={this.state.formData}
              onDone={() => {
                this.handleCloseButton();
              }}
            />
          )}
        </div>
        <div
          className="enquire-form-wrapper modal-enquire-form-media"
          ref={this.enquireModalMedia}
        >
          <div className="container">
            <div
              className={
                "row navigation-section " +
                (this.state.formStep === 1 || this.state.formStep === 3
                  ? "justify-content-end"
                  : "justify-content-between")
              }
            >
              {this.state.formStep !== 1 && this.state.formStep !== 3 && (
                <button
                  className="btn"
                  onClick={() => {
                    this.handleStepChange(this.state.formStep - 1);
                  }}
                >
                  <i className="icon icon-chevronleft" />
                  BACK
                </button>
              )}
              <button
                className="btn"
                onClick={() => {
                  this.handleCloseButton();
                }}
              >
                CLOSE
                <i className="icon icon-close" />
              </button>
            </div>
            {this.state.formStep === 1 && (
              <MediaStep1
                onStepChange={(step, data) => {
                  this.handleStepChange(step, data);
                }}
                onFormDataChange={obj => {
                  this.handleFormDataChange(obj);
                }}
                staticImage={Static && Static.value ? Static.value : ""}
                partnershipImage={Partnership && Partnership.value ? Partnership.value : ""}
                digitalImage={Digital && Digital.value ? Digital.value : ""}
                learnMore={LearnMore && LearnMore.value ? LearnMore.value : ""}
                formData={this.state.formData}
              />
            )}
            {this.state.formStep === 2 && (
              <MediaStep2
                onStepChange={step => {
                  this.handleStepChange(step);
                }}
                onFormDataChange={(obj, fileErrorMessage) => {
                  this.handleFormDataChange(obj, fileErrorMessage);
                }}
                categories={this.state.formFieldData.retailerCategories}
                submitted={this.state.submitted}
                formatValidation={this.state.formatValidation}
                formData={this.state.formData}
                centreContactNumber={
                  this.state.formData.preferredCentre &&
                    this.state.formFieldData.centreContactNumber[
                    this.state.formData.preferredCentre
                    ] &&
                    this.state.formFieldData.centreContactNumber[
                      this.state.formData.preferredCentre
                    ].contact
                    ? this.state.formFieldData.centreContactNumber[
                      this.state.formData.preferredCentre
                    ].contact
                    : ""
                }
                fileErrorMessage={this.state.fileErrorMessage}
              />
            )}
            {this.state.formStep === 3 && (
              <StepComplete
                formData={this.state.formData}
                onDone={() => {
                  this.handleCloseButton();
                }}
                learnMore={LearnMore && LearnMore.value ? LearnMore.value : ""}
              />
            )}
          </div>
        </div>
      </Fragment>
      // </BaseComponent>
    );
  }
}

export default FeatureEnquireForm;
