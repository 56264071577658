import React, { Component } from "react";
import { Text, Image, Link } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import Button from "../../../Base/Button";
import defaultImg from "../../../../assets/image/transparent.png";
import TagManager from "react-gtm-module";

class FeatureLeadershipLeadershipListing extends Component {
  constructor(props) {
    super(props);
    this.leadershiplisting = React.createRef();
    this.hideButton = false;
  }

  state = {
    hideButton: false,
  };

  showmorelist = () => {
    this.setState({ hideButton: true });
    this.leadershiplisting.current.classList.remove("leadershiplisting");
    TagManager.dataLayer({
      dataLayer: {
        event: "clickedModuleCta",
        moduleName: "Leadership Listing",
        moduleCtaLink: "View More",
      },
      dataLayerName: "dataLayer",
    });
  };
  render() {
    const { LeadershipList = null, ShowMore = null } = this.props.fields || {};
    return (
      <BaseComponent margin="standard">
        <div className="container">
          <div
            className="leadership-listing-container leadershiplisting"
            ref={this.leadershiplisting}
          >
            {LeadershipList &&
              LeadershipList.length &&
              LeadershipList.map((items, index) => {
                return (
                  <div
                    className="leadership-listing-content-wrapper"
                    key={"leadershiplistingID" + index}
                  >
                    <div className="image-range">
                      <div className="thumbnail">
                        <Image
                          media={{
                            ...items.fields.ProfileImage.value,
                            src: defaultImg,
                          }}
                          data-src={items.fields.ProfileImage.value.src}
                          className="listing-image lazyload"
                        />
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-name">
                        <Text field={items.fields.ProfileName} />
                      </div>
                      <div className="listing-sub-name">
                        <Text field={items.fields.ProfilePosition} />
                      </div>
                      <div className="listing-description">
                        <Text field={items.fields.ProfileBio} />
                      </div>
                      <div className="listing-profile-link">
                        <Link
                          field={{
                            href: items.fields.ProfileLinkedIn.value,
                            target: "_blank",
                          }}
                        >
                          <i className="icon icon-linkedin" />
                        </Link>
                        <span className="text">
                          <Link
                            field={{
                              href: items.fields.ProfileLinkedIn.value,
                              target: "_blank",
                            }}
                          >
                            <Text field={items.fields.ViewProfileText} />
                          </Link>
                        </span>
                        <Link
                          field={{
                            href: items.fields.ProfileLinkedIn.value,
                            target: "_blank",
                          }}
                        >
                          <i className="icon icon-external" />
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}

            <div className="show-more">
              <Button
                color={"light-secondary"}
                size={"medium"}
                onClick={this.showmorelist}
                className={
                  "show-more-button" +
                  (this.state.hideButton ? " hidden" : "")
                }
              >
                <Text field={ShowMore} />
              </Button>
            </div>
          </div>
        </div>
      </BaseComponent>
    );
  }
}
export default FeatureLeadershipLeadershipListing;
