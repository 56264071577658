import React, { Component } from "react";
import _ from "lodash";

class FormFile extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    files: this.props.value,
    errorMessage: null
  };

  handleAttachments = () => {
    this.props.handleAttachments(this.state.files, this.state.errorMessage);
  };

  setList = files => {
    if (files && files.length > 0) {
      let lists = [];
      let max = 0;
      for (let i = 0; i < files.length; i++) {
        if (files[i] && files[i].type === "application/pdf") {
          if (files[i].size <= 5242880) {
            lists.push(files[i]);
          } else max++;
        } else {
          this.setState(
            {
              errorMessage:
                "One or more files could not be uploaded. Only files with pdf extension is allowed."
            },
            this.handleAttachments
          );
        }
      }
      if (max > 0) {
        this.setState(
          {
            files: _.values(lists),
            errorMessage:
              "One or more files could not be uploaded. The maximum file size allowed is 5MB."
          },
          this.handleAttachments
        );
      } else {
        this.setState(
          { files: _.values(lists), errorMessage: null },
          this.handleAttachments
        );
      }
    } else {
      return null;
    }
    // else {
    //   if (files[0] && files[0].type === "application/pdf") {
    //     if (files[0].size <= 5250000) {
    //       this.setState(
    //         { files: _.values(files), errorMessage: null },
    //         this.handleAttachments
    //       );
    //     } else {
    //       this.setState(
    //         {
    //           errorMessage:
    //             "The file could not be uploaded. The maximum file size allowed is 5MB."
    //         },
    //         this.handleAttachments
    //       );
    //     }
    //   } else if (files.length === 0) {
    //     return null;
    //   } else {
    //     this.setState(
    //       {
    //         errorMessage:
    //           "The file could not be uploaded. Only files with pdf extension is allowed."
    //       },
    //       this.handleAttachments
    //     );
    //   }
    // }
  };

  removeFile(filename) {
    const { files } = this.state;
    let newList = files.filter(file => file.name !== filename);
    this.setState(
      { files: newList, errorMessage: null },
      this.handleAttachments
    );
  }

  renderList = () => {
    const { files } = this.state;
    if (files.length) {
      let list = [];
      for (let i = 0; i < files.length; i++) {
        const element = files[i];
        list.push(element);
      }
      return (
        <div className="file-list">
          {list.map((item, index) => {
            return (
              <React.Fragment key={`fileId-${index}`}>
                <div className="file-item">
                  <i className="icon left icon-file" />
                  <div className="name">{item.name}</div>
                  <div className="remove">
                    <i
                      className="icon right icon-close"
                      onClick={() => this.removeFile(item.name)}
                    />
                  </div>
                </div>
                <br />
              </React.Fragment>
            );
          })}
        </div>
      );
    }
  };

  render() {
    return (
      <div className="form-file">
        <div className="file-btn">
          <label className="btn btn-dark-secondary btn-small">
            Add attachments
            <input
              type="file"
              hidden
              multiple
              accept="application/pdf"
              onChange={e => this.setList(e.target.files)}
            />
          </label>
        </div>
        {this.renderList()}
      </div>
    );
  }
}

export default FormFile;
