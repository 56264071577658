import React from "react";

const handleNextStep = (onStepChangeFunc, step, spaceRequirement) => {
  let obj = { spaceRequirement: spaceRequirement };
  onStepChangeFunc(step, obj);
};

const LeasingStep3 = props => {
  return (
    <div className="form-wrapper">
      <div className="container">
        <div className="row">
          <div className="col step is-old">STEP 3 OF 4</div>
          <div className="col step is-new">STEP 2 OF 3</div>
        </div>
        <div className="row">
          <div className="col title">
            What's your minimum space requirement?
          </div>
        </div>
        <div className="row submit-section justify-content-start">
          <div className="col col-12 col-lg-2 leasing-btn-wrapper">
            <button
              className="btn btn-dark-secondary btn-block double-line leasing-step-3"
              onClick={() => {
                handleNextStep(props.onStepChange, 4, "Small");
              }}
            >
              Small
              <br />
              Under 100m²
            </button>
          </div>
          <div className="col col-12 col-lg-2 leasing-btn-wrapper">
            <button
              className="btn btn-dark-secondary btn-block double-line leasing-step-3"
              onClick={() => {
                handleNextStep(props.onStepChange, 4, "Medium");
              }}
            >
              Medium
              <br />
              100-200m²
            </button>
          </div>
          <div className="col col-12 col-lg-2 leasing-btn-wrapper">
            <button
              className="btn btn-dark-secondary btn-block double-line leasing-step-3"
              onClick={() => {
                handleNextStep(props.onStepChange, 4, "Large");
              }}
            >
              Large
              <br />
              More than 200m²
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeasingStep3;
