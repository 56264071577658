import React from "react";
import { Text, Link } from "@sitecore-jss/sitecore-jss-react";
import TagManager from "react-gtm-module";

const Button = (props) => {
  /**
   * Color - light-primary, light-secondary, dark-primary, dark-secondary
   * Size - small, medium, large
   **/
  let {
    onClick = null,
    label = null,
    color = null,
    size = null,
    icon = null,
    className = null,
    disabled = false,
    isLink = false,
    linkField = null,
    moduleName = null,
  } = props || {};

  let buttonLayout = "";

  if (isLink) {
    buttonLayout = (
      <Link
        field={linkField}
        onClick={() => {
          if (moduleName) {
            TagManager.dataLayer({
              dataLayer: {
                event: "clickedModuleCta",
                moduleName,
                moduleCtaLink:
                  (linkField && linkField.value && linkField.value.href) || "",
              },
              dataLayerName: "dataLayer",
            });
          }
        }}
        className={`btn
                    ${color ? "btn-" + color : "btn-light-primary"}
                    ${size ? "btn-" + size : "btn-large"}
                    ${className ? className : ""}
                `}
      >
        {props.children && <span className="btn-label">{props.children}</span>}
        {label && <span className="btn-label">{label}</span>}
        {icon && <i className={`icon icon-${icon}`} />}
      </Link>
    );
  } else {
    if (onClick) {
      buttonLayout = (
        <button
          className={`btn
                    ${color ? "btn-" + color : "btn-light-primary"}
                    ${size ? "btn-" + size : "btn-large"}
                    ${className ? className : ""}
                `}
          onClick={onClick}
          disabled={disabled}
        >
          {props.children && (
            <span className="btn-label">{props.children}</span>
          )}
          {label && <span className="btn-label">{label}</span>}
          {icon && <i className={`icon icon-${icon}`} />}
        </button>
      );
    }
  }

  return buttonLayout;
};

export default Button;
