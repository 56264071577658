import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const SecondaryLinks = props => {
  const {
    Title: title = null,
    Links: links = null,
    FileDownloads: fileDownloads = null
  } = props.data || {}
  const linkValue = links || fileDownloads || {};
  return (
    <div className="secondarylinks-wrapper">
      <div className="title">
        <Text field={title} />
      </div>
      {linkValue.map((item, index) => {
        let {
          Name: Name = null,
          Url: Url = null,
          LinkText: LinkText = null,
          LinkUrl: LinkUrl = null
        } = item.fields || {};
        if (Name === null && Url === null) {
          Name = LinkText;
          Url = LinkUrl.value;
        } else {
          Url = Url.value;
        }
        return (
          <div key={`myId-${index}`} className="item">
            <a
              href={Url}
              target="_blank"
              rel="noopener noreferrer"
              className="dl-i"
            >
              <i
                className={`icon icon-download ${fileDownloads ? "" : "d-none"}`}
              />
            </a>
            <a href={Url} target="_blank" rel="noopener noreferrer">
              <Text field={Name} />
            </a>
          </div>
        );
      })}
    </div>
  );
};

export default SecondaryLinks;
