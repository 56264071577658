export const displayPageHeader = (path) => {
  if (path === "/") {
    return false;
  }
  const pages = [
    "/places",
    "/places/",
    // "/properties",
    // "/properties/",
    // "/properties2",
    // "/properties2/",
    "/disclaimer",
    "/disclaimer/",
    "/privacy-policy",
    "/privacy-policy/",
    "/privacy%20policy",
    "/privacy%20policy/",
    "/search",
    "/search/",
  ];

  for (let idx = 0; idx < pages.length; idx++) {
    if (pages[idx].match(path)) return false;
  }

  return true;
};
