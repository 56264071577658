import React, { Component } from "react";
import FormRadioGroup from "../../../../Base/Form/FormRadiogroup";
import FormFile from "../../../../Base/Form/FormFile";
import FormDropdown from "../../../../Base/Form/FormDropDown";
import $ from "jquery";
// window.$ = $;

class MediaStep2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formError: {
        firstName: true,
        lastName: true,
        email: true,
        phone: true,
        businessName: true,
      },
      show: false,
      showField: null,
    };
  }

  componentDidMount() {
    $(".modal-enquire-form-media").scrollTop(0);
    const formInput = document
      .getElementsByClassName("enquire-form-wrapper")[0]
      .querySelectorAll("input");
    if (formInput) {
      Array.prototype.slice.call(formInput).map((formInput, index) => {
        // For all major browsers, except IE 8 and earlier
        if (formInput.addEventListener) {
          formInput.addEventListener("focus", this.handleOnInputFocus);
          // For IE 8 and earlier versions
        } else if (formInput.attachEvent) {
          formInput.attachEvent("onfocus", this.handleOnInputFocus);
        }
      });
    }
  }

  handleOnInputFocus = (e) => {
    if (window.outerWidth < 992) {
      const inputElem = e.target;
      inputElem.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  checkFormError = () => {
    let error = false;
    for (let key in this.state.formError) {
      if (this.state.formError[key]) {
        error = true;
      }
    }
    return error;
  };

  resetFormError = () => {
    for (let key in this.state.formError) {
      this.state.formError[key] = true;
    }
  };

  handleNextStep = (onStepChangeFunc, step) => {
    let valid = true;
    // validate form before changing step
    if (valid) {
      onStepChangeFunc(step);
    }
  };

  handleInputChange = (
    onFormDataChange,
    e,
    field,
    required,
    validate,
    expression
  ) => {
    let obj = field;
    obj[Object.keys(obj)[0]] = e.target.value;
    onFormDataChange(obj);

    if (required && !e.target.value) {
      this.state.formError[Object.keys(obj)[0]] = true;
    } else if (validate && !this.validateFormat(expression, e.target.value)) {
      this.state.formError[Object.keys(obj)[0]] = true;
    } else {
      this.state.formError[Object.keys(obj)[0]] = false;
    }
  };

  handleSelectChange = (onFormDataChange, value, field) => {
    let obj = field;
    obj[Object.keys(obj)[0]] = value;
    onFormDataChange(obj);
  };

  handleRadioChange = (onFormDataChange, optionValue, field) => {
    let obj = field;
    obj[Object.keys(obj)[0]] = optionValue;
    onFormDataChange(obj);
  };

  handleAttachments = (onFormDataChange, attachments, errorMessage) => {
    onFormDataChange({ attachments: attachments }, errorMessage);
  };

  validateFormat = (expression, value) => {
    let re = new RegExp(expression);
    return re.test(String(value).toLowerCase());
  };

  handleToggle = (e) => {
    this.setState({ show: !this.state.show });
  };

  handleBlur = (e) => {
    if (this.state.show) {
      setTimeout(() => {
        this.setState({ show: !this.state.show });
      }, 100);
    }
  };

  render() {
    if (!this.props.submitted) {
      this.resetFormError();
    }
    return (
      <div className="media-step-two-wrapper">
        <div className="form-wrapper">
          <div className="container">
            <div className="row">
              <div className="col step">STEP 2 OF 2</div>
            </div>
            <div className="row">
              <div className="col title">A few more details...</div>
            </div>
            <div className="row field-section">
              <div className="col col-12 col-lg-5">
                {/* call now button */}
                {this.props.centreContactNumber && (
                  <div>
                    <div className="field-label">
                      <label>Talk to a leasing expert directly</label>
                    </div>
                    <div className="field-button">
                      <a
                        className="btn btn-dark-secondary btn-small"
                        href={`tel:` + this.props.centreContactNumber}
                      >
                        Call now
                      </a>
                    </div>
                  </div>
                )}
                <div className="spacer-24" />
                {/* Contact details */}
                <div className="field-input">
                  <input
                    placeholder="*First name"
                    onChange={(e) => {
                      this.handleInputChange(
                        this.props.onFormDataChange,
                        e,
                        {
                          firstName: "",
                        },
                        true
                      );
                    }}
                    value={this.props.formData["firstName"]}
                    className={
                      this.props.submitted && !this.props.formData.firstName
                        ? "form-error"
                        : ""
                    }
                  />
                  {this.props.submitted && !this.props.formData.firstName ? (
                    <span>
                      <i className="icon icon-danger leasing-danger-icon">!</i>
                      <label className="form-control-validation">
                        First name is required
                      </label>
                    </span>
                  ) : null}
                </div>
                <div className="field-input">
                  <input
                    placeholder="*Last name"
                    onChange={(e) => {
                      this.handleInputChange(
                        this.props.onFormDataChange,
                        e,
                        {
                          lastName: "",
                        },
                        true
                      );
                    }}
                    value={this.props.formData["lastName"]}
                    className={
                      this.props.submitted && !this.props.formData.lastName
                        ? "form-error"
                        : ""
                    }
                  />
                  {this.props.submitted && !this.props.formData.lastName ? (
                    <span>
                      <i className="icon icon-danger leasing-danger-icon">!</i>
                      <label className="form-control-validation">
                        Last name is required
                      </label>
                    </span>
                  ) : null}
                </div>
                <div className="field-option-group space">
                  <FormRadioGroup
                    radioGroupName="Contact preference (mandatory)"
                    options={[
                      { name: "contact", value: "phone", label: "Phone" },
                      { name: "contact", value: "email", label: "Email" },
                    ]}
                    errorMessage="Please make a selection"
                    onChange={(optionValue) => {
                      this.handleRadioChange(
                        this.props.onFormDataChange,
                        optionValue,
                        {
                          contactPreference: "",
                        }
                      );
                    }}
                    value={this.props.formData["contactPreference"]}
                  />
                </div>
                <div className="field-input">
                  <input
                    placeholder="*Email"
                    onChange={(e) => {
                      this.handleInputChange(
                        this.props.onFormDataChange,
                        e,
                        { email: "" },
                        true,
                        true,
                        this.props.formatValidation.email
                      );
                    }}
                    value={this.props.formData["email"]}
                    className={
                      this.props.submitted && !this.props.formData.email
                        ? "form-error"
                        : ""
                    }
                  />
                  {this.props.submitted && !this.props.formData.email ? (
                    <span>
                      <i className="icon icon-danger leasing-danger-icon">!</i>
                      <label className="form-control-validation">
                        Email is required
                      </label>
                    </span>
                  ) : null}
                  {this.props.submitted &&
                  this.props.formData.email &&
                  !this.validateFormat(
                    this.props.formatValidation.email,
                    this.props.formData.email
                  ) ? (
                    <span>
                      <i className="icon icon-danger leasing-danger-icon">!</i>
                      <label className="form-control-validation">
                        Email format is invalid
                      </label>
                    </span>
                  ) : null}
                </div>
                <div className="field-input">
                  <input
                    placeholder="*Phone"
                    onChange={(e) => {
                      this.handleInputChange(
                        this.props.onFormDataChange,
                        e,
                        { phone: "" },
                        true,
                        true,
                        this.props.formatValidation.phone
                      );
                    }}
                    value={this.props.formData["phone"]}
                    className={
                      this.props.submitted && !this.props.formData.phone
                        ? "form-error"
                        : ""
                    }
                  />
                  {this.props.submitted && !this.props.formData.phone ? (
                    <span>
                      <i className="icon icon-danger leasing-danger-icon">!</i>
                      <label className="form-control-validation">
                        Phone is required
                      </label>
                    </span>
                  ) : null}
                  {this.props.submitted &&
                  this.props.formData.phone &&
                  !this.validateFormat(
                    this.props.formatValidation.phone,
                    this.props.formData.phone
                  ) ? (
                    <span>
                      <i className="icon icon-danger leasing-danger-icon">!</i>
                      <label className="form-control-validation">
                        Phone format is invalid
                      </label>
                    </span>
                  ) : null}
                </div>
                <div className="field-divider" />
                <div className="spacer-24" />
                <div className="field-input">
                  <input
                    placeholder="*Business name"
                    onChange={(e) => {
                      this.handleInputChange(
                        this.props.onFormDataChange,
                        e,
                        {
                          businessName: "",
                        },
                        true
                      );
                    }}
                    value={this.props.formData["businessName"]}
                    className={
                      this.props.submitted && !this.props.formData.businessName
                        ? "form-error"
                        : ""
                    }
                  />
                  {this.props.submitted && !this.props.formData.businessName ? (
                    <span>
                      <i className="icon icon-danger leasing-danger-icon">!</i>
                      <label className="form-control-validation">
                        Business name is required
                      </label>
                    </span>
                  ) : null}
                </div>
                <div className="dropdown-container">
                  <label className="arrow border-bottom">
                    <div className="dropdown-placeholder">
                      {this.props.formData["retailCategory"] && (
                        <label>Retail Category</label>
                      )}
                      <input
                        type="button"
                        value={
                          this.props.formData["retailCategory"]
                            ? this.props.formData["retailCategory"]
                            : "Choose a retail category"
                        }
                        className={`dropdown-btn ${
                          this.props.formData["retailCategory"]
                            ? ""
                            : "no-default-value"
                        }`}
                        onClick={this.handleToggle}
                        onBlur={this.handleBlur}
                        onChange={(e) => {
                          this.handleSelectChange(
                            this.props.onFormDataChange,
                            e.target.value,
                            { retailCategory: "" }
                          );
                        }}
                      />
                    </div>
                    <i className="icon icon-chevrondown" />
                  </label>
                  {this.state.show && (
                    <FormDropdown
                      selected={(value) => {
                        this.handleSelectChange(
                          this.props.onFormDataChange,
                          value,
                          { retailCategory: "" }
                        );
                      }}
                      options={this.props.categories}
                      active={this.props.formData["retailCategory"]}
                    />
                  )}
                </div>
                <div className="field-option-group">
                  <FormRadioGroup
                    radioGroupName="Have you ever advertised with QICRE?"
                    options={[
                      { name: "leased", value: "yes", label: "Yes" },
                      { name: "leased", value: "no", label: "No" },
                    ]}
                    errorMessage="Please make a selection"
                    onChange={(optionValue) => {
                      this.handleRadioChange(
                        this.props.onFormDataChange,
                        optionValue,
                        {
                          advertisedWithQicgre: "",
                        }
                      );
                    }}
                    value={this.props.formData["advertisedWithQicgre"]}
                  />
                </div>
                {/* <div className="field-divider" /> */}
                <div className="field-textarea">
                  <textarea
                    placeholder="Tell us how we can help"
                    onChange={(e) => {
                      this.handleInputChange(this.props.onFormDataChange, e, {
                        tellUs: "",
                      });
                    }}
                    value={this.props.formData["tellUs"]}
                    className=""
                  />
                </div>
                <div className="spacer-24" />
                {/* Attachements */}
                <div className="field-label">
                  <label className="desktop">
                    Attachments can help the team to better support you <br />
                    with your enquiry
                  </label>
                  <label className="mobile">
                    Attachments can help the team to better support you with
                    your enquiry
                  </label>
                </div>
                <div className="field-button">
                  <FormFile
                    handleAttachments={(files, errorMessage) => {
                      this.handleAttachments(
                        this.props.onFormDataChange,
                        files,
                        errorMessage
                      );
                    }}
                    value={this.props.formData["attachments"]}
                  />
                  {this.props.fileErrorMessage && (
                    <div className="attachment-error">
                      {this.props.fileErrorMessage}
                    </div>
                  )}
                </div>
                <div className="field-divider" />
                {/* Subscription */}
                <div className="field-checkbox">
                  <label className="checkbox-container">
                    Please subscribe me to the QICRE e-newsletter
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        this.handleRadioChange(
                          this.props.onFormDataChange,
                          e.target.checked,
                          { subscribe: "" }
                        );
                      }}
                      checked={this.props.formData["subscribe"]}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
              </div>
            </div>
            <div className="row submit-section">
              <div className="col-12 col-lg-auto">
                <button
                  className="btn btn-dark-secondary btn-large"
                  onClick={() => {
                    this.handleNextStep(this.props.onStepChange, 3);
                  }}
                >
                  Submit enquiry
                </button>
                {this.props.submitted && this.checkFormError() ? (
                  <div className="leasing-submit-error">
                    <i className="icon icon-danger leasing-danger-icon">!</i>
                    <label>Please complete all required fields.</label>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MediaStep2;
