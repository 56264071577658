import React from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";

class FeatureKeyStatsKeyStatsSummary extends React.Component {
  constructor(props) {
    super(props);
  }

  setVisible = (keyStats) => {
    if (
      !this.props.rendering.dataSource ||
      this.props.rendering.dataSource === "" ||
      keyStats.length < 3
    ) {
      return "hide";
    }
    return "show";
  };

  renderList = (keyStats) => {
    if (keyStats.length > 0) {
      let hasIcons = false
      let keyStatsLayout =
        keyStats.map((item, index) => {
          const {
            Title = null,
            Value = null,
            Prefix = null,
            Suffix = null,
            IconName = null,
          } = item.fields || {};
          let keyStatsSummaryLayout;

          if ((Title && Title.value) || (Value && Value.value)) {
            keyStatsSummaryLayout = (
              <div
                key={"keystats-summary-list" + index}
                className="col col-12 col-md-6 col-xl-4"
              >
                <div className="keystats-summary-list__item">
                  <div className="keystats-summary-list__details">
                    <div className="keystats-summary-list__icon">
                      <i className={`icon icon-${IconName && IconName.value ? IconName.value : ""}`} />
                    </div>
                    <div
                      className={`keystats-summary-list__text`}
                    >
                      {Value && Value.value && (
                        <div className="keystats-summary-list__value">
                          <span>
                            <Text field={Prefix} />
                          </span>
                          <Text field={Value} />
                          {Suffix && Suffix.value &&
                            <span className="keystats-summary-list__value--suffix">
                              <RichText
                                field={{
                                  value: Suffix.value.replace("2", `<sup>2</sup>`)
                                }}
                              />
                            </span>
                          }
                        </div>
                      )}
                      {Title && Title.value && (
                        <div className="keystats-summary-list__title">
                          <Text field={Title} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          }
          if (IconName && IconName.value) {
            hasIcons = true
          }
          return keyStatsSummaryLayout
        })

      return (
        <div className={`keystats-summary__content row ${hasIcons ? "" : "no-icons"}`}>
          {keyStatsLayout}
        </div>)
    } else return null;
  };

  render() {
    const {
      Title = null,
      Subtext = null,
      KeyStats = null,
      SectionId = null,
      MoveSubtextToBottom = null
    } = this.props.fields || {};
    let { RemoveDefaultMargin = "" } = this.props.params || {};
    let margin =
      RemoveDefaultMargin && RemoveDefaultMargin === "1" ? "" : "standard";

    return (
      this.setVisible(KeyStats) === "show" && (
        <BaseComponent
          setVisible={this.setVisible(KeyStats)}
          sectionId={SectionId && SectionId.value ? SectionId.value : ""}
          margin={margin}
        >
          <div className="keystats-summary">
            <div className="container">
              {Title && Title.value && (
                <div className="keystats-summary__header">
                  <Text field={Title} />
                </div>
              )}
              {Subtext && Subtext.value && MoveSubtextToBottom && !MoveSubtextToBottom.value && (
                <div className="keystats-summary__subheader">
                  <Text field={Subtext} />
                </div>
              )}
              {this.renderList(KeyStats)}
              {Subtext && Subtext.value && MoveSubtextToBottom && MoveSubtextToBottom.value && (
                <div className="keystats-summary__subheader keystats-summary__subheader--bottom">
                  <Text field={Subtext} />
                </div>
              )}
            </div>
          </div>
        </BaseComponent>
      )
    );
  }
}

export default FeatureKeyStatsKeyStatsSummary;
