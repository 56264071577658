import React, { Component } from "react";
import BaseComponent from "../../../Base/BaseComponent";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import ModuleHeader from "../../../Base/ModuleHeader";
import Button from "../../../Base/Button";
import defaultImg from "../../../../assets/image/transparent.png";
import TagManager from "react-gtm-module";

class FeatureRetailerStoryHighlightStories extends Component {
  constructor(props) {
    super(props);
    this.state = { gate: false };
  }


  showUpperCard = (highlightStories, title) => {
    const {
      StoryTitle: StoryTitle = null,
      StoryTags: StoryTags = null,
      StoryImageHero: StoryImageHero = null,
      StoryPublishDate: StoryPublishDate = null,
      PageLink: PageLink = null,
    } = highlightStories[0].fields || {};

    if (
      StoryImageHero &&
      StoryImageHero.value &&
      StoryTitle &&
      StoryTitle.value
    ) {
      return (
        <div className="heroheader">
          <div className="detail-image">
            {PageLink && PageLink.value ? (
              <a href={PageLink.value}>
                <img
                  className="lazyload"
                  src={defaultImg}
                  data-src={StoryImageHero.value}
                  alt="thumbnail"
                />
              </a>
            ) : (
              <img
                className="lazyload"
                src={defaultImg}
                data-src={StoryImageHero.value}
                alt="thumbnail"
              />
            )}
          </div>
          <div
            className={`${StoryImageHero.value === "" || StoryImageHero.value === null
              ? "retailerstory-featuredstory-body-hide"
              : "retailerstory-featuredstory-body"
              }`}
          >
            <div className="title-desktop">
              <Text field={title} />
            </div>

            <div className="summary">
              {PageLink && PageLink.value ? (
                <a href={PageLink.value}>
                  <Text field={StoryTitle} />
                </a>
              ) : (
                <Text field={StoryTitle} />
              )}
            </div>

            <div className="info">
              {PageLink && PageLink.value ? (
                <a href={PageLink.value}>
                  <span className="tag">
                    <Text field={StoryTags} />
                  </span>
                  <span className="date">
                    {StoryTags &&
                      StoryTags.value &&
                      StoryPublishDate &&
                      StoryPublishDate.value &&
                      " | "}
                    <Text field={StoryPublishDate} />
                  </span>
                </a>
              ) : (
                <React.Fragment>
                  <span className="tag">
                    <Text field={StoryTags} />
                  </span>
                  <span className="date">
                    {StoryTags &&
                      StoryTags.value &&
                      StoryPublishDate &&
                      StoryPublishDate.value &&
                      " | "}
                    <Text field={StoryPublishDate} />
                  </span>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      );
    } else return null;
  }

  showLowerCards = () => {
    const {
      Title = null,
      HighlightStories = null,
      ViewAllText = null,
      ViewMoreText = null,
      StoriesListingPageLink = null
    } = this.props.fields || {};

    if (HighlightStories.length === 5) {
      return (
        <div className="herobody">
          <ModuleHeader
            title={Title && Title.value ? Title.value : ""}
            url={StoriesListingPageLink && StoriesListingPageLink.value ? StoriesListingPageLink.value : ""}
            text={ViewAllText && ViewAllText.value ? ViewAllText.value : ""}
            moduleName="Highlight Stories"
            isStandard={true}
          />
          <div className="content-wrapper">
            {this.showItems(HighlightStories)}
            {this.showButton(ViewMoreText)}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  showItems = (highlightStories) => {
    return (
      <div className="row featuredstories-item-wrapper">
        {highlightStories &&
          highlightStories.map((item, index) => {
            const {
              StoryTitle: StoryTitle = null,
              StoryTags: StoryTags = null,
              StoryImage: StoryImage = null,
              StoryPublishDate: StoryPublishDate = null,
              PageLink: PageLink = null,
            } = item.fields || {};

            if (highlightStories.length < 5 && highlightStories.length > 1) {
              return (
                <div
                  key={`myId-${index}`}
                  className={`col col-12 col-md-4 col-lg-3 item-wrapper ${!this.state.gate ? "item-show" : ""
                    } ${index === 0 ? "d-block d-md-none" : "d-none"}`}
                >
                  <a href={PageLink.value}>
                    <div className="item-container">
                      <div className="item-image">
                        {StoryImage && StoryImage.value && StoryImage.value.src &&
                          <img
                            className="lazyload"
                            src={defaultImg}
                            data-src={StoryImage.value.src}
                            alt={StoryImage.value.alt ? StoryImage.value.alt : "story-thumbnail" + index}
                          />
                        }
                      </div>
                      <div className="item-summary">
                        <span className="tag">
                          <Text field={StoryTags} />
                        </span>
                        <span className="date">
                          {StoryTags &&
                            StoryTags.value &&
                            StoryPublishDate &&
                            StoryPublishDate.value &&
                            " | "}
                          <Text field={StoryPublishDate} />
                        </span>
                      </div>
                      <div className="item-name">
                        <Text field={StoryTitle} />
                      </div>
                    </div>
                  </a>
                </div>
              );
            } else {
              return (
                <div
                  key={`myId-${index}`}
                  className={`col col-12 col-md-4 col-lg-3 item-wrapper ${!this.state.gate ? "item-show" : ""
                    } ${index === 0 ? "d-block d-md-none" : ""}`}
                >
                  <a href={PageLink.value}>
                    <div className="item-container">
                      <div className="item-image">
                        {StoryImage && StoryImage.value && StoryImage.value.src &&
                          <img
                            className="lazyload"
                            src={defaultImg}
                            data-src={StoryImage.value.src}
                            alt={StoryImage.value.alt ? StoryImage.value.alt : "story-thumbnail" + index}
                          />
                        }
                      </div>
                      <div className="item-summary">
                        <span className="tag">
                          <Text field={StoryTags} />
                        </span>
                        <span className="date">
                          {StoryTags &&
                            StoryTags.value &&
                            StoryPublishDate &&
                            StoryPublishDate.value &&
                            " | "}
                          <Text field={StoryPublishDate} />
                        </span>
                      </div>
                      <div className="item-name">
                        <Text field={StoryTitle} />
                      </div>
                    </div>
                  </a>
                </div>
              );
            }
          })}
      </div>
    );
  }

  showButton = (viewMoreText) => {
    if (!this.state.gate) {
      return (
        <div className="btn-wrapper">
          <Button
            className="showmore-btn"
            onClick={() => {
              this.setState({ gate: !this.state.gate });
              TagManager.dataLayer({
                dataLayer: {
                  event: "clickedModuleCta",
                  moduleName: "Highlight Stories",
                  moduleCtaLink: "View More",
                },
                dataLayerName: "dataLayer",
              });
            }}
            label={viewMoreText && viewMoreText.value ? viewMoreText.value : "View more"}
            color={"light-secondary"}
            size={"large"}
          />
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    const {
      Title = null,
      HighlightStories = null,
      ViewAllText = null,
      ViewMoreText = null,
    } = this.props.fields || {};

    if (HighlightStories && HighlightStories.length > 0) {
      return (
        <BaseComponent margin="standard">
          <div className="retailerstory-featuredstory-wrapper container">
            {this.showUpperCard(HighlightStories, Title)}
            {this.showLowerCards()}
          </div>
        </BaseComponent>
      );
    }
    else return null;
  }
}

export default FeatureRetailerStoryHighlightStories;
