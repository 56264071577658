import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import TilePlaces from "../../../../Base/Tiles/TilePlaces";
import TileStories from "../../../../Base/Tiles/TileStories";
import TileGeneric from "../../../../Base/Tiles/TileGeneric";
import ModuleHeader from "../../../../Base/ModuleHeader";

class SearchResult extends React.Component {
  constructor(props) {
    super(props);
  }

  renderPlacesList = () => {
    const { Places, PlacesViewAll } = this.props.items;
    if (Places.length) {
      return (
        <div className="result">
          <ModuleHeader
            title="Places"
            data={PlacesViewAll}
            moduleName="Search Result"
            isStandard={true}
          />
          <div className="row">
            {Places.map((item, index) => {
              return (
                <div
                  key={`searchPlacesItem-${index}`}
                  className="col-12 col-md-4 col-lg-3 tile-range"
                >
                  <TilePlaces data={item} moduleName="Search Result" />
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  };

  renderStoriesList = () => {
    const { Stories, StoriesViewAll } = this.props.items;
    if (Stories.length) {
      return (
        <div className="result">
          <ModuleHeader
            title="Stories"
            data={StoriesViewAll}
            moduleName="Search Result"
            isStandard={true}
          />
          <div className="row">
            {Stories.map((item, index) => {
              return (
                <div
                  key={`searchStoriesItem-${index}`}
                  className="col-12 col-md-4 col-lg-3 tile-range"
                >
                  <TileStories data={item} moduleName="Search Result" />
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  };

  renderOthersList = () => {
    const { Others } = this.props.items;
    if (Others.length) {
      return (
        <div className="result">
          <ModuleHeader
            title="Other results"
            moduleName="Search Result"
            isStandard={true}
          />
          <div className="row">
            {Others.map((item, index) => {
              return (
                <div
                  key={`searchOthersItem-${index}`}
                  className="col-12 col-md-4 col-lg-3 tile-range"
                >
                  <TileGeneric data={item} moduleName="Search Result" />
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  };

  render() {
    return (
      <div className="retailer-sitesearch-result">
        <div className="result-count">
          <Text field={this.props.items.SearchResultCount} />
        </div>
        {this.renderPlacesList()}
        {this.renderStoriesList()}
        {this.renderOthersList()}
      </div>
    );
  }
}

export default SearchResult;
