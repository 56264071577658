import React from "react";
import {
  Text,
  Image,
  RichText,
  Link,
  Placeholder,
} from "@sitecore-jss/sitecore-jss-react";
import defaultImg from "../../../../assets/image/transparent.png";
import BaseComponent from "../../../Base/BaseComponent";
import SectionDivider from "../../../Base/SmallModules/SectionDivider";
import Button from "../../../Base/Button";
import DownloadForm from "../../../Base/DownloadForm";

class FeatureAssetsAssetType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formFieldState: props.fields.FormFields,
      showModal: false,
      modalDownloadLink: "#",
      fileId: ""
    };
  }

  showHero = (heroUrl, heroType, heroTitle) => {
    let heroImageLayout = null;
    if (
      heroType &&
      heroType.value &&
      heroType.value.toLowerCase() === "image" &&
      heroUrl &&
      heroUrl.value
    ) {
      heroImageLayout = (
        <div className="asset-type__hero">
          <img
            alt={heroTitle && heroTitle.value ? heroTitle.value : "asset-type-hero"}
            src={defaultImg}
            data-src={heroUrl.value}
            className="lazyload asset-type__hero--image"
          />
        </div>
      );
    } else if (
      heroType &&
      heroType.value &&
      heroType.value.toLowerCase() === "video" &&
      heroUrl &&
      heroUrl.value
    ) {
      heroImageLayout = (
        <div className="asset-type__hero">
          <video controls>
            <source
              src={heroUrl.value}
              type="video/mp4"
            />
            <source
              src={heroUrl.value}
              type="video/ogg"
            />
            Your browser does not support the video tag.
          </video>
        </div>
      );
    } else if (heroUrl && heroUrl.value) {
      heroImageLayout = (
        <div className="asset-type__hero">
          <img
            alt={heroTitle && heroTitle.value ? heroTitle.value : "asset-type-hero"}
            src={defaultImg}
            data-src={heroUrl.value}
            className="lazyload asset-type__hero--image"
          />
        </div>
      );
    }
    return heroImageLayout;
  };

  hasHeroHeader = (SectionTitle, HeroMediaUrl) => {
    let hasHeroHeaderValue = false;
    if ((SectionTitle && SectionTitle.value) || (HeroMediaUrl && HeroMediaUrl.value)) {
      hasHeroHeaderValue = true;
    }
    return hasHeroHeaderValue;
  }

  render() {
    const {
      SectionTitle = null,
      SectionId = null,
      SectionName = null,
      HeaderColor = null,
      HeaderTextColor = null,
      Heading = null,
      Description = null,
      CalltoAction = null,
      HeroMediaUrl = null,
      HeroMediaType = null,
      HeroMediaTitle = null,
      GoogleMap = null,
      GoogleMapURL = null,
      Address = null,
      IsOverlapping = null,
      FileDownloadsTitle,
      FileDownloads,
      FormFields = null,
      EnableRecaptcha = null,
      PublicKey = null,
      PrivacyPolicy = null,
      SuccessMessageHeader,
      SuccessMessage,
      FailedMessage,
    } = this.props.fields || {};

    const {
      showModal,
      fileId,
      modalDownloadLink,
      formFieldState
    } = this.state;

    return (
      <BaseComponent
        margin="standard"
        sectionId={SectionId && SectionId.value ? SectionId.value : ""}
      >
        <div className={"asset-type"}>
          {this.hasHeroHeader(SectionTitle, HeroMediaUrl) &&
            <div
              className="asset-type__section-name"
              style={{
                backgroundColor:
                  HeaderColor && HeaderColor.value ? HeaderColor.value : "",
              }}
            >
              <div className="container">
                <SectionDivider
                  margin="24"
                  backgroundColor={
                    HeaderTextColor && HeaderTextColor.value
                      ? HeaderTextColor.value
                      : ""
                  }
                />
                <div
                  className="asset-type__section-name--label"
                  style={{
                    color:
                      HeaderTextColor && HeaderTextColor.value
                        ? HeaderTextColor.value
                        : "",
                  }}
                >
                  <Text field={SectionTitle} />
                </div>
              </div>
            </div>
          }
          <div className="asset-type__body-wrapper">
            <div className="container">
              {this.showHero(HeroMediaUrl, HeroMediaType, HeroMediaTitle)}
              <div className={`asset-type__details ${!this.hasHeroHeader(SectionTitle, HeroMediaUrl) ? "asset-type__details--no-margin" : ""}`}>
                <div className="row">
                  <div className="col col-12 col-lg-8">
                    {!this.hasHeroHeader(SectionTitle, HeroMediaUrl) &&
                      <SectionDivider
                        margin="24"
                      />
                    }
                    {Heading && Heading.value && (
                      <div className="asset-type__details--header">
                        <Text field={Heading} />
                      </div>
                    )}
                    {Description && Description.value && (
                      <div className="asset-type__details--description">
                        <RichText field={Description} />
                      </div>
                    )}
                    {CalltoAction &&
                      CalltoAction.value &&
                      CalltoAction.value.href &&
                      CalltoAction.value.text && (
                        <div className="asset-type__details--cta">
                          <Button
                            isLink={true}
                            linkField={CalltoAction}
                            color={"light-primary"}
                            size={"large"}
                            label={CalltoAction.value.text}
                            icon={"chevron-up-2"}
                            moduleName="Asset Type Introduction"
                          />
                        </div>
                      )}
                  </div>
                  <div className="col col-12 col-lg-4">
                    {GoogleMap &&
                      GoogleMap.value &&
                      GoogleMapURL &&
                      GoogleMapURL.value && (
                        <div className="asset-type__details--map">
                          <div className="asset-type__details--map__image">
                            <a
                              href={GoogleMapURL.value}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                alt="asset-type__map"
                                src={defaultImg}
                                data-src={GoogleMap.value}
                                className="lazyload"
                              />
                            </a>
                          </div>
                          {GoogleMapURL &&
                            GoogleMapURL.value &&
                            Address &&
                            Address.value && (
                              <div className="asset-type__details--map__address">
                                <i className="icon icon-location" />
                                <a
                                  href={GoogleMapURL.value}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="asset-type__details--map__address--link"
                                >
                                  <Text field={Address} />
                                </a>
                              </div>
                            )}
                        </div>
                      )}
                    {FileDownloads &&
                      FileDownloads.length > 0 &&
                      <div className="filedownloads-wrapper">
                        <div className="secondarylinks-wrapper">
                          <div className="title">
                            {FileDownloadsTitle && FileDownloadsTitle.value ?
                              <Text field={FileDownloadsTitle} /> : "Downloads"}
                          </div>
                          {FileDownloads.map((item, index) => {
                            const {
                              Name,
                              Url,
                              FileId
                            } = item.fields

                            if (Name && Name.value && Url && Url.value && FileId && FileId.value) {
                              return (
                                <div key={Name.value + index} className="item">
                                  <button onClick={() => {
                                    document.body.classList.add("scroll-disable");
                                    this.setState({
                                      showModal: true,
                                      modalDownloadLink: Url.value,
                                      fileId: FileId.value
                                    })
                                  }}>
                                    <i className="icon icon-download "></i>
                                    <span>{Name.value}</span>
                                  </button>
                                </div>
                              )
                            } else return null
                          })
                          }
                        </div>
                      </div>
                    }
                    {showModal &&
                      <DownloadForm
                        FormFields={formFieldState}
                        EnableRecaptcha={EnableRecaptcha}
                        PublicKey={PublicKey}
                        PrivacyPolicy={PrivacyPolicy}
                        SuccessMessageHeader={SuccessMessageHeader}
                        SuccessMessage={SuccessMessage}
                        FailedMessage={FailedMessage}
                        fileId={fileId}
                        modalDownloadLink={modalDownloadLink}
                        showModal
                        onClickClose={(value) => this.setState({
                          showModal: false,
                          formFieldState: value
                        })}
                        gtmComponent={"Asset Type Introduction File Download"}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BaseComponent>
    );
  }
}

export default FeatureAssetsAssetType;
