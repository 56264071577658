import { Form } from "@sitecore-jss/sitecore-jss-react-forms";
import React from "react";
import { withRouter } from "react-router-dom";
import { sitecoreApiHost, sitecoreApiKey } from "../../../../temp/config";
import BaseComponent from "../../../Base/BaseComponent";
import {
  ConditionalFieldWrapper,
  ErrorComponent,
  FieldErrorComponent,
  UpdateFieldModel
} from "./CustomizeForm";

const FoundationFormsForm = ({ fields, history }) => {
  return (
    <div className="custom-form-container">
      {fields && (
        <BaseComponent margin="standard">
          <div className="custom-form" id="js-custom-form">
            <Form
              form={UpdateFieldModel(fields)}
              sitecoreApiHost={sitecoreApiHost}
              sitecoreApiKey={sitecoreApiKey}
              onRedirect={url => history.push(url)}
              fieldWrapperComponent={ConditionalFieldWrapper}
              errorComponent={ErrorComponent}
              fieldValidationErrorsComponent={FieldErrorComponent}
            />
          </div>
        </BaseComponent>
      )}
    </div>
  );
};

export default withRouter(FoundationFormsForm);
