import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import ModuleHeader from "../../../Base/ModuleHeader";
import TagManager from "react-gtm-module";

const FeatureESGESGReportSection = (props) => {
  const { Heading, Details } = props.fields;
  return (
    <BaseComponent margin="standard">
      <div className="container">
        <div className="ESG-report-section-wrapper">
          {Heading && Heading.value && (
            <ModuleHeader
              title={Heading.value}
              moduleName="ESG Report Section"
              isStandard={true}
            />
          )}
          {Details && Details.length
            ? Details.map((item, index) => {
                const {
                  SectionTitle,
                  SectionDescription,
                  SectionImage,
                  SectionImageAltText,
                  SectionNumber,
                  LinkUrl,
                } = item.fields;
                return (
                  <div
                    className="ESG-report-section-tile-wrapper"
                    key={`ESG-report-section-tile-${index}`}
                  >
                    <a
                      {...LinkUrl.value}
                      onClick={() =>
                        TagManager.dataLayer({
                          dataLayer: {
                            event: "clickedModuleCard",
                            moduleName: "ESG Report Section",
                            moduleTitle: SectionTitle.value,
                            moduleCardLink:
                              (LinkUrl &&
                                LinkUrl.value &&
                                LinkUrl.value.href) ||
                              "",
                          },
                          dataLayerName: "dataLayer",
                        })
                      }
                    >
                      <div className="ESG-report-section-tile">
                        <div className="section-image thumbnail">
                          <img
                            src={SectionImage.value}
                            alt={SectionImageAltText.value}
                          />
                        </div>
                        <div className="section-details">
                          <div className="section-number">
                            {SectionNumber &&
                            SectionNumber.value &&
                            SectionNumber.value < 10
                              ? "0"
                              : ""}
                            <Text field={SectionNumber} />
                          </div>
                          <div className="section-title">
                            <Text field={SectionTitle} />
                          </div>
                          <div className="section-description">
                            <Text field={SectionDescription} />
                          </div>
                        </div>
                        <div className="section-button">
                          <i className="icon icon-arrow-right-thick d-none d-md-block" />
                          <i className="icon icon-chevronright d-block d-md-none" />
                        </div>
                      </div>
                    </a>
                  </div>
                );
              })
            : ""}
        </div>
      </div>
    </BaseComponent>
  );
};

export default FeatureESGESGReportSection;
