import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import Button from "../../../Base/Button";
import defaultImg from "../../../../assets/image/transparent.png";

class FeaturePromotionsRetailerWhatsOn extends React.Component {
  constructor(props) {
    super(props);
  }

  state = { showMore: true };

  renderList = (promotions) => {
    if (promotions.length >= 2) {
      return promotions.map((item, index) => {
        const {
          PromotionName = null,
          PromotionImage = null,
          PromotionType = null,
          StartDate = null,
          PromotionLink = null
        } = item.fields || {};
        return (
          <div
            className={`tile ${this.state.showMore && index > 1 ? "hide" : ""}`}
            key={`myKey-${index}`}
          >
            <div className="promotion-image">
              {PromotionImage && PromotionImage.value &&
                <a
                  href={
                    PromotionLink && PromotionLink.value
                      ? PromotionLink.value
                      : ""
                  }
                >
                  <img
                    alt="Promotion"
                    src={defaultImg}
                    data-src={PromotionImage.value}
                    className="lazyload" />
                </a>
              }
            </div>
            <div className="tag">
              <span className="promotion-type">
                <Text field={PromotionType} />
              </span>
              {PromotionType &&
                PromotionType.value &&
                PromotionName &&
                PromotionName.value
                ? " | "
                : ""}
              <span className="promotion-date">
                <Text field={StartDate} />
              </span>
            </div>
            <div className="description">
              <Text field={PromotionName} />
            </div>
          </div>
        );
      });
    }
  };

  setVisible = (promotions) => {
    if (
      !this.props.rendering.dataSource ||
      this.props.rendering.dataSource === "" ||
      promotions.length < 2
    ) {
      return "hide";
    }
    return "show";
  };
  render() {
    const {
      Title,
      CentreWebsiteText,
      CentreWebsiteUrl,
      Promotions,
      SectionId,
      BackgroundColor,
      FontColor
    } = this.props.fields || {};
    return (
      this.setVisible(Promotions) === "show" && (
        <BaseComponent
          setVisible={this.setVisible(Promotions)}
          margin="standard"
          sectionId={SectionId && SectionId.value ? SectionId.value : ""}
        >
          <div className="retailer-whatson">
            <div
              className="banner"
              style={{ background: BackgroundColor && BackgroundColor.value ? BackgroundColor.value : "" }}
            />
            <div className="container">
              <div className="header">
                <div className="left">
                  <div
                    className="main"
                    style={{ color: FontColor && FontColor.value ? FontColor.value : "" }}
                  >
                    <Text field={Title} />
                  </div>
                </div>
                <div className="right">
                  <div
                    className="link"
                    style={{ color: FontColor && FontColor.value ? FontColor.value : "" }}
                  >
                    <a
                      href={CentreWebsiteUrl && CentreWebsiteUrl.value ? CentreWebsiteUrl.value : ""}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <u>
                        <Text field={CentreWebsiteText} />
                      </u>
                      <i className="icon icon-external" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="body">
                <div className="tile-container">{this.renderList(Promotions)}</div>
                <div
                  className={`show-more-btn ${this.state.showMore &&
                      Promotions.length > 2
                      ? ""
                      : "hide"
                    }`}
                >
                  <Button
                    onClick={e => this.setState({ showMore: false })}
                    color={"light-secondary"}
                    size={"medium"}
                    label={"Show More"}
                  />
                </div>
              </div>
            </div>
          </div>
        </BaseComponent>
      )
    );
  }
}

export default FeaturePromotionsRetailerWhatsOn;
