import React from "react";
import { withSitecoreContext, RichText } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";

class FeatureGenericContentRichText extends React.Component {
  constructor (props) {
    super(props);
  }

  componentDidMount () {
    if (window){
      const el = window.document.getElementById("_goback");
      if (el) {
        el.onclick = function() {
          window.history.back();
        };
      }
    }
  }

  render () {
    const props = this.props;
    const { RemoveDefaultMargin = "" } = this.props.params || {};
    const margin = RemoveDefaultMargin && RemoveDefaultMargin === "1" ? "" : "standard";

    return (
      <React.Fragment>
        {props.fields && props.fields.Details && (
          <BaseComponent margin={margin}>
            <div className="richtext-wrapper">
              <RichText field={props.fields.Details} />
            </div>
          </BaseComponent>
        )}

        {!props.fields && props.sitecoreContext.pageEditing && (
          <BaseComponent>
            <div className="richtext-wrapper">
              <p>
                Rich Text component is not displayed due to following reasons:{" "}
                <br />- Datasource is not set.
              </p>
            </div>
          </BaseComponent>
        )}
      </React.Fragment>
    );
  }
};

export default withSitecoreContext()(FeatureGenericContentRichText);
