import React, { Component } from "react";
import BaseComponent from "../../../Base/BaseComponent";
import { Text, Image, Link } from "@sitecore-jss/sitecore-jss-react";
import CustomPlayer from "../../../Base/CustomPlayer";
import PlayerControls from "../../../Base/CustomPlayer/PlayerControls";
import defaultImg from "../../../../assets/image/transparent.png";
import HexToColorMatrix from "../../../Base/HexToColorMatrix/index.js";
import TagManager from "react-gtm-module";
import HeroVideo from "../../../Base/Video";

class FeatureGenericContentRetailerHero extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSmallHero: false
    }
  }

  componentDidMount() {
    const { HeaderFontColorName, HeaderFontColorHex, DisplaySmallHero } = this.props.fields || {};
    if (
      HeaderFontColorName &&
      HeaderFontColorName.value !== "" &&
      HeaderFontColorHex &&
      HeaderFontColorHex.value !== ""
    ) {
      this.changeFontColor(HeaderFontColorHex);
    }
    if (window) {
      const path = window.location.pathname.toLowerCase();
      if (path.length === 1 && DisplaySmallHero && DisplaySmallHero.value) {
        this.setState({
          isSmallHero: true
        })
      }
    }
  }

  changeFontColor = (colorHex) => {
    const heroWrapper = document.getElementsByClassName(
      "retailerhero-wrapper"
    )[0];
    const colour = colorHex.value;
    if (heroWrapper) {
      const heroTitle = heroWrapper.getElementsByClassName("herotitle")[0];
      const heroVideo = heroWrapper.querySelector("#video-controls");
      const headerWrapper = document.getElementsByClassName(
        "top-navigation-container"
      )[0];

      if (heroTitle) {
        heroTitle.style.color = colour;
      }
      if (heroVideo) {
        heroVideo.querySelector("#playpause").style.color = colour;
      }
      if (headerWrapper) {
        const navLink = headerWrapper.querySelectorAll(".nav-link");
        const navIcon = headerWrapper.querySelectorAll(".icon");
        const navDivider = headerWrapper.querySelectorAll(".nav-divider");

        for (let i = 0; i < navLink.length; ++i) {
          navLink[i].style.color = colour;
          var styleElemA = document.head.appendChild(
            document.createElement("style")
          );
          var pathStyleA = ".navbar-nav .nav-item:hover {border-bottom-color: ";
          var pathStyleB = colour + "!important;}";
          styleElemA.innerHTML = pathStyleA + pathStyleB;
        }
        for (let i = 0; i < navIcon.length; ++i) {
          navIcon[i].style.color = colour;
        }
        for (let i = 0; i < navDivider.length; ++i) {
          navDivider[i].style.borderColor = colour;
        }
        setTimeout(() => {
          const headerLogoSVg = headerWrapper.querySelectorAll("svg path");
          const headerLogoSVGFilter = headerWrapper.querySelector(
            ".cdn-url-image-svg filter feColorMatrix"
          );
          if (headerLogoSVGFilter) {
            headerLogoSVGFilter.setAttribute(
              "values",
              HexToColorMatrix(colour)
            );
          }
          if (headerLogoSVg) {
            for (let i = 0; i < headerLogoSVg.length; ++i) {
              headerLogoSVg[i].style.fill = colour;
            }
          }
        }, 500);
      }
    }
  };

  showHero = (image, video, source, isWide) => {
    let heroImageLayout = null;
    if (
      source &&
      source.value &&
      source.value.toLowerCase() === "image" &&
      image &&
      image.value &&
      image.value.src
    ) {
      heroImageLayout = (
        <div className="img-hero">
          <Image
            media={{ ...image.value, src: defaultImg }}
            data-src={image.value.src}
            className="lazyload"
          />
        </div>
      );
    } else if (
      source &&
      source.value &&
      source.value.toLowerCase() === "video" &&
      video.value
    ) {
      if (isWide) {
        heroImageLayout = <CustomPlayer data={video.value} />;
      } else {
        heroImageLayout = <HeroVideo data={video.value} hasControls />;
      }
    } else if (image && image.value && image.value.src) {
      heroImageLayout = (
        <div className="img-hero">
          <Image
            media={{ ...image.value, src: defaultImg }}
            data-src={image.value.src}
            className="lazyload"
          />
        </div>
      );
    }
    return heroImageLayout;
  };

  showHeroTitle = (title, video, source, isWide) => {
    return (
      <div>
        <div className="herotitle">
          <Text field={title} />
        </div>
        {source &&
          source.value &&
          source.value.toLowerCase() === "video" &&
          video &&
          video.value &&
          isWide && <PlayerControls />}
      </div>
    );
  };

  render() {
    const {
      DisplayWideHeroImage,
      HeroImage: HeroImage = null,
      HeroVideo: HeroVideo = null,
      HeroTitle,
      HeroSource,
      HeroCTA,
    } = this.props.fields || {};

    let { AddMargin = "" } = this.props.params || {};
    let margin =
      AddMargin && AddMargin === "1" ? "standard" : "";
      
    return (
      ((HeroImage && HeroImage.value && HeroImage.value.src) ||
        (HeroVideo && HeroVideo.value)) && (
        <BaseComponent margin={margin}>
          <div className={`retailerhero-wrapper ${this.state.isSmallHero ? "is-small-hero" : ""}`}>
            <div
              className={
                DisplayWideHeroImage && DisplayWideHeroImage.value
                  ? "module-wide"
                  : "container module-not-wide"
              }
            >
              {DisplayWideHeroImage && DisplayWideHeroImage.value ? (
                this.showHero(
                  HeroImage,
                  HeroVideo,
                  HeroSource,
                  DisplayWideHeroImage.value
                )
              ) : (
                <div className="row">
                  <div className="col-12 image-range">
                    <div className="thumbnail">
                      {this.showHero(
                        HeroImage,
                        HeroVideo,
                        HeroSource,
                        DisplayWideHeroImage && DisplayWideHeroImage.value
                      )}
                    </div>
                  </div>
                </div>
              )}
              {((HeroTitle && HeroTitle.value) ||
                (HeroCTA && HeroCTA.value && HeroCTA.value.href)) && (
                  <div
                    className={`title-wrapper${DisplayWideHeroImage.value ? "-wide" : ""
                      }`}
                  >
                    <div className="container">
                      {HeroTitle &&
                        HeroTitle.value &&
                        this.showHeroTitle(
                          HeroTitle,
                          HeroVideo,
                          HeroSource,
                          DisplayWideHeroImage && DisplayWideHeroImage.value
                        )}
                      {HeroCTA &&
                        HeroCTA.value &&
                        HeroCTA.value.href &&
                        HeroCTA.value.text && (
                          <div
                            className={`hero-button ${HeroTitle && HeroTitle.value ? "" : "no-hero-title"
                              }`}
                          >
                            <Link
                              field={HeroCTA}
                              onClick={() =>
                                TagManager.dataLayer({
                                  dataLayer: {
                                    event: "clickedModuleCta",
                                    moduleName: "Generic Retailer Hero",
                                    moduleCtaLink:
                                      HeroCTA &&
                                      HeroCTA.value &&
                                      HeroCTA.value.href,
                                  },
                                  dataLayerName: "dataLayer",
                                })
                              }
                              className="btn btn-large btn-light-primary"
                            >
                              <span className="btn-label">
                                <Text field={{ value: HeroCTA.value.text }} />
                              </span>
                              <i className="icon icon-chevron-up-2" />
                            </Link>
                          </div>
                        )}
                    </div>
                  </div>
                )}
            </div>
          </div>
        </BaseComponent>
      )
    );
  }
}

export default FeatureGenericContentRetailerHero;
