import React, { Component } from "react";
import TagManager from "react-gtm-module";
import TilePartners from "../../../Base/Tiles/TilePartners";
import Button from "../../../Base/Button";

class PartnerItems extends Component {
  constructor(props) {
    super(props);
    this.state = { gate: false };
    this.Partners = this.props.data;
  }
  render() {
    return (
      <div>
        <div className="row">
          {this.Partners &&
            this.Partners.map((item, index) => {
              return (
                <div
                  key={`myId-${index}`}
                  className={`col col-12 col-md-4 col-lg-3 item-wrapper ${!this.state.gate ? "item-show" : ""
                    }`}
                >
                  <TilePartners item={item} />
                </div>
              );
            })}
        </div>
        {!this.state.gate && (
          <Button
            onClick={() => {
              this.setState({ gate: !this.state.gate });
              TagManager.dataLayer({
                dataLayer: {
                  event: "clickedModuleCta",
                  moduleName: "Featured Partners",
                  moduleCtaLink: "View More",
                },
                dataLayerName: "dataLayer",
              });
            }}
            color={"light-secondary"}
            size={"medium"}
            label={"View More"}
          />
        )}
      </div>
    );
  }
}

export default PartnerItems;
