import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import Chart from "chart.js";

class FeatureKeyStatsKeyStatsChart extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { TenantStatsList = null } = this.props.fields || {};
    let labels = [];
    let backgroundColor = [];
    let datus = [];

    if (TenantStatsList.length) {
      TenantStatsList.map(data => {
        labels.push(data.fields.StatName.value);
        backgroundColor.push(data.fields.StatColor.value);
        datus.push(data.fields.StatPercentatge.value);
      });

      new Chart(document.getElementById("doughnut-chart"), {
        type: "doughnut",
        data: {
          labels: labels,
          display: false,
          datasets: [
            {
              backgroundColor: backgroundColor,
              data: datus
            }
          ]
        },
        options: {
          title: {
            display: false,
            text: ""
          },
          legend: {
            display: false
          },
          tooltips: {
            bodyFontSize: 35
          },
          cutoutPercentage: 60
        }
      });
    }
  }

  validateDisplay(tenantStatsList) {
    let display = "hide";
    if (
      this.props.rendering.dataSource &&
      this.props.rendering.dataSource !== "" &&
      tenantStatsList &&
      tenantStatsList.length > 0
    ) {
      display = "show";
    }
    return display;
  }

  render() {
    const {
      SectionId = null,
      IsOverlapping = null,
      Title = null,
      TenantStatsList = null
    } = this.props.fields || {};
    return (this.validateDisplay(TenantStatsList) === 'show' &&
      <BaseComponent
        setVisible={this.validateDisplay(TenantStatsList)}
        margin="standard"
        sectionId={SectionId && SectionId.value ? SectionId.value : ""}
      >
        <div
          className={
            "key-stat-chart-container " +
            (IsOverlapping && IsOverlapping.value ? "overlap" : "")
          }
        >
          <div className="container">
            <div className="chart-wrapper">
              <canvas id="doughnut-chart" width="100%" height="100%" />
            </div>
            <div className="stat-chart-legend-wrapper">
              <div className="stat-chart-title">
                <Text field={Title} />
              </div>
              <div className="stat-chart-content">
                {TenantStatsList.map((item, index) => {
                  return (
                    <div
                      className="stat-chart-content-text"
                      key={`statId-${index}`}
                    >
                      <div
                        className="legend"
                        style={{ backgroundColor: item.fields.StatColor.value }}
                      />
                      <div className="text">
                        <Text field={item.fields.StatName} />
                      </div>
                      <div className="value">
                        <Text field={item.fields.StatPercentatge} />
                        <span>%</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </div>
      </BaseComponent>
    );
  }
}

export default FeatureKeyStatsKeyStatsChart;
