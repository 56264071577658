import React from "react";
import {
  Text,
  Image,
  RichText,
  Link,
  Placeholder,
} from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import defaultImg from "../../../../assets/image/transparent.png";
import HeroVideo from "../../../Base/Video";

const FeatureAssetsAssetDetails = (props) => {
  const {
    Hero = null,
    HeroVideo: video = null,
    HeroSource = null,
    SummaryStatement = null,
    Description = null,
    GoogleMap = null,
    GoogleMapURL = null,
    Address = null,
    Website = null,
    SectionId = null,
  } = props.fields || {};
  return (
    <BaseComponent margin="standard">
      <div className="assetdetail-wrapper">
        <div className="container">
          <div className="detail-image">
            <div className="row">
              <div className="col-12 image-range">
                <div className="thumbnail">
                  {HeroSource &&
                    HeroSource.value &&
                    HeroSource.value == "Video" ? (
                    <HeroVideo data={video.value} hasControls />
                  ) : (
                    <Image
                      media={{ ...Hero.value, src: defaultImg }}
                      data-src={Hero.value.src}
                      className="hero-image-generic lazyload"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="assetdetail-body">
            <BaseComponent
              sectionId={SectionId && SectionId.value ? SectionId.value : ""}
            >
              <div className="row">
                <div className="col col-12 col-lg-8">
                  <div className="asset-detail-left">
                    <div className="summary">
                      <Text field={SummaryStatement} />
                    </div>
                    <RichText field={Description} className="richtext" />
                  </div>
                </div>
                <div className="col col-12 col-lg-4">
                  <div className="centremap-wrapper">
                    {GoogleMapURL && GoogleMapURL.value && GoogleMap && GoogleMap.value &&
                      <div className="map-container">
                        <a
                          href={GoogleMapURL.value}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            alt="map"
                            src={defaultImg}
                            data-src={GoogleMap.value}
                            className="map lazyload"
                          />
                        </a>
                      </div>
                    }
                    {GoogleMapURL && GoogleMapURL.value && (
                      <div className="text">
                        <i className="icon icon-location" />
                        <a
                          href={GoogleMapURL.value}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Text field={Address} />
                        </a>
                      </div>
                    )}
                    {Website && Website.value && Website.value.href && (
                      <div className="text">
                        <i className="icon icon-external" />
                        <Link field={Website} />
                      </div>
                    )}
                  </div>
                  <Placeholder
                    name="jss-filedownloads-right"
                    rendering={props.rendering}
                  />
                </div>
              </div>
            </BaseComponent>
          </div>
        </div>
      </div>
    </BaseComponent>
  );
};

export default FeatureAssetsAssetDetails;
