import React from "react";

const SectionDivider = props => {
  const { margin, backgroundColor } = props;
  let mode = "";
  switch (margin) {
    case "20":
      mode = "divider-small";
      break;
    case "24":
      mode = "divider-medium";
      break;
    case "28":
      mode = "divider-large";
      break;
    default:
      mode = "d-none";
  }

  return (
    <div className={`section-divider-wrapper ${mode}`}>
      <div className="section-divider" style={{ backgroundColor: backgroundColor ? backgroundColor : "" }}/>
    </div>
  );
};

export default SectionDivider;
