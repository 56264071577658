import React, { Component } from "react";
import TileStories from "../../../Base/Tiles/TileStories";
import BaseComponent from "../../../Base/BaseComponent";
import DropDown from "../../../Base/DropDown";
import RiseAndFade from "../../../Base/Animations/RiseAndFade";
import LazyLoading from "../../../Base/LazyLoading";
import TagManager from "react-gtm-module";

class FeatureStoriesStoriesListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedCategory: "All",
      SelectedYear: "All",
      CategoryList: [],
      YearList: [],
      showLoader: false,
    };
  }

  componentDidMount() {
    const { Categories = null, Year = null } = this.props.fields || {};
    const filterCat = this.getUrlParameter("category");
    const filterYear = this.getUrlParameter("year");
    let categories = ["All"];
    let years = [];
    if (Categories && Categories.length) {
      Categories.map((item) => {
        if (
          item &&
          item.fields &&
          item.fields.CategoryName &&
          item.fields.CategoryName.value
        )
          categories.push(item.fields.CategoryName.value);
      });
      categories.map((item) => {
        if (
          item &&
          filterCat &&
          item.toLowerCase().indexOf(filterCat.toLowerCase()) !== -1
        ) {
          this.setState({ SelectedCategory: item });
        }
      });
    }
    if (Year && Year.length) {
      years.push("All");
      Year.map((item) => {
        if (
          item &&
          item.fields &&
          item.fields.YearName &&
          item.fields.YearName.value
        ) {
          // years.push(item.fields.YearName.value.slice(4));
          years.push(item.fields.YearName.value);
        }
      });
      years.map((item) => {
        if (
          item &&
          filterYear &&
          item.toLowerCase().indexOf(filterYear.toLowerCase()) !== -1
        ) {
          this.setState({ SelectedYear: item });
        }
      });
    }
    this.setState({ CategoryList: categories, YearList: years });
  }

  getUrlParameter(querystringParam) {
    querystringParam = querystringParam
      .replace(/[\[]/, "\\[")
      .replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + querystringParam + "=([^&#]*)");
    var results = regex.exec(window.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  renderCategories = () => {
    const handleChange = (selectedFilter) => {
      this.setState({
        showLoader: true,
      });
      setTimeout(() => {
        this.setState({
          selectedFilter,
          showLoader: false,
        });
      }, 100);
      TagManager.dataLayer({
        dataLayer: {
          event: "storiesListingFilter",
          storiesFilterType: "category",
          storiesFilterValue: selectedFilter,
        },
        dataLayerName: "dataLayer",
      });
    };
    if (this.state.CategoryList && this.state.CategoryList.length)
      return (
        <React.Fragment>
          <DropDown
            options={this.state.CategoryList}
            defaultSelected={this.state.SelectedCategory}
            handleSelectionChange={(selected) => {
              handleChange(selected);
              this.setState({ SelectedCategory: selected });
            }}
            inputTitle={"Type: "}
            showSelected={true}
          />
        </React.Fragment>
      );
  };

  renderYear = () => {
    const handleChange = (selectedFilter) => {
      this.setState({
        showLoader: true,
      });
      setTimeout(() => {
        this.setState({
          selectedFilter,
          showLoader: false,
        });
      }, 100);
      TagManager.dataLayer({
        dataLayer: {
          event: "storiesListingFilter",
          storiesFilterType: "year",
          storiesFilterValue: selectedFilter,
        },
        dataLayerName: "dataLayer",
      });
    };
    if (this.state.YearList && this.state.YearList.length) {
      return (
        <React.Fragment>
          <DropDown
            options={this.state.YearList}
            defaultSelected={this.state.SelectedYear}
            handleSelectionChange={(selected) => {
              handleChange(selected);
              this.setState({ SelectedYear: selected });
            }}
            inputTitle={"Year: "}
            showSelected={true}
          />
        </React.Fragment>
      );
    } else return null;
  };

  renderItems = (stories, isESG) => {
    const { SelectedCategory, SelectedYear } = this.state;
    if (stories && stories.length) {
      let list;
      if (SelectedCategory === "All" && SelectedYear === "All") {
        list = { StoriesList: stories };
      } else {
        list = {
          StoriesList: stories.filter((item) => {
            const { StoryCategory, StoryPublishDate, StoryTags } = item.fields;
            if (SelectedCategory === "All") {
              return StoryPublishDate && StoryPublishDate.value
                ? StoryPublishDate.value.indexOf(SelectedYear) !== -1
                : null;
            } else if (SelectedYear === "All") {
              return StoryCategory && StoryCategory.value
                ? StoryCategory.value.indexOf(SelectedCategory) !== -1 ||
                    item.fields.StoryCategory.value === "All"
                : null;
            } else {
              return StoryCategory &&
                StoryCategory.value &&
                StoryPublishDate &&
                StoryPublishDate.value
                ? StoryCategory.value.indexOf(SelectedCategory) !== -1 &&
                    StoryPublishDate.value.indexOf(SelectedYear) !== -1
                : null;
            }
          }),
        };
        // if (isESG && isESG.value) {
        //   list = {
        //     StoriesList: stories.filter(item => {
        //       const { StoryCategory, StoryPublishDate, StoryTags } = item.fields;
        //       if (SelectedCategory === "All") {
        //         return StoryPublishDate && StoryPublishDate.value
        //           ? StoryPublishDate.value.indexOf(SelectedYear) !== -1
        //           : null;
        //       } else if (SelectedYear === "All") {
        //         return StoryCategory && StoryCategory.value
        //           ? StoryCategory.value.indexOf(SelectedCategory) !== -1 ||
        //           item.fields.StoryCategory.value === "All"
        //           : null;
        //       } else {
        //         return StoryCategory &&
        //           StoryCategory.value &&
        //           StoryPublishDate &&
        //           StoryPublishDate.value
        //           ? StoryCategory.value.indexOf(SelectedCategory) !== -1 &&
        //           StoryPublishDate.value.indexOf(SelectedYear) !== -1
        //           : null;
        //       }
        //     })
        //   };
        // } else {
        //   list = {
        //     StoriesList: stories.filter(item =>
        //       item.fields.StoryCategory && item.fields.StoryCategory.value
        //         ? item.fields.StoryCategory.value === SelectedCategory ||
        //         item.fields.StoryCategory.value === "All"
        //         : null
        //     )
        //   };
        // }
      }
      if (list && list.StoriesList && list.StoriesList.length) {
        return list.StoriesList.map((item, index) => {
          let {
            StoryImage: StoryImage = null,
            StoryTitle: StoryTitle = null,
            StoryCategory: StoryCategory = null,
            StoryPublishDate: StoryPublishDate = null,
            PageLink: PageLink = null,
          } = item.fields;
          return (
            <div
              key={`storietile-${index}`}
              className="col-12 col-md-4 col-lg-3 tile-range"
            >
              <RiseAndFade index={index} columns={4} tabletColumns={3}>
                <TileStories
                  data={{
                    Thumbnail: {
                      value: {
                        src:
                          StoryImage && StoryImage.value && StoryImage.value.src
                            ? StoryImage.value.src
                            : "",
                        alt:
                          StoryImage && StoryImage.value && StoryImage.value.alt
                            ? StoryImage.value.alt
                            : "",
                      },
                    },
                    Title: StoryTitle,
                    Tags: StoryCategory,
                    Date: StoryPublishDate,
                    ItemURL: PageLink,
                  }}
                  moduleName="Stories Listing"
                />
              </RiseAndFade>
            </div>
          );
        });
      } else return <div className="col story-unavailable">No story found</div>;
    }
  };

  render() {
    const {
      Stories = null,
      IsESG = null,
      IsOverlapping = null,
      SectionId = null,
    } = this.props.fields || {};
    const { showLoader } = this.state;
    return (
      <BaseComponent
        margin="standard"
        sectionId={SectionId && SectionId.value ? SectionId.value : ""}
        overlap={
          IsOverlapping && IsOverlapping.value ? "content-stories-overlap" : ""
        }
      >
        <div
          className="stories-listing-wrapper container has-rise-and-fade"
          id="stories-listing"
        >
          <div className="filter-list">
            <div className="category-list-desktop d-none d-lg-block">
              {this.state.CategoryList &&
                this.state.CategoryList.length &&
                this.state.CategoryList.map((item, index) => {
                  return (
                    <span
                      key={`category-${index}`}
                      className={`category ${
                        item.indexOf(this.state.SelectedCategory) !== -1
                          ? "selected"
                          : ""
                      }`}
                      onClick={() => {
                        this.setState({
                          showLoader: true,
                        });
                        setTimeout(() => {
                          this.setState({
                            SelectedCategory: item,
                            showLoader: false,
                          });
                        }, 100);
                        TagManager.dataLayer({
                          dataLayer: {
                            event: "storiesListingFilter",
                            storiesFilterType: "category",
                            storiesFilterValue: item,
                          },
                          dataLayerName: "dataLayer",
                        });
                      }}
                    >
                      {item}
                    </span>
                  );
                })}
            </div>
            <div className="d-block d-lg-none">{this.renderCategories()}</div>
            <div className="year-list">{this.renderYear()}</div>
          </div>{" "}
          {/* </div> */}
          <div className="row">
            {!showLoader && this.renderItems(Stories)}
            {showLoader && <LazyLoading />}
          </div>
        </div>
      </BaseComponent>
    );
  }
}

export default FeatureStoriesStoriesListing;
