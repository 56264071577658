import React from "react";

const PlayerControls = (props) => {
  const {
    color = null
  } = props;

  let defaultColor = "#2e3947";
  
  return (
    <div id="video-controls" className="controls">
      <i
        id="playpause"
        type="button"
        data-state="play"
        className="icon icon-play"
        style={{ color: color ? color : defaultColor }}
      >
        <span className="path1" />
        <span className="path2" />
        <span className="path3" />
        <span className="playpause" />
      </i>
    </div>
  );
};

export default PlayerControls;
