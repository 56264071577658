import React, { Component } from "react";

class Zoom extends Component {
    constructor(props) {
        super(props);
    }

    handleActionClick(action) {
        const { onZoom } = this.props;
        onZoom(action);
    }

    render() {
        return (
            <div className="zoom-control-wrapper">
                <div
                    className="zoom-control zoom-control-plus"
                    onClick={() => { this.handleActionClick("in") }}>
                    <span className="zoom-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36.01" viewBox="0 0 36 36.01"><polygon points="36 16.01 20 16 20 0 16 0 16 16 0 16.01 0 20 16 20 16 36 20 36 20 20 36 20 36 16.01"></polygon></svg>
                    </span>
                </div>
                <div
                    className="zoom-control zoom-control-minus"
                    onClick={() => { this.handleActionClick("out") }}>
                    <span className="zoom-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36"><rect y="15.99" width="36" height="4"></rect></svg></span>
                </div>
                <div
                    className="zoom-control zoom-control-full"
                    onClick={() => { this.handleActionClick("full") }}>
                    <span className="zoom-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36"><path d="M9.29,34.29,7,32l9-9-3-3L4,29,1.78,26.22A1,1,0,0,0,0,26.85V36H8.58A1,1,0,0,0,9.29,34.29ZM36,36V26.86a1,1,0,0,0-1.78-.63L32,29l-9-9-3,3,9,9-2.77,2.22A1,1,0,0,0,26.85,36ZM26.22,1.77,29,4l-9,9,3,3,9-9,2.22,2.78A1,1,0,0,0,36,9.15V0H26.84A1,1,0,0,0,26.22,1.77ZM0,0V9c0,.94,1.41,1.73,2,1L4,7l9,9,3-3L7,4l3-2c.74-.59-.05-2-1-2Z"></path></svg>
                    </span>
                </div>
            </div>
        )
    }
}

export default Zoom;