import React from "react";
import BaseComponent from "../../../Base/BaseComponent";
import FooterLinks from "../FooterLinks";
import FooterNotes from "../FooterNotes";
import FooterSocial from "../FooterSocial";
import FooterSubscribe from "../FooterSubscribe";

const FeatureFooterRetailerFooter = props => {
  let pageWithoutSpace = [
    "Asset Page",
    "Partners",
    "Leadership",
    "About",
    "Contact Us"
  ];
  let { pageName = "" } = props.params || {};
  let noMarginTop = pageWithoutSpace.includes(pageName);

  return (
    <BaseComponent>
      <div
        className={`retailer-footer-wrapper ${
          noMarginTop ? "no-margin-top" : ""
          }`}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 order-12 order-md-1">
              <FooterLinks data={props.fields} />
            </div>
            <div className="col-12 col-md-6 order-1 order-md-12">
              <FooterSubscribe data={props.fields} />
              <FooterSocial data={props.fields} />
            </div>
          </div>
          <FooterNotes data={props.fields} />
        </div>
      </div>
    </BaseComponent>
  );
};

export default FeatureFooterRetailerFooter;
