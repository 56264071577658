import React from "react";
import BaseComponent from "../../../Base/BaseComponent";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import SectionDivider from "../../../Base/SmallModules/SectionDivider";
import { getImage } from "../../../../helper/ImageHelper";

class FeatureEnquireEnquiryHighlight extends React.Component {
  constructor(props) {
    super(props);
  }

  handleEnquireButton(type) {
    let modal = "";
    if (type.toLowerCase() === "long-term leasing") {
      modal = document.getElementsByClassName("modal-enquire-form-leasing")[0];
      const longTermBtn = modal.querySelector(".js-long-term-leasing");
      this.triggerEvent(longTermBtn, "click");
    } else if (type.toLowerCase() === "short-term leasing") {
      modal = document.getElementsByClassName("modal-enquire-form-leasing")[0];
      const shortTermBtn = modal.querySelector(".js-short-term-leasing");
      this.triggerEvent(shortTermBtn, "click");
    } else if (type.toLowerCase() === "media leasing") {
      modal = document.getElementsByClassName("modal-enquire-form-media")[0];
    }
    modal.style.display = "block";
    modal.classList.add("enquire-modal-open");
    if (modal.ownerDocument) {
      modal.ownerDocument.body.classList.add("scroll-disabled-body");
    }
  }

  // Manual Trigger Event
  triggerEvent = (element, event) => {
    var evt;
    if (document.createEvent) {
      evt = document.createEvent("HTMLEvents");
      evt.initEvent(event, true, true);
      evt.eventName = event;
      element.dispatchEvent(evt);
    } else {
      evt = document.createEventObject();
      evt.eventName = event;
      evt.eventType = event;
      element.fireEvent("on" + evt.eventType, evt);
    }
  };

  render() {
    const {
      SectionId = null,
      LeasingType = null,
      ModuleTitle = null,
      LeasingTitle = null,
      LeasingContent = null,
      LeasingImage = null,
      LeasingImageAltText = null,
      LeasingCTACaption = null
    } = this.props.fields || {};
    return (
      <BaseComponent
        margin={"standard"}
        sectionId={SectionId ? SectionId.value : ""}
      >
        <div className="enquiry-highlight-container">
          <div className="container">
            <SectionDivider margin="24" />
            {ModuleTitle && (
              <div className="module-title">
                <Text field={ModuleTitle} />
              </div>
            )}
            <div className="row">
              <div className="col-12 image-range">
                <div className="thumbnail">
                  <img
                    onClick={() => {
                      this.handleEnquireButton(LeasingType.value);
                    }}
                    {...getImage(LeasingImage, LeasingImageAltText)}
                  />
                </div>
              </div>
            </div>
            {LeasingTitle && (
              <div className="content-title">
                <a>
                  <Text field={LeasingTitle} />
                </a>
              </div>
            )}
            {LeasingContent && (
              <div className="content-description">
                <RichText field={LeasingContent} />
              </div>
            )}
            {LeasingCTACaption && (
              <div className="cta-link-wrapper">
                <button
                  onClick={() => {
                    this.handleEnquireButton(LeasingType.value);
                  }}
                >
                  <div className="cta-label">{LeasingCTACaption.value}</div>
                  <i className="icon icon-cta">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </button>
              </div>
            )}
          </div>
        </div>
      </BaseComponent>
    );
  }
}

export default FeatureEnquireEnquiryHighlight;
