import React from "react";
import axios from "axios";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import Filter from "./Filter";
import List from "./List";
import Map from "./Map";
import LazyLoading from "../../../Base/LazyLoading";
import TagManager from "react-gtm-module";
import { getUrlParameter } from "../../../../helper/UrlHelper";
import { addClassLazyload } from "../../../../helper/AddClassLazyloadHelper";

class FeatureRetailerListingAssetListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLocation: null,
      view: "list",
      currentCountry: "",
      assetCheckbox: ["All"],
      stateCheckbox: ["All"],
      listResult: {},
      showLoader: false,
    };
  }

  componentDidMount() {
    this.getQueries();
  }

  getQueries = () => {
    let qCountry = getUrlParameter("country");
    let country = "";
    let qState = getUrlParameter("state");
    let state = "";

    let qType = getUrlParameter("type");
    let type = "";

    if (qCountry && qCountry.toLowerCase() === "us") {
      country = "United States";
      state = ["US"];
      qState = "";
    } else if (qState) {
      country = "Australia";
      let b = [];
      qState.split(",").forEach((item) => {
        b.push(item.toUpperCase());
      });
      state = b;
      qState = b;
    } else if (qCountry && qCountry.toLowerCase() === "au") {
      country = "Australia";
      state = this.getAustralianStates(
        this.props && this.props.fields && this.props.fields.Locations
      );
    } else {
      country = "Australia"
      state = ["All"];
      qState = "";
    }

    if (qType) {
      let a = [];
      qType.split(",").forEach((item) => {
        a.push(item.charAt(0).toUpperCase() + item.slice(1));
      });
      type = a;
      qType = a;
    } else {
      type = ["All"];
      qType = "";
    }

    if (qCountry || qType || qState) {
      this.setState(
        {
          currentCountry: country,
          stateCheckbox: state,
          assetCheckbox: type,
        },
        () => this.getListData(country, qType, qState)
      );

      setTimeout(() => {
        const assetListingContainer = document.querySelector(".assetlisting");
        const isPropertiestLisng = document.querySelector(".is-properties-listing");
        if (assetListingContainer && window && !isPropertiestLisng) {
          let yPos = assetListingContainer.getBoundingClientRect().top + window.pageYOffset;
          window.scrollTo({ top: yPos, behavior: 'smooth' });
        }
      }, 500)
    } else this.getListData(country);
  };

  getAustralianStates = (s) => {
    let states = [];
    let list = [];

    if (s && s.length && s[0] && s[0].fields && s[0].fields.StateFilterTokens) {
      list = s[0].fields.StateFilterTokens;

      list.forEach((item) => {
        if (
          item &&
          item.fields &&
          item.fields.Value &&
          item.fields.Value.value
        ) {
          states.push(item.fields.Value.value);
        }
      });
    }

    return states;
  };

  getListData = async (country = "", assetFilter = "", stateFilter = "") => {
    let contextInfo = window.document.getElementsByName("ContextItem")[0];
    let pageId = contextInfo.value;
    let dbName = contextInfo.dataset.targetDb;

    let apiUrl = `/api/sitecore/assetlisting/search?pageId=${pageId}&dbName=${dbName}&country=${country}&assetFilter=${assetFilter}&stateFilter=${stateFilter}`;


    this.setState({
      showLoader: true,
    });

    let response = await axios.get(apiUrl);
    if (response) {
      this.setState(
        {
          listResult: response.data,
          showLoader: false,
        },
        () => addClassLazyload("list-wrapper")
      );
    }
  };

  applyFilter = () => {
    const { assetCheckbox, stateCheckbox, currentCountry } = this.state;
    let assetFilter, stateFilter, country = "";

    if (assetCheckbox.indexOf("All") > -1) {
      assetFilter = "";
    } else {
      assetFilter = assetCheckbox.filter((el) => el !== "All").join();
    }

    if (
      stateCheckbox.indexOf("All") > -1 ||
      (stateCheckbox && stateCheckbox.indexOf("US") > -1)
    ) {
      stateFilter = "";
      if (stateCheckbox.indexOf("All") > -1) {
        country = "Australia";
      } else {
        country = "";
      }
    } else {
      stateFilter = stateCheckbox.filter((el) => el !== "All").join();
    }
    this.getListData(country ? country : currentCountry, assetFilter, stateFilter);

    TagManager.dataLayer({
      dataLayer: {
        event: "assetListingFilter",
        assetFilter,
        stateFilter,
      },
      dataLayerName: "dataLayer",
    });
  };

  onCheckboxChange = (checkbox, property, value) => {
    this.setState(
      {
        [checkbox]: value
          ? [...this.state[checkbox], property]
          : this.state[checkbox].filter((el) => el !== property),
      },
      () => {
        if (property === "All" && value) {
          this.setState(
            {
              [checkbox]: ["All"],
            },
            () => this.applyFilter()
          );
        } else {
          if (this.state[checkbox].indexOf("All") > -1) {
            this.setState(
              {
                [checkbox]: this.state[checkbox].filter((el) => el !== "All"),
              },
              () => this.applyFilter()
            );
          } else {
            if (!this.state[checkbox].length) {
              this.setState(
                {
                  [checkbox]: ["All"],
                  currentCountry:
                    checkbox == "stateCheckbox"
                      ? ""
                      : this.state.currentCountry,
                },
                () => {
                  this.applyFilter();
                }
              );
            } else {
              if (checkbox == "stateCheckbox") {
                let newStates = this.state.stateCheckbox;
                if (property === "US") {
                  this.setState({ stateCheckbox: ["US"] }, () =>
                    this.applyFilter()
                  );
                } else {
                  if (newStates.indexOf("US") > -1) {
                    newStates.splice(newStates.indexOf("US"), 1);
                    this.setState({ stateCheckbox: newStates }, () =>
                      this.applyFilter()
                    );
                  } else this.applyFilter();
                }
              } else this.applyFilter();
            }
          }
        }
      }
    );
  };

  showEnquireModal = () => {
    document.getElementsByClassName(
      "modal-enquire-form-leasing"
    )[0].style.display = "block";
  };

  resetCheckbox = () => {
    this.getListData("Australia");
    this.setState({
      stateCheckbox: ["All"],
      assetCheckbox: ["All"],
      selectedLocation: null,
      currentCountry: "Australia"
    });
  };

  resetSelectedLocation = () => {
    this.setState({ selectedLocation: null });
  };

  onMarkerClick = (location) => {
    this.setState({ selectedLocation: location }, () =>
      addClassLazyload("map-wrapper")
    );
    TagManager.dataLayer({
      dataLayer: {
        event: "assetListingMarker",
        assetMarker:
          location && location.AssetName && location.AssetName.value
            ? location.AssetName.value
            : "Not specified",
      },
      dataLayerName: "dataLayer",
    });
  };

  renderView = (apiKey) => {
    const { view, listResult, selectedLocation, currentCountry } = this.state;
    if (view === "map") {
      let locations = listResult.AssetLists.map((item) => {
        return { ...item };
      });

      return (
        <Map
          locations={locations}
          apiKey={apiKey && apiKey.value ? apiKey.value : ""}
          onMarkerClick={this.onMarkerClick}
          selectedLocation={selectedLocation}
          resetSelectedLocation={this.resetSelectedLocation}
          country={currentCountry || "Australia"}
          showEnquireModal={this.showEnquireModal}
        />
      );
    } else {
      return <List list={listResult} />;
    }
  };

  render() {
    const {
      currentCountry,
      stateCheckbox,
      assetCheckbox,
      showLoader,
    } = this.state;
    const {
      Locations = null,
      PageTitle = null,
      ViewText = null,
      DoneText = null,
      ResetText = null,
      ApiKey = null,
    } = this.props.fields || {};

    const { bgColor = null, bgColorHex = null } = this.props.headerparams || {};

    return (
      <BaseComponent>
        <div className="assetlisting">
          {PageTitle &&
            PageTitle.value &&
            <div className={`assetlisting-title-container ${bgColor}`} style={{ backgroundColor: bgColorHex ? bgColorHex : '' }}>
              <div className="container">
                <div className="page-title">
                  <Text field={PageTitle} />
                </div>
              </div>
            </div>
          }
          <div className="filter" id="filter-container">
            {Locations && Locations.length && (
              <Filter
                PageTitle={PageTitle}
                currentCountry={currentCountry}
                changeCountry={(currentCountry) => {
                  this.setState(
                    { currentCountry, selectedLocation: null }
                    // this.resetCheckbox
                  );
                  TagManager.dataLayer({
                    dataLayer: {
                      event: "assetListingLocation",
                      assetLocation: currentCountry,
                    },
                    dataLayerName: "dataLayer",
                  });
                }}
                locations={Locations}
                DoneText={DoneText}
                ViewText={ViewText}
                ResetText={ResetText}
                changeView={(view) => {
                  this.setState({ view });
                  TagManager.dataLayer({
                    dataLayer: {
                      event: "assetListingView",
                      assetView: view,
                    },
                    dataLayerName: "dataLayer",
                  });
                }}
                currentView={this.state.view}
                stateCheckbox={stateCheckbox}
                assetCheckbox={assetCheckbox}
                onCheckboxChange={this.onCheckboxChange}
                resetCheckbox={() => {
                  this.resetCheckbox();
                  TagManager.dataLayer({
                    dataLayer: {
                      event: "assetListingResetFilter",
                    },
                    dataLayerName: "dataLayer",
                  });
                }}
                applyFilter={this.applyFilter}
                bgColor={bgColor}
                bgColorHex={bgColorHex}
              />
            )}
          </div>
          <div className="view">
            {!showLoader && this.renderView(ApiKey)}
            {showLoader && <LazyLoading />}
          </div>
        </div>
      </BaseComponent>
    );
  }
}

export default FeatureRetailerListingAssetListing;
