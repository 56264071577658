import React from "react";
import BaseComponent from "../../../Base/BaseComponent";

class FeatureVideoVideo extends React.Component {
  constructor(props) {
    super(props);
  }

  setVisible = (videoUrl, videoFile) => {
    let setVisible = "hide";
    if (
      (videoFile &&
        videoFile.value &&
        videoFile.value.src &&
        videoFile.value.mimeType) ||
      videoUrl.value
    ) {
      if (videoUrl.value) {
        setVisible = "show";
      } else {
        if (videoFile.value.mimeType.indexOf("video") > -1) {
          setVisible = "show";
        } else {
          setVisible = "hide";
        }
      }
    }
    return setVisible;
  };

  render() {
    const { VideoUrl, VideoFile, SectionId } = this.props.fields || {};

    if (
      (VideoUrl && VideoUrl.value) ||
      (VideoFile && VideoFile.value && VideoFile.value.src)
    ) {
      return (
        <BaseComponent
          margin="standard"
          setVisible={this.setVisible(VideoUrl, VideoFile)}
          sectionId={SectionId && SectionId.value ? SectionId.value : ""}
        >
          <div className="video">
            <div className="container">
              <div className="content">
                <video controls>
                  <source
                    src={
                      VideoUrl && VideoUrl.value
                        ? VideoUrl.value
                        : VideoFile.value.src
                    }
                    type="video/mp4"
                  />
                  <source
                    src={
                      VideoUrl && VideoUrl.value
                        ? VideoUrl.value
                        : VideoFile.value.src
                    }
                    type="video/ogg"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </BaseComponent>
      );
    } else return null;
  }
}

export default FeatureVideoVideo;
