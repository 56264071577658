import React, { Component } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import ModuleHeader from "../../../Base/ModuleHeader";
import Button from "../../../Base/Button";
import defaultImg from "../../../../assets/image/transparent.png";
import TagManager from "react-gtm-module";

class FeatureStoriesFeaturedStories extends Component {
  constructor(props) {
    super(props);
    this.state = { gate: false };
  }
  render() {
    const {
      SectionId = null,
      IsOverlapping = null,
      Title = null,
      ViewAllUrl = null,
      Stories = null,
      TileCTAText = null,
      WhiteBackgroundColor = null,
    } = this.props.fields || {};

    let showItems = () => {
      return (
        <div className="row featuredstories-item-wrapper">
          {Stories &&
            Stories.length > 0 &&
            Stories.map((item, index) => {
              let {
                StoryTitle: StoryTitle = null,
                StoryImage: StoryImage = null,
                StoryTags: StoryTags = null,
                PageLink: PageLink = null,
                StoryPublishDate: StoryPublishDate = null,
              } = item.fields || {};
              return (
                <div
                  key={`myId-${index}`}
                  className={`col col-12 col-md-4 item-wrapper tile-range ${!this.state.gate ? "item-show" : ""
                    }`}
                >
                  <a
                    href={PageLink && PageLink.value ? PageLink.value : ""}
                    onClick={() =>
                      TagManager.dataLayer({
                        dataLayer: {
                          event: "clickedModuleCard",
                          moduleName: "Featured Stories",
                          moduleCardLink:
                            PageLink && PageLink.value ? PageLink.value : "",
                        },
                        dataLayerName: "dataLayer",
                      })
                    }
                  >
                    <div className="item-container">
                      {StoryImage && StoryImage.value && StoryImage.value.src && (
                        <div className="item-image thumbnail">
                          <img
                            className="lazyload"
                            src={defaultImg}
                            data-src={StoryImage.value.src}
                            alt={
                              StoryImage.value.alt ? StoryImage.value.alt : ""
                            }
                          />
                        </div>
                      )}
                      <div className="item-summary">
                        <span className="tag">
                          <Text field={StoryTags} />
                        </span>
                        {StoryTags &&
                          StoryTags.value &&
                          StoryPublishDate &&
                          StoryPublishDate.value &&
                          <span className="divider">{"|"}</span>
                        }
                        <span className="date">

                          <Text field={StoryPublishDate} />
                        </span>
                      </div>
                      <div className="item-name">
                        <Text field={StoryTitle} />
                      </div>
                      {TileCTAText &&
                        TileCTAText.value &&
                        <div className="item-cta">
                          <span className="cta-label">
                            <Text field={TileCTAText} />
                          </span>
                          <i className="icon icon-cta">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                        </div>
                      }
                    </div>
                  </a>
                </div>
              );
            })}
        </div>
      );
    };
    let showButton = () => {
      if (!this.state.gate) {
        return (
          <div className="btn-wrapper">
            <Button
              onClick={() => {
                this.setState({ gate: !this.state.gate });
                TagManager.dataLayer({
                  dataLayer: {
                    event: "clickedModuleCta",
                    moduleName: "Featured Stories",
                    moduleCtaLink: "Show More",
                  },
                  dataLayerName: "dataLayer",
                });
              }}
              label={"Show More"}
              color={"light-secondary"}
              size={"large"}
              className="showmore-btn"
            />
          </div>
        );
      } else {
        return null;
      }
    };
    return Stories &&
      Stories.length > 0 &&
      Stories.length > 2 &&
      <BaseComponent
        margin="standard"
        sectionId={SectionId && SectionId.value ? SectionId.value : ""}
        overlap={
          IsOverlapping && IsOverlapping.value ? "content-stories-overlap" : ""
        }
      >
        <div
          className={`fitout-featuredstories-wrapper ${IsOverlapping && IsOverlapping.value
            ? "overlap-stories-content"
            : ""
            }`}
        >
          <div className={`featuredstories-header-bg ${WhiteBackgroundColor && WhiteBackgroundColor.value ? "is-bg-white" : ""}`}>
            <div className="container">
              <ModuleHeader
                title={Title && Title.value ? Title.value : ""}
                data={ViewAllUrl}
                text={ViewAllUrl && ViewAllUrl.value && ViewAllUrl.value.text ? ViewAllUrl.value.text : "View All"}
                moduleName="Featured Stories"
              />
            </div>
          </div>

          <div className="content-wrapper">
            <div className="container">
              {showItems()}
              {showButton()}
            </div>
          </div>
        </div>
      </BaseComponent>
  }
}

export default FeatureStoriesFeaturedStories;
