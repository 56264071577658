import React, { Component, Fragment } from "react";

class DropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  handleToggle = (e) => {
    e.target.focus();
    this.setState({ show: !this.state.show });
  };

  handleBlur = () => {
    if (this.state.show) {
      setTimeout(() => {
        this.setState({ show: !this.state.show });
      }, 250);
    }
  };

  render() {
    const {
      inputTitle,
      options,
      handleSelectionChange,
      showSelected,
      defaultSelected,
    } = this.props || {};
    return (
      <Fragment>
        <div className="drop-down-container">
          <label className="arrow">
            <div className="dropdown-placeholder">
              <input
                type="button"
                value={
                  inputTitle ? inputTitle + defaultSelected : defaultSelected
                }
                className="dropdown-btn"
                onClick={(e) => this.handleToggle(e)}
                onBlur={(e) => this.handleBlur(e)}
              />
            </div>
            <i className="icon icon-chevrondown" />
            <ul className="dropdown-list" hidden={!this.state.show}>
              {options &&
                options.length &&
                options.map((item, index) => {
                  return (
                    <li
                      key={`optionId${index}`}
                      onClick={() => {
                        this.setState(
                          { show: false, selected: item },
                          handleSelectionChange(item)
                        );
                      }}
                    >
                      <span>{item}</span>
                      {showSelected && item.indexOf(defaultSelected) !== -1 ? (
                        <span>
                          <i className="icon icon-check" />
                        </span>
                      ) : (
                        ""
                      )}
                    </li>
                  );
                })}
            </ul>
          </label>
        </div>
      </Fragment>
    );
  }
}

export default DropDown;
