import React from "react";
import axios from "axios";
import _ from "lodash";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import TagManager from "react-gtm-module";
import SearchResult from "./SearchResult";

class FeatureSearchRetailerSiteSearch extends React.Component {
  constructor(props) {
    super(props);
    this.handleSearch = _.debounce(this.handleSearch, 500);
  }

  state = { keyword: "", searchResults: null };

  handleSearch = async () => {
    let pageId = window.document.getElementsByName("ContextItem")[0].value;
    let apiUrl = `/api/sitecore/retailersearch/search?keyword=${this.state.keyword}&pageid=${pageId}`;
    let response = await axios.get(apiUrl);

    TagManager.dataLayer({
      dataLayer: {
        event: "performedSearch",
        searchTerm: this.state.keyword
      },
      dataLayerName: "dataLayer"
    });

    if (response) {
      this.setState({
        searchResults: response.data
      });
    }
  };

  renderSearchResult = () => {
    const { keyword, searchResults } = this.state;
    if (keyword && searchResults) {
      let count = parseInt(searchResults.SearchResultCount.value.split(" ")[0]);
      if (count !== 0) {
        return <SearchResult items={searchResults} />;
      } else {
        return (
          <div className="no-result">
            <Text field={searchResults.NoSearchResult} />
          </div>
        );
      }
    } else {
      return <div className="increase-height" />;
    }
  };

  render() {
    const { Placeholder = null } = this.props.fields || {};
    return (
      <BaseComponent margin="standard">
        <div className="retailer-sitesearch">
          <div className="container">
            <div className="search-field">
              <input
                type="text"
                autoFocus={true}
                value={this.state.keyword}
                onChange={e =>
                  this.setState({ keyword: e.target.value }, this.handleSearch)
                }
                placeholder={Placeholder && Placeholder.value ? Placeholder.value : ""}
              />
            </div>

            {this.renderSearchResult()}
          </div>
        </div>
      </BaseComponent>
    );
  }
}

export default FeatureSearchRetailerSiteSearch;
