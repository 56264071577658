import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import defaultImg from "../../../../assets/image/transparent.png";

class FeatureRetailerStoryFeaturedImages extends React.Component {
  constructor(props) {
    super(props);
  }

  renderList = () => {
    const { Images = null } = this.props.fields || {};
    if (Images.length) {
      const imageCount = Images.length;
      return Images.map((item, index) => {
        const { Caption, Url } = item.fields;
        return (
          <div className="retailerstory-tile" key={`imgId-${index}`}>
            <div
              className={`retailerstory-img-${imageCount > 1 ? "two" : "one"}`}
            >
              <img
                alt={Caption.value}
                src={defaultImg}
                data-src={Url.value}
                className="lazyload" />
            </div>
            <div className="image-caption">
              <Text field={Caption} />
            </div>
          </div>
        );
      });
    }
  };

  render() {
    return (
      <BaseComponent margin="standard">
        <div className="retailerstory-featuredimages">
          <div className="container">
            <div className="row">{this.renderList()}</div>
          </div>
        </div>
      </BaseComponent>
    );
  }
}

export default FeatureRetailerStoryFeaturedImages;
