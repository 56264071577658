import defaultImage from "../assets/image/transparent.png";

export const getImage = (url, text, image) => {
  let src = defaultImage;
  let alt = "";

  if (url && url.value) {
    src = url.value;
    if (text && text.value) {
      alt = text.value;
    }
  } else if (image && image.value && image.value.src) {
    src = image.value.src;
    if (image.value.alt) {
      alt = image.value.alt;
    }
  }

  return { src, alt };
};
