const HexToColorMatrix = hex => {
    let rColor, gColor, bColor, hexColor, colorMatrix;

    if (hex) {
        hexColor = hex.replace(/#/g, "");
        if (hexColor.length === 6 || hexColor.length === 3) {
            if (hexColor.length === 3) {
                rColor = (parseInt(hexColor.substring(0, 1).repeat(2), 16)) / 255;
                gColor = (parseInt(hexColor.substring(1, 2).repeat(2), 16)) / 255;
                bColor = (parseInt(hexColor.substring(2, 3).repeat(2), 16)) / 255;
            } else {
                rColor = (parseInt(hexColor.substring(0, 2), 16)) / 255;
                gColor = (parseInt(hexColor.substring(2, 4), 16)) / 255;
                bColor = (parseInt(hexColor.substring(4, 6), 16)) / 255;
            }
        }
        colorMatrix = `1 0 0 ${rColor} 0 0 1 0 ${gColor} 0 0 0 1 ${bColor} 0 0 0 0 1 0`;
    }
    else {
        colorMatrix = "";
    }
    return colorMatrix;
};

export default HexToColorMatrix;