import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import DownloadForm from "../../../Base/DownloadForm";

class FeatureGenericContentFileDownloads extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formFieldState: props.fields.FormFields,
      showModal: false,
      modalDownloadLink: "#",
      fileId: ""
    };
  }

  render() {
    const {
      FileDownloads,
      Title,
      FormFields = null,
      EnableRecaptcha = null,
      PublicKey = null,
      PrivacyPolicy = null,
      SuccessMessageHeader,
      SuccessMessage,
      FailedMessage,
    } = this.props.fields || {};

    const {
      showModal,
      fileId,
      modalDownloadLink,
      formFieldState
    } = this.state;

    if (FileDownloads && FileDownloads.length > 0) {
      return (
        <>
          <div className="filedownloads-wrapper">
            <div className="secondarylinks-wrapper">
              <div className="title">
                {Title && Title.value ?
                  <Text field={Title} /> : "Downloads"}
              </div>
              {FileDownloads.map((item, index) => {
                const {
                  Name,
                  Url,
                  FileId
                } = item.fields

                if (Name && Name.value && Url && Url.value && FileId && FileId.value) {
                  return (
                    <div key={Name.value + index} className="item">
                      <button onClick={() => {
                        document.body.classList.add("scroll-disable");
                        this.setState({
                          showModal: true,
                          modalDownloadLink: Url.value,
                          fileId: FileId.value
                        })
                      }}>
                        <i className="icon icon-download "></i>
                        <span>{Name.value}</span>
                      </button>
                    </div>
                  )
                } else return null
              })
              }
            </div>
          </div>
          {showModal &&
            <DownloadForm
              FormFields={formFieldState}
              EnableRecaptcha={EnableRecaptcha}
              PublicKey={PublicKey}
              PrivacyPolicy={PrivacyPolicy}
              SuccessMessageHeader={SuccessMessageHeader}
              SuccessMessage={SuccessMessage}
              FailedMessage={FailedMessage}
              fileId={fileId}
              modalDownloadLink={modalDownloadLink}
              showModal
              onClickClose={(value) => this.setState({
                showModal: false,
                formFieldState: value
              })}
              gtmComponent={"Generic File Downloads"}
            />
          }
        </>
      )
    } else return null
  }
}

export default FeatureGenericContentFileDownloads;
