import React from "react";
import { Text, Link, RichText } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import SectionDivider from "../../../Base/SmallModules/SectionDivider";
import Button from "../../../Base/Button";
import CtaHyperlink from "../../../Base/CtaHyperlink";
import { getImage } from "../../../../helper/ImageHelper";
import TagManager from "react-gtm-module";

const setVisible = (props) => {
  if (!props.rendering.dataSource || props.rendering.dataSource === "" || !props.fields) {
    return "hide";
  }
  return "show";
};

const FeatureGenericContentGenericCtaHighlight = (props) => {
  const {
    Title = null,
    Content = null,
    Image: image = null,
    ImageUrl = null,
    ImageAltText = null,
    DisplayImageToTheLeft = null,
    ImageCaption = null,
    CtaName = null,
    CtaLandingPage = null,
    SectionId = null,
    IsButtonStyle = null,
    Orientation = null
  } = props.fields || {};

  let noImage = true;
  let ctaLayout = "";
  const contentImage = getImage(ImageUrl, ImageAltText, image);
  // const noImage = contentImage.src.indexOf("/static/media/transparent") !== -1;
  if (
    (image && image.value && image.value.src) ||
    (ImageUrl && ImageUrl.value)
  ) {
    noImage = false;
  }

  if (CtaName && CtaName.value &&
    CtaLandingPage && CtaLandingPage.value && CtaLandingPage.value.href) {
    if (IsButtonStyle && IsButtonStyle.value) {
      ctaLayout =
        <Link
          field={CtaLandingPage}
          onClick={() =>
            TagManager.dataLayer({
              dataLayer: {
                event: "clickedModuleCta",
                moduleName: "Generic Cta Highlight",
                moduleCtaLink:
                  (CtaLandingPage &&
                    CtaLandingPage.value &&
                    CtaLandingPage.value.href) ||
                  "",
              },
              dataLayerName: "dataLayer",
            })
          }
          className="btn btn-large btn-light-primary"
        >
          <span className="btn-label">
            <Text field={CtaName} />
          </span>
          <i className="icon icon-chevron-up-2" />
        </Link>
    } else {
      ctaLayout =
        <CtaHyperlink
          url={CtaLandingPage.value.href}
          target={CtaLandingPage.value.target}
          text={CtaName.value}
          moduleName="Generic Cta Highlight"
        />
    }
  }

  return (
    setVisible(props) === "show" && (
      <BaseComponent
        margin="standard"
        sectionId={SectionId && SectionId.value ? SectionId.value : ""}
        setVisible={setVisible(props)}
      >
        <div className="container cta-container">
          <div
            className={`generic-cta-highlight-container 
            ${DisplayImageToTheLeft && DisplayImageToTheLeft.value != true
                ? "display-block"
                : ""
              }`}
          >
            {!noImage && (
              <div
                className={`cta-image-wrapper 
                ${DisplayImageToTheLeft && DisplayImageToTheLeft.value != true
                    ? "float-right"
                    : ""
                  }`}
              >
                <div className="image-range">
                  <div className={`thumbnail ${Orientation && Orientation.value && Orientation.value.toLowerCase() === "landscape" ? "is-landscape" : ""}`}>
                    <img
                      {...contentImage}
                      className="img-responsive lazyload"
                    />
                  </div>
                </div>
                {ImageCaption && ImageCaption.value && (
                  <div className="image-caption">
                    <Text field={ImageCaption} />
                  </div>
                )}
              </div>
            )}
            <div className={`cta-all-content ${DisplayImageToTheLeft && DisplayImageToTheLeft.value == true ? "is-left" : ""}`}>
              <SectionDivider margin="24" />
              <div
                className={`cta-content-wrapper 
                ${DisplayImageToTheLeft && DisplayImageToTheLeft.value != true
                    ? "float-left"
                    : ""
                  } 
                ${noImage ? "mt-0 w-100" : ""}`}
              >
                <div className="cta-content-title" id={props.rendering.uid}>
                  <Text field={Title} />
                </div>
                <div className="cta-content-description">
                  <RichText field={Content} />
                </div>
              </div>

              <div
                className={`cta-button-wrapper 
                ${DisplayImageToTheLeft && DisplayImageToTheLeft.value != true
                    ? "float-left"
                    : ""
                  } 
                ${Content && Content.value ? "" : "no-content"}`}
              >
                {ctaLayout}
              </div>
            </div>
            <div className="clearfix" />
          </div>
        </div>
      </BaseComponent>
    )
  );
};

export default FeatureGenericContentGenericCtaHighlight;
