import React from "react";
import { Text, Link } from "@sitecore-jss/sitecore-jss-react";
import TagManager from "react-gtm-module";

const FooterNotes = (props) => {
  const { NotesTitle = null, NotesLinks = null, FooterMainLinks = null } = props.data || {};
  if (NotesTitle && NotesTitle.value) {
    let footerLinkClass = "";

    if (FooterMainLinks && FooterMainLinks.length < 3) {
      footerLinkClass = "is-link-two";
    }
    else if (FooterMainLinks && FooterMainLinks.length < 4) {
      footerLinkClass = "is-link-three";
    }

    return (
      <div className={`row footer-notes-wrapper ${footerLinkClass}`}>
        <div className="col-12 col-md-auto title">
          <Text field={NotesTitle} />
        </div>
        <div className="col-12 col-md-auto">
          <div className="row no-gutters">
            {NotesLinks &&
              NotesLinks.length &&
              NotesLinks.map((item, index) => {
                let { NoteLink: NoteLink = null } = item.fields;
                return (
                  <div key={`footernote-${index}`} className="col-auto">
                    <Link
                      field={NoteLink}
                      onClick={() =>
                        TagManager.dataLayer({
                          dataLayer: {
                            event: "clickedModuleCta",
                            moduleName: "Retailer Footer",
                            moduleCtaLink:
                              (NoteLink &&
                                NoteLink.value &&
                                NoteLink.value.href) ||
                              "",
                          },
                          dataLayerName: "dataLayer",
                        })
                      }
                    />
                    <a
                      {...NoteLink.value}
                      onClick={() =>
                        TagManager.dataLayer({
                          dataLayer: {
                            event: "clickedModuleCta",
                            moduleName: "Retailer Footer",
                            moduleCtaLink:
                              (NoteLink &&
                                NoteLink.value &&
                                NoteLink.value.href) ||
                              "",
                          },
                          dataLayerName: "dataLayer",
                        })
                      }
                    >
                      <i className="icon icon-external ml-2" />
                    </a>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default FooterNotes;
