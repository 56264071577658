import React from "react";
import { Text, Image } from "@sitecore-jss/sitecore-jss-react";
import defaultImg from "../../../../assets/image/transparent.png";
import TagManager from "react-gtm-module";

const TileGeneric = (props) => {
  const { Thumbnail, Title, Summary, ItemURL } = props.data;
  return (
    <div className="tile">
      <div className="thumbnail">
        <a
          href={ItemURL.value}
          onClick={() =>
            TagManager.dataLayer({
              dataLayer: {
                event: "clickedModuleCard",
                moduleName: props.moduleName,
                moduleCardLink: ItemURL.value,
              },
              dataLayerName: "dataLayer",
            })
          }
        >
          <Image
            media={{ ...Thumbnail.value, src: defaultImg }}
            data-src={Thumbnail.value.src}
            className="lazyload"
          />
        </a>
      </div>
      <div className="details">
        <div className="title">
          <a href={ItemURL.value}>
            <Text field={Title} />
          </a>
        </div>
        <div className="summary-generic">
          <Text field={Summary} />
        </div>
      </div>
    </div>
  );
};

export default TileGeneric;
