import React from 'react';

const handleNextStep = (onStepChangeFunc, step, duration) => {
  let obj = {leaseDuration: duration, formType: 'leasing'}
  onStepChangeFunc(step, obj);
}

const LeasingStep1 = (props) => {
    return(
        <div className="form-wrapper">
          <div className="container">
            <div className="row">
              <div className="col step">
                STEP 1 OF 4
              </div>
            </div>
            <div className="row">
              <div className="col col-lg-6 title">
                How long are you looking to lease for?
                </div>
            </div>
            <div className="row submit-section justify-content-start">
              <div className="col col-12 col-lg-2 leasing-btn-wrapper">
                <button className="btn btn-dark-secondary btn-block lg-double-line desktop js-short-term-leasing"
                    onClick={()=>{handleNextStep(props.onStepChange, 2, "Less than 12 months")}}>
                    Less than 12<br/>
                    months
                </button>
                <button className="btn btn-dark-secondary btn-small lg-double-line mobile"
                    onClick={()=>{handleNextStep(props.onStepChange, 2, "Less than 12 months")}}>
                    Less than 12 months
                </button>
              </div>
              <div className="col col-12 col-lg-2 leasing-btn-wrapper">
                <button className="btn btn-dark-secondary btn-block lg-double-line desktop js-long-term-leasing"
                    onClick={()=>{handleNextStep(props.onStepChange, 2, "More than 12 months")}}>
                    More than 12<br/>
                    months
                </button>
                <button className="btn btn-dark-secondary btn-small lg-double-line mobile"
                    onClick={()=>{handleNextStep(props.onStepChange, 2, "More than 12 months")}}>
                    More than 12 months
                </button>
              </div>
            </div>
          </div>
        </div>
      );
}

export default LeasingStep1;