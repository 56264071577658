import React from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import TagManager from "react-gtm-module";
import defaultImg from "../../../assets/image/transparent.png";

const Highlight = (props) => {
  const {
    data = null,
    gtmEvent = null,
    gtmModuleName = null,
    index = null,
    imageSize = null,
  } = props || {};
  const {
    Image: image = null,
    ImageAltText: imageAltText = null,
    Title: title = null,
    Description: description = null,
    CTALink: url = null,
    CTAText: text = null,
    CTAType: type = null,
  } = data || {};

  let link =
    url && url.value && url.value.href
      ? { value: url.value.href }
      : url && url.value && url.value.length
        ? (link = url)
        : null;

  let linkTarget = (url && url.value && url.value && url.value.target) || null;

  return (
    <a
      className="col col-12 col-md-4 tile-range"
      href={
        link && link.value
          ? link.value
          : imageSize
            ? null
            : "javascript:void(0);"
      }
      target={linkTarget}
      onClick={() => {
        if (type && type.value !== "external") {
          props.handleOnClickEnquire(type.value);
        }
        TagManager.dataLayer({
          dataLayer: {
            event: gtmEvent,
            moduleName: gtmModuleName,
            moduleCardLink:
              type && type.value !== "external"
                ? type.value + " leasing"
                : link && link.value
                  ? link.value
                  : "",
          },
          dataLayerName: "dataLayer",
        });
      }}
    >
      <div className={`highlights-item__image ${imageSize}`}>
        {image && image.value ? (
          <img
            alt={imageAltText && imageAltText.value ? imageAltText.value : "enquiry-image" + index}
            src={defaultImg}
            data-src={image.value}
            className="lazyload"
          />
        ) : (
          <img alt="no-image" src={defaultImg} />
        )}
      </div>
      <div className="highlights-item__details">
        {title && title.value && (
          <div className="highlights-item__label">
            <Text field={title} />
          </div>
        )}
        {description && description.value && (
          <div className="highlights-item__description">
            <RichText field={description} />
          </div>
        )}
        {text && text.value && (
          <div className="highlights-item__cta">
            <span className="cta-label">
              <Text field={text} />
            </span>
            <i className="icon icon-cta">
              <span className="path1" />
              <span className="path2" />
            </i>
          </div>
        )}
      </div>
    </a>
  );
};

export default Highlight;
