import React, { Component } from "react";

class FormCheckbox extends Component {
  onInputChange = checked => {
    this.props.onCheckboxChange(this.props.type, this.props.value, checked);
  };

  render() {
    return (
      <React.Fragment>
        <label className="form-checkbox-container">
          {this.props.label}
          <input
            value={this.props.value}
            onChange={e => this.onInputChange(e.target.checked)}
            type="checkbox"
            checked={this.props.checked}
          />
          <span className="checkmark" />
        </label>
      </React.Fragment>
    );
  }
}

export default FormCheckbox;
