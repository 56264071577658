import React from "react";
import TagManager from "react-gtm-module";

const CtaHyperlink = (props) => {
  let { data, url, text, moduleName, target } = props || null;
  if (data && data.value) {
    url = data.value.href;
    text = data.value.text;
    target = data.value.targetl
  }
  let ctaLayout = "";
  if (url && text) {
    ctaLayout = (
      <div className="cta-link-wrapper">
        <a
          href={url}
          target={target}
          onClick={() =>
            TagManager.dataLayer({
              dataLayer: {
                event: "clickedModuleCta",
                moduleName,
                moduleCtaLink: url,
              },
              dataLayerName: "dataLayer",
            })
          }
        >
          <div className="cta-label">{text}</div>
          <i className="icon icon-cta">
            <span className="path1" />
            <span className="path2" />
          </i>
        </a>
      </div>
    );
  }

  return ctaLayout;
};

export default CtaHyperlink;
