import React, { Component } from "react";

class RiseAndFade extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valueHelperPos: 0
        };
    }

    componentDidMount() {
        const {
            index = null,
            columns = null,
            tabletColumns = null
        } = this.props || {};

        if (window) {
            window.addEventListener("scroll", this.handleScroll);
            if (index && columns) {
                this.setState({
                    valueHelperPos: this.valueHelper(index, columns, tabletColumns)
                });
            }
        }
    }

    handleScroll = () => {
        const imageWrapperList = document.querySelectorAll(".animation-rise-and-fade");
        if (imageWrapperList) {
            for (let i = 0; i < imageWrapperList.length; i++) {
                let imageWrapper = imageWrapperList[i];
                let position = imageWrapper.getBoundingClientRect();
                let isShowing = window.innerHeight >= position.top;
                let transformValue = imageWrapper.style.transform.split(/\w+\(|\);?/);
                let value = transformValue[1].split('%')[0];
                let opacity = 0;
                if (value > 0) {
                    if (isShowing) {
                        value = value - 1;
                        opacity = opacity + 0.3;
                    }
                }
                else {
                    value = 0;
                    opacity = 1;
                }
                imageWrapper.style.transform = `translateY(${value}%)`;
                imageWrapper.style.opacity = opacity;
            }
        }
    }


    valueHelper = (index, columns, tabletColumns) => {
        let value = 0;
        let checker;

        if (tabletColumns) {
            // Mobile breakpoint
            if (window && window.outerWidth && window.outerWidth < 768) {
                value = 6;
            }
            // Tablet breakpoint
            else if (window && window.outerWidth && window.outerWidth <= 768 && window.outerWidth < 992 && tabletColumns) {
                checker = index % tabletColumns;
            }

            // Desktop breakpoint
            else {
                checker = index % columns;
            }

            // Default checker
            if (checker == 1) {
                value = 6;
            } else if (checker == 2) {
                value = 8;
            } else if (checker == 3) {
                value = 10;
            } else if (checker == 4) {
                value = 12;
            }
        } else {
            checker = index % columns;

            if (checker == 1) {
                value = 6;
            } else if (checker == 2) {
                value = 8;
            } else if (checker == 3) {
                value = 10;
            } else if (checker == 4) {
                value = 12;
            }
        }

        return value;
    };

    render() {
        const {
            position = null,
            index = null,
            columns = null,
            tabletColumns = null
        } = this.props || {};
        const {
            valueHelperPos
        } = this.state;
        let divStyle, positionValue;
        if (position && !isNaN(position)) {
            positionValue = position;
        }
        else if (index && columns) {
            positionValue = valueHelperPos;
        }
        else {
            positionValue = 0;
        }

        divStyle = {
            transform: `translateY(${positionValue}%)`
        }
        return (
            <div className="animation-rise-and-fade" style={divStyle}>
                {this.props.children}
            </div>
        )
    }
}

export default RiseAndFade;