import React from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import CtaHyperlink from "../../../Base/CtaHyperlink";
import SectionDivider from "../../../Base/SmallModules/SectionDivider";
import { getImage } from "../../../../helper/ImageHelper";
import TagManager from "react-gtm-module";
import Highlight from "../../../Base/Highlight";

const setVisible = (props) => {
  if (!props.rendering.dataSource || props.rendering.dataSource === "") {
    return "hide";
  }
  return "show";
};

const FeatureGenericContentGenericImageHighlight = (props) => {
  const {
    SectionName = null,
    Title = null,
    Description = null,
    Image: image = null,
    ImageUrl = null,
    ImageAltText = null,
    SectionId = null,
    HighlightCallToAction = null,
    CTAText = null,
    SubItems = null,
  } = props.fields || {};

  let displaySubItems = false;
  let displayRange = 0;
  if (SubItems && SubItems.length && SubItems.length >= 3) {
    displaySubItems = true;
    displayRange = SubItems.length - (SubItems.length % 3);
  }

  return (
    setVisible(props) === "show" && (
      <BaseComponent
        margin="standard"
        sectionId={SectionId && SectionId.value ? SectionId.value : ""}
        setVisible={setVisible(props)}
      >
        <div className="container">
          <div className={`generic-image-highlight-container ${!(SectionName && SectionName.value) ? "no-section-title" : ""}`}>
            <SectionDivider margin="24" />
            {SectionName &&
              SectionName.value && (
                <div className="section-title">
                  <Text field={SectionName} />
                </div>
              )}
            {HighlightCallToAction && HighlightCallToAction.value && (
              <div className="row">
                <div className="col-12 image-range">
                  <div className="thumbnail">
                    <a
                      {...HighlightCallToAction.value}
                      onClick={() =>
                        TagManager.dataLayer({
                          dataLayer: {
                            event: "clickedModuleCard",
                            moduleName: "Generic Image Highlight",
                            moduleCtaLink:
                              HighlightCallToAction.value &&
                              HighlightCallToAction.value.href,
                          },
                          dataLayerName: "dataLayer",
                        })
                      }
                    >
                      <img {...getImage(ImageUrl, ImageAltText, image)} />
                    </a>
                  </div>
                </div>
              </div>
            )}
            {Title &&
              Title.value && (
                <div className="content-title">
                  <a>
                    <Text field={Title} />
                  </a>
                </div>
              )}
            {Description && <RichText field={Description} />}
            {HighlightCallToAction &&
              HighlightCallToAction.value &&
              HighlightCallToAction.value.href && (
                <CtaHyperlink
                  url={HighlightCallToAction.value.href}
                  text={
                    CTAText && CTAText.value
                      ? CTAText.value
                      : HighlightCallToAction.value.text
                        ? HighlightCallToAction.value.text
                        : "Learn more"
                  }
                  moduleName="Generic Image Highlight"
                />
              )}
          </div>
          {displaySubItems && (
            <div className="row highlights-wrapper">
              {SubItems.map((item, index) => {
                if (index < displayRange) {
                  return (
                    <Highlight
                      key={`${SectionName && SectionName.value}${index}`}
                      index={index}
                      data={item.fields}
                      gtmEvent="clickedModuleCard"
                      gtmModuleName="Generic Image Highlight Sub Item"
                      imageSize="highlight-gih"
                    />
                  );
                }
              })}
            </div>
          )}
        </div>
      </BaseComponent>
    )
  );
};

export default FeatureGenericContentGenericImageHighlight;
