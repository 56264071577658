import React, { createRef } from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import axios from "axios";
import TagManager from "react-gtm-module";
import ReCAPTCHA from "react-google-recaptcha";

class FooterLinks extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        pageId: "",
        dbName: "",
        name: "",
        emailAddress: "",
      },
      message: "",
      error: false,
      verifiedRecaptcha: false,
      expiredRecaptcha: false,
      load: false,
      recaptchaLoaded: false,
    };

    this.recatpchaRef = createRef();
  }

  validateFormat = (value) => {
    let re = new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$");
    return re.test(String(value).toLowerCase());
  };

  componentDidMount() {
    let contextInfo = window.document.getElementsByName("ContextItem")[0];
    let pageId = contextInfo.value;
    let dbName = contextInfo.dataset.targetDb;

    this.setState({
      ...this.state,
      formData: {
        pageId: pageId,
        dbName: dbName,
        name: "",
        emailAddress: "",
      },
    });

    setTimeout(() => {
      this.setState({ load: true });
    }, 1500);
  }

  subscribe = async (element) => {
    const { verifiedRecaptcha, formData } = this.state;
    const url = `/api/sitecore/subscription/subscribe`;
    let data = { ...formData };

    const recaptchaWrapper = document
      .getElementsByClassName("footer-subscribe-wrapper")[0]
      .querySelector(".footer-subscribe-recaptcha");

    if (recaptchaWrapper && !verifiedRecaptcha) {
      recaptchaWrapper.classList.add("is-required");
    }

    if (formData.emailAddress === "") {
      this.setState({
        ...this.state,
        message: "Please enter a valid email.",
        error: true,
      });
    }

    if ((recaptchaWrapper && !verifiedRecaptcha) || formData.emailAddress === "") {
      return;
    }

    if (formData.emailAddress !== "") {
      if (!this.validateFormat(formData.emailAddress)) {
        this.setState({
          ...this.state,
          message: "Please enter a valid email.",
          error: true,
        });
      } else {
        let response = await axios({
          method: "POST",
          url,
          data: data,
        });

        if (response && response.data) {
          if (response.data.IsSuccessful) {
            // this.recatpchaRef.current.props.grecaptcha.reset();
            if(recaptchaWrapper) {
              this.recaptcha.reset();
            }
            this.setState({
              ...this.state,
              message: response.data.Message,
              error: false,
              verifiedRecaptcha: false
            });
          } else {
            this.setState({
              ...this.state,
              message: response.data.Message,
              error: false,
            });
          }

          TagManager.dataLayer({
            dataLayer: {
              event: "subscribeFormSubmit",
            },
            dataLayerName: "dataLayer",
          });
        }
      }
    }
  };

  recaptchaLoaded = () => {
    console.log("Successful Load Recaptcha");
    this.setState({ recaptchaLoaded: true });
  };

  recaptchaExpired = () => {
    this.setState({
      verifiedRecaptcha: false,
      expiredRecaptcha: true,
    });
  };
  recaptchaVerifyResponse = (response) => {
    if (response) {
      this.setState({
        verifiedRecaptcha: true,
        expiredRecaptcha: false,
      });
    } else {
      this.setState({ verifiedRecaptcha: false });
    }
  };

  handleEmailChange = (e) => {
    let message = "";
    let error = false;

    if (this.state.error && !this.validateFormat(e.target.value)) {
      message = "Please enter a valid email.";
      error = true;
    }

    this.setState({
      ...this.state,
      formData: {
        ...this.state.formData,
        emailAddress: e.target.value,
      },
      message: message,
      error: error,
    });
  };


  render() {

    const { verifiedRecaptcha, expiredRecaptcha, load } = this.state;

    const {
      SubscribeTitle = null,
      SubscribeSummary = null,
      SubscribeEmailPlaceholder = null,
      SubscribeSubmitButton = null,
      SubscribePrivacyPolicy = null,
      EnableRecaptcha = null,
      PublicKey = null
    } = this.props.data || {};
    if (
      SubscribeTitle &&
      SubscribeTitle.value &&
      SubscribeSummary &&
      SubscribeSummary.value &&
      SubscribeEmailPlaceholder &&
      SubscribeEmailPlaceholder.value &&
      SubscribeSubmitButton &&
      SubscribeSubmitButton.value
    ) {
      return (
        <div className="footer-subscribe-wrapper">
          <div className="title">
            <Text field={SubscribeTitle} />
          </div>
          <div className="content">
            <Text field={SubscribeSummary} />
          </div>
          <div className="row">
            <div className="col-12 col-md-auto subscribe-email">
              <input
                type="email"
                id="email-field"
                className={
                  "form-control form-control-lg" +
                  (this.state.message ? " box-message" : "") +
                  (this.state.error ? " box-error" : "")
                }
                onChange={(e) => {
                  this.handleEmailChange(e);
                }}
                autoComplete="off"
              />
              <label
                className={
                  "form-control-placeholder" +
                  (this.state.formData.emailAddress ? " hasValue" : "")
                }
                htmlFor="email-field"
              >
                {SubscribeEmailPlaceholder.value
                  ? SubscribeEmailPlaceholder.value
                  : ""}
              </label>
              {this.state.message !== "" && (
                <label
                  className={
                    (this.state.message ? "text-message" : "") +
                    (this.state.error ? " text-error" : "")
                  }
                >
                  {this.state.message}
                </label>
              )}
            </div>
            <div className="col-12 col-md-auto subscribe-btn">
              <button className="btn btn-large btn-subscribe btn-block"
                onClick={(e) => this.subscribe(e)}
              >
                <Text field={SubscribeSubmitButton} />
              </button>
            </div>
            {load &&
              EnableRecaptcha &&
              EnableRecaptcha.value &&
              PublicKey &&
              PublicKey.value &&
              (
                <div
                  className={`col-12 footer-subscribe-recaptcha
                  ${verifiedRecaptcha ? "verifiedRecaptcha" : ""}
                  ${expiredRecaptcha ? "is-required" : ""}`
                  }
                >
                  <ReCAPTCHA
                    size="normal"
                    sitekey={PublicKey.value}
                    onChange={this.recaptchaVerifyResponse}
                    onExpired={this.recaptchaExpired}
                    asyncScriptOnLoad={this.recaptchaLoaded}
                    // ref={this.recatpchaRef}
                    ref={ref => this.recaptcha = ref}
                  />
                  <div className="recaptcha-validation">
                    <span>
                      <label>ReCaptcha is required</label>
                    </span>
                  </div>
                </div>
              )}
          </div>

          <div className="message">
            <RichText field={SubscribePrivacyPolicy} />
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default FooterLinks;
