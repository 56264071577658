import React, { Component } from "react";
import { Image } from "@sitecore-jss/sitecore-jss-react";
import { ReactSVG } from "react-svg";
import defaultImg from "../../../assets/image/transparent.png";

class HeaderImageFormat extends Component {
  constructor(props) {
    super(props);
  }

  validateImageFormat = (logo) => {
    const imageSource = logo.src;
    if (imageSource) {
      let svgPattern = new RegExp("svg");
      let urlPattern = new RegExp("https|http|.com");
      let isSVGFormat = svgPattern.test(imageSource.toLowerCase());
      let isUrlFormat = urlPattern.test(imageSource.toLowerCase());
      let imageFormat = "";

      if (isSVGFormat && isUrlFormat) {
        imageFormat = (
          <div className="logo-wrapper">
            <img className="cdn-url-image" alt="qicre logo" src={imageSource} />
            <svg className="cdn-url-image-svg">
              <filter id="filter-image-cdn" colorInterpolationFilters="sRGB">
                <feColorMatrix type="matrix" values="" />
              </filter>
              <image
                filter="url(#filter-image-cdn)"
                preserveAspectRatio="xMidYMid meet"
                xlinkHref={imageSource}
                x="0"
                y="0"
                width="100%"
                height="100%"
              />
            </svg>
          </div>
        );
      } else if (isSVGFormat) {
        imageFormat = <ReactSVG src={imageSource} />;
      } else {
        imageFormat = (
          <Image
            media={{ ...logo, src: defaultImg }}
            data-src={logo.src}
            className="lazyload"
          />
        );
      }
      return imageFormat;
    }
  };

  render() {
    const { logo, bgColor } = this.props;
    return (
      <a href="/" className="navbar-brand">
        {this.validateImageFormat(logo, bgColor)}
      </a>
    );
  }
}

export default HeaderImageFormat;
