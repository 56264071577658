import React from "react";

class CustomPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.data = props.data;
  }

  componentDidMount() {
    var supportsVideo = !!document.createElement("video").canPlayType;
    var videoControls = document.getElementById("video-controls");

    if (supportsVideo && videoControls) {
      var video = document.getElementById("video");
      video.controls = false;
      videoControls.setAttribute("data-state", "visible");
      var playpause = document.getElementById("playpause");
      video.muted = true;
      if (document.addEventListener) {
        var changeButtonState = function(type) {
          if (type == "playpause") {
            if (video.paused || video.ended) {
              playpause.setAttribute("data-state", "play");
              playpause.setAttribute("class", "icon icon-play");
            } else {
              playpause.setAttribute("data-state", "pause");
              playpause.setAttribute("class", "icon icon-pause");
            }
          }
        };
        video.addEventListener(
          "play",
          function() {
            changeButtonState("playpause");
          },
          false
        );
        video.addEventListener(
          "pause",
          function() {
            changeButtonState("playpause");
          },
          false
        );
        playpause.addEventListener("click", function(e) {
          if (video.paused || video.ended) video.play();
          else video.pause();
        });
      }
    }
  }

  render() {
    return (
      <div className="customplayer-wrapper">
        <figure id="videoContainer" data-fullscreen="false">
          {/* <video id="video" controls preload="metadata" poster="img/poster.jpg"> */}
          <div className="video-wrapper__1">
            <div className="video-wrapper__2">
              <div className="video-wrapper__3">
                <video id="video" controls preload="metadata">
                  <source src={this.data} type="video/mp4" />
                  <source src={this.data} type="video/webm" />
                  <source src={this.data} type="video/ogg" />
                </video>
              </div>
            </div>
          </div>
        </figure>
      </div>
    );
  }
}

export default CustomPlayer;
