import React from "react";
import BaseComponent from "../../../Base/BaseComponent";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import SectionDivider from "../../../Base/SmallModules/SectionDivider";
import defaultImg from "../../../../assets/image/transparent.png";
import ModuleHeader from "../../../Base/ModuleHeader";
import DownloadForm from "../../../Base/DownloadForm";

class FeatureFitoutDownloadableGuidelines extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formFieldState: props.fields.FormFields,
      showModal: false,
      modalDownloadLink: "#",
      fileId: ""
    };
  }

  renderAssets = assets => {
    return assets.map((item, index) => {
      const { AssetImage, AssetGuidelines } = item.fields;
      return (
        <div
          key={AssetImage.value + index}
          className="col-12 col-md-4 col-lg-3 downloadable-content"
        >
          <div className="downloadable-image">
            <div className="thumbnail">
              <img
                alt={`downloadable-image-${index}`}
                src={defaultImg}
                data-src={AssetImage && AssetImage.value ? AssetImage.value : ""}
                className="lazyload" />
            </div>
          </div>
          <div className="downloadable-link-list">
            <SectionDivider margin="24" />
            {AssetGuidelines.map((item, index) => {
              const { Name, Link, FileId } = item.fields || {};
              return (
                <div className="downloadable-link" key={Link.value + index}>
                  <button onClick={() => {
                    document.body.classList.add("scroll-disable");
                    this.setState({
                      showModal: true,
                      modalDownloadLink: Link.value,
                      fileId: FileId.value
                    })
                  }}>
                    <i className="icon icon-download "></i>
                    <span>{Name.value}</span>
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      );
    });
  };

  setVisible = (Assets) => {
    if (Assets && Assets.length > 0) {
      return "show";
    }
    return "hide";
  };

  render() {
    const {
      ModuleTitle = null,
      Assets = null,
      FormFields = null,
      EnableRecaptcha = null,
      PublicKey = null,
      PrivacyPolicy = null,
      SuccessMessageHeader,
      SuccessMessage,
      FailedMessage,
    } = this.props.fields || {};

    const {
      showModal,
      fileId,
      modalDownloadLink,
      formFieldState
    } = this.state;

    return (
      <BaseComponent
        margin="standard"
        setVisible={this.setVisible(Assets)}
      >
        {showModal &&
          <DownloadForm
            FormFields={formFieldState}
            EnableRecaptcha={EnableRecaptcha}
            PublicKey={PublicKey}
            PrivacyPolicy={PrivacyPolicy}
            SuccessMessageHeader={SuccessMessageHeader}
            SuccessMessage={SuccessMessage}
            FailedMessage={FailedMessage}
            fileId={fileId}
            modalDownloadLink={modalDownloadLink}
            showModal
            onClickClose={(value) => this.setState({
              showModal: false,
              formFieldState: value
            })}
            gtmComponent={"Fitout Downloadable Guidelines"}
          />
        }

        <div className="downloadable-guidelines-container">
          <div className="downloadable-header-bg">
            <div className="container">
              <ModuleHeader
                title={ModuleTitle && ModuleTitle.value ? ModuleTitle.value : ""}
                moduleName="Download and Fitout"
              />
            </div>
          </div>
          {Assets &&
            Assets.length > 0 && (
              <div className="downloadable-list">
                <div className="container">
                  <div className="row">{this.renderAssets(Assets)}</div>
                </div>
              </div>
            )}
        </div>

      </BaseComponent>
    );
  }
}

export default FeatureFitoutDownloadableGuidelines;
