import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";

const FeatureFormsRetailerNewsletter = props => {
  return (
    <BaseComponent margin="standard">
      <div className="newsletter-container">
        <div className="container">
          <div className="title">
            <Text field={props.fields.title} />
          </div>
          <div className="form-container">
            <div className="form-input-wrapper">
              <input
                className="form-control"
                type="text"
                placeholder="Email Address"
              />
            </div>
            <div className="form-submit-wrapper">
              <button type="button" className="btn btn-dark-primary btn-large">
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </div>
    </BaseComponent>
  );
};

export default FeatureFormsRetailerNewsletter;
