import React, { Component } from "react";
import Select from "react-select";

class MultiselectField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      optionsList: [],
      isInvalid: true,
      isTouched: false
    };
  }

  componentDidMount() {
    let optionsList = [];
    const { optionList } = this.props || {}
    optionList.map(item => {
      const { Name } = item.fields || {};
      optionsList.push({ value: Name.value, label: Name.value });
    });
    this.setState({ optionsList });
  }

  handleOnChange = options => {
    let optionsValue = [];
    if(options && options.length > 0) {
      options.map((item)=> {
        optionsValue.push(item.value);
      })
    }
    this.props.handleOnChangeMultiselect(optionsValue.toString());
    this.setState({
      options,
      isTouched: true,
      isInvalid: options && options.length > 0 ? false : true
    });
  };

  noOptionsMessage = () => {
    // const { options } = this.state;
    // let message = "Loading...";
    // if (options && options.length) {
    //   message = "No options";
    // }
    return <div className="loading-text">No options</div>;
  };

  formatOptionLabel = ({ label }, { inputValue }) => {
    let highlighted = label;
    if (inputValue && inputValue.length > 1) {
      highlighted = label.replace(
        new RegExp(inputValue, "gi"),
        highlighted => `<b>${highlighted}</b>`
      );
    }
    return <span dangerouslySetInnerHTML={{ __html: highlighted }} />;
  };

  render() {
    const { title, placeholder, isRequired = false, errorMessage = null, isSubmitted = false, } = this.props;
    const { optionsList, options, isInvalid, isTouched } = this.state;
    return (
      <div className={`multiselect-container ${((isTouched || isSubmitted) && isRequired && isInvalid) ? "is-required" : ""}`}>
        <div className="title">{title}</div>
        <Select
          //   className={formError.retailers ? "error" : ""}
          className="multiselect-field"
          isMulti
          onChange={this.handleOnChange}
          placeholder={placeholder}
          options={optionsList}
          value={options}
          components={{ NoOptionsMessage: this.noOptionsMessage }}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "#f5f5f6"
            }
          })}
          isClearable={false}
          backspaceRemovesValue={false}
          formatOptionLabel={this.formatOptionLabel}
        // isDisabled={isRetailersDisabled}
        />
        {(isTouched || isSubmitted) &&
          isRequired &&
          isInvalid &&
          <span>
            <label className="form-control-validation">
              {errorMessage}
            </label>
          </span>
        }
      </div>
    );
  }
}

export default MultiselectField;
