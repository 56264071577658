import React from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";

const FoundationLayoutTwoColumn7525 = props => {
  return (
    // <BaseComponent margin="standard">
    <BaseComponent>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-9 col-xl-9 two-col-layout">
            <div className="placeholder-wrapper">
              <Placeholder
                name="jss-two-column-75-25-left"
                rendering={props.rendering}
              />
            </div>
          </div>
          <div className="col-12 col-lg-3 col-xl-3 two-col-layout">
            <div className="placeholder-wrapper">
              <Placeholder
                name="jss-two-column-75-25-right"
                rendering={props.rendering}
                params={props.params}
              />
            </div>
          </div>
        </div>
      </div>
    </BaseComponent>
  );
};

export default FoundationLayoutTwoColumn7525;
