import React from "react";
import { Text, Image } from "@sitecore-jss/sitecore-jss-react";
import { getImage } from "../../../../helper/ImageHelper";
import defaultImg from "../../../../assets/image/transparent.png";
import TagManager from "react-gtm-module";
import HeroVideo from "../../Video";

const TilePlaces = (props) => {
  const type = props.type ? props.type : "";
  const {
    Thumbnail,
    ThumbnailType,
    ThumbnailUrl,
    ThumbnailAltText,
    Address,
    AssetName,
    Summary,
    ItemURL,
    AssetUrl,
  } = props.data;

  let hasSrcAssetLink = false;
  let hasSrcAssetUrlLink = false;
  let assetLink = "";
  let assetUrlLink = "";

  if (ItemURL && ItemURL.value && ItemURL.value.href) {
    assetLink = ItemURL.value;
    hasSrcAssetLink = true;
  } else if (ItemURL && ItemURL.value) {
    assetLink = ItemURL.value
  }

  if (AssetUrl && AssetUrl.value && AssetUrl.value.href) {
    assetUrlLink = AssetUrl.value;
    hasSrcAssetUrlLink = true;
  } else if (AssetUrl && AssetUrl.value) {
    assetUrlLink = AssetUrl.value
  }

  let imageValue = getImage(null, null, Thumbnail);

  const isVideo =
    ThumbnailType &&
      ThumbnailType.value &&
      ThumbnailType.value.toLowerCase() === "video"
      ? true
      : false;

  const items = (
    <React.Fragment>
      {ThumbnailUrl && ThumbnailUrl.value ? (
        <div className="thumbnail">
          {isVideo ? (
            <HeroVideo data={ThumbnailUrl.value} hasControls />
          ) : (
            <Image
              media={{ alt: ThumbnailAltText && ThumbnailAltText.value ? ThumbnailAltText.value : AssetName && AssetName.value ? AssetName.value + " Image" : "thumbnail-alt-text", src: defaultImg }}
              data-src={ThumbnailUrl.value}
              className="lazyload"
            />
          )}
        </div>
      ) : Thumbnail ? (
        <div className="thumbnail">
          <Image
            media={{ ...imageValue, src: defaultImg }}
            data-src={imageValue.src}
            className="lazyload"
          />
        </div>
      ) : props.showDefaultImg ? (
        <div className="thumbnail">
          <Image
            media={{ src: defaultImg }}
            data-src={defaultImg}
            className="lazyload"
          />
        </div>
      ) : null}
      {Address && (
        <div className="address">
          <Text field={Address} />
        </div>
      )}
      {AssetName &&
        AssetName.value && (
          <div className="assetname">
            {assetUrlLink && hasSrcAssetUrlLink ? (
              <a
                {...assetUrlLink}
                href={assetUrlLink.href + assetUrlLink.querystring}
                onClick={() =>
                  TagManager.dataLayer({
                    dataLayer: {
                      event: "clickedModuleCard",
                      moduleName: props.moduleName,
                      moduleCardLink: assetUrlLink.href,
                    },
                    dataLayerName: "dataLayer",
                  })
                }
              >
                <Text field={AssetName} />
              </a>
            ) : assetUrlLink && !hasSrcAssetUrlLink ?
              <a
                href={assetUrlLink}
                onClick={() =>
                  TagManager.dataLayer({
                    dataLayer: {
                      event: "clickedModuleCard",
                      moduleName: props.moduleName,
                      moduleCardLink: assetUrlLink,
                    },
                    dataLayerName: "dataLayer",
                  })
                }
              >
                <Text field={AssetName} />
              </a>
              : (
                <Text field={AssetName} />
              )}
          </div>
        )}
      {Summary && (
        <div className="summary-places">
          <Text field={Summary} />
        </div>
      )}
    </React.Fragment>
  );

  return (
    <div className={`tile${type ? "-featured" : ""}`}>
      {assetLink && hasSrcAssetLink ? (
        <a
          {...assetLink}
          href={assetLink.href + assetLink.querystring}
          onClick={() =>
            TagManager.dataLayer({
              dataLayer: {
                event: "clickedModuleCard",
                moduleName: props.moduleName,
                moduleCardLink: assetLink.href,
              },
              dataLayerName: "dataLayer",
            })
          }
        >
          {items}
        </a>
      ) :
        assetLink && !hasSrcAssetLink ?
          <a
            href={assetLink}
            onClick={() =>
              TagManager.dataLayer({
                dataLayer: {
                  event: "clickedModuleCard",
                  moduleName: props.moduleName,
                  moduleCardLink: assetLink,
                },
                dataLayerName: "dataLayer",
              })
            }
          >
            {items}
          </a>
          : (items)
      }
    </div>
  );
};

export default TilePlaces;
