import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import SectionDivider from "../../../Base/SmallModules/SectionDivider";
import mappedInHelper from "./Helpers/mappedInHelper";
import Level from "./Controls/Level";
import Zoom from "./Controls/Zoom";

const setVisible = props => {
  if (!props.rendering.dataSource || props.rendering.dataSource === "") {
    return "hide";
  }
  return "show";
};

class FeatureFloorPlanFloorPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      levelData: [],
      enableMap: false
    };
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    const script = document.createElement("script");
    const { Map } = this.props.fields || {};

    if (setVisible(this.props) === "show") {
      script.src =
        "https://d1p5cqqchvbqmy.cloudfront.net/websdk/v1.55.1/mappedin.js";
      script.type = "text/javascript";
      script.async = true;
      script.onload = () => {
        this.mappedIn = new mappedInHelper({
          levelCallback: levelData => {
            this.updateLevelState(levelData);
          },
          map: Map.fields
            ? {
              clientId: Map.fields.ClientId.value,
              clientSecret: Map.fields.ClientSecret.value,
              venue: Map.fields.Venue.value
            }
            : { clientId: "", clientSecret: "", venue: "" }
        });
      };
      document.body.appendChild(script);
      document.addEventListener("mousedown", this.handleClickOutsideFloorplan);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutsideFloorplan);
  }

  handleClickOutsideFloorplan = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ enableMap: false });
    }
  };

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  updateLevelState(levelData) {
    this.setState({
      ...this.state,
      levelData // set the lelvel data here
    });
  }

  handleLevelSelection(levelId) {
    this.mappedIn.clearMap();
    this.mappedIn.changeMap(levelId);
  }

  handleZoom(action) {
    if (action == "in") {
      this.mappedIn.zoomIn();
    } else if (action == "out") {
      this.mappedIn.zoomOut();
    } else {
      this.mappedIn.resetCamera();
    }
  }

  render() {
    const { levelData, enableMap } = this.state;
    const {
      Title = null,
      SectionId = null
    } = this.props.fields || {};
    return (
      setVisible(this.props) === "show" && (
        <BaseComponent
          margin="standard"
          sectionId={SectionId && SectionId.value ? SectionId.value : ""}
        >
          <div className="floorplan">
            <div className="container">
              <SectionDivider margin="24" />
              <div className="title">
                <Text field={Title} />
              </div>
              {this.props.fields &&
                this.props.fields.Map &&
                this.props.fields.Map.fields && (
                  <div className="map-wrapper" ref={this.setWrapperRef}>
                    <div className="level-wrapper">
                      <Level
                        data={levelData}
                        onLevelChange={levelId => {
                          this.handleLevelSelection(levelId);
                        }}
                      />
                    </div>
                    <div className="map-content">
                      <div
                        onClick={() => this.setState({ enableMap: true })}
                        className={`map-cover ${enableMap ? "d-none" : ""}`}
                      />
                    </div>
                    <Zoom
                      className="element-desktop"
                      onZoom={action => {
                        this.handleZoom(action);
                      }}
                    />
                  </div>
                )}
            </div>
          </div>
        </BaseComponent>
      )
    );
  }
}

export default FeatureFloorPlanFloorPlan;
