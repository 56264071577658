import React, { Component } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import TilePlaces from "../../../../Base/Tiles/TilePlaces";
import RiseAndFade from "../../../../Base/Animations/RiseAndFade";
import TagManager from "react-gtm-module";

class List extends Component {
  renderList = () => {
    const {
      AssetLists,
      NoResults,
      ShowFeatured
    } = this.props.list;

    if (AssetLists && AssetLists.length) {
      let Highlight = {};
      let Asset = AssetLists;
      if (ShowFeatured && ShowFeatured.value) {
        Highlight = AssetLists[0];
        Asset = AssetLists.slice(1);
      }

      const {
        ThumbnailType = null,
        ThumbnailUrl = null,
        Address = null,
        AssetName = null,
        Summary = null,
        AssetFeatures = null,
        ItemURL = null,
      } = Highlight || {};


      return (
        <div className="list-wrapper is-highlight">
          {ShowFeatured &&
            ShowFeatured.value &&
            <div className="row">
              <div className="col-12 image-range">
                <a
                  {...ItemURL.value}
                  onClick={() =>
                    TagManager.dataLayer({
                      dataLayer: {
                        event: "clickedModuleCard",
                        moduleName: "Asset Listing highlight",
                        moduleCardLink:
                          ItemURL && ItemURL.value && ItemURL.value.href ? ItemURL.value.href : "",
                      },
                      dataLayerName: "dataLayer",
                    })
                  }
                >
                  <TilePlaces
                    data={{
                      ThumbnailUrl,
                      ThumbnailType,
                    }}
                    type="featured"
                    showDefaultImg
                    moduleName="Asset Listing highlight"
                  />
                </a>
              </div>
              <div className="col-12 ">
                <TilePlaces
                  data={{
                    Address,
                    AssetName,
                    Summary,
                    AssetFeatures,
                    AssetUrl: ItemURL,
                  }}
                  type="featured"
                  moduleName="Asset Listing highlight"
                />
              </div>
            </div>
          }

          <div className="has-rise-and-fade">
            <div className="row">
              {Asset && Asset.length > 0 &&
                Asset.map((item, index) => {
                  return (
                    <div
                      key={`tilePlacesId-${index}`}
                      className="col-12 col-md-4 col-lg-3 tile-range"
                    >
                      <RiseAndFade
                        index={index}
                        columns={4}
                        tabletColumns={3}
                      >
                        <TilePlaces
                          data={item}
                          showDefaultImg
                          moduleName="Asset Listing"
                        />
                      </RiseAndFade>
                    </div>
                  );
                })
              }
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="no-result">
          <Text field={NoResults} />
        </div>
      );
    }
  };
  render() {
    return (
      <React.Fragment>
        {this.props.list.AssetLists && (
          <div className="assetlisting-list">
            <div className="container">{this.renderList()}</div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default List;
