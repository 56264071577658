import React from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import SectionDivider from "../../../Base/SmallModules/SectionDivider";

class FeatureRetailerStoryChapter extends React.Component {
  constructor(props) {
    super(props);
  }

  setVisible = () => {
    if (
      !this.props.rendering.dataSource ||
      this.props.rendering.dataSource === ""
    ) {
      return "hide";
    }
    return "show";
  };

  render() {
    const {
      ChapterId = null,
      Heading = null,
      Body = null,
      isAlternateFont = false
    } = this.props.fields || {};

    const markproFont = 'markpro';
    const requiresAlternateFont = isAlternateFont && isAlternateFont.value

    return (
      this.setVisible() === "show" && (
        <BaseComponent setVisible={this.setVisible()}>
          <div className="retailerstory-chapter">
            <div className="container">
              <div className="wrapper">
                <div
                  className="story-item"
                  id={ChapterId && ChapterId.value ? ChapterId.value : ""}
                >
                  {Heading &&
                    Heading.value ? (
                      <div className="title">
                        <Text field={Heading} />
                      </div>
                    ) : (
                      <SectionDivider margin="20" />
                    )}
            <div className="description" style={{ fontFamily:  requiresAlternateFont && markproFont, lineHeight: requiresAlternateFont && '1.5'}}> 
                    <RichText field={Body} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BaseComponent>
      )
    );
  }
}

export default FeatureRetailerStoryChapter;
