import React, { Component } from "react";
import FormDropdown from "../../../../Base/Form/FormDropDown";

class LeasingStep2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      show: false,
      showField: null
    };
  }

  handleNextStep = (onStepChangeFunc, step) => {
    onStepChangeFunc(step);
  };

  handleSelectionChange = (onFormDataChange, value, field) => {
    let obj = field;
    obj[Object.keys(obj)[0]] = value;
    if (Object.keys(obj)[0] === "preferredCentre") {
      obj.LeasingRecipients =
        this.props.centreRecipients &&
          this.props.centreRecipients[value] &&
          this.props.centreRecipients[value].LeasingRecipients
          ? this.props.centreRecipients[value].LeasingRecipients
          : "";
    }
    onFormDataChange(obj);
    if (this.state.show) {
      this.setState({ show: !this.state.show });
    }
  };

  handleToggle = (e, field) => {
    this.setState({ showField: field, show: !this.state.show });
  };

  render() {
    const {
      countries,
      states,
      preferredCentres,
      onStepChange,
      onFormDataChange
    } = this.props || {};
    return (
      <div className="form-wrapper">
        <div className="container">
          <div className="row">
            <div className="col step is-old">STEP 2 OF 4</div>
            <div className="col step is-new">STEP 1 OF 3</div>
          </div>
          <div className="row title-section">
            <div className="col title">Choose a location</div>
          </div>
          <div className="row field-section">
            <div className="col col-12 col-lg-5">
              <div className="dropdown-container">
                <label className={`arrow border-bottom`}>
                  <div className="dropdown-placeholder">
                    {this.props.formData["country"] && <label>Country</label>}
                    <input
                      type="button"
                      value={
                        this.props.formData["country"]
                          ? this.props.formData["country"]
                          : countries && countries.length && countries[0].name
                      }
                      className={`dropdown-btn ${
                        this.props.formData["country"] ? "" : "no-default-value"
                        }`}
                      onClick={e => this.handleToggle(e, "country")}
                    />
                  </div>
                  <i className="icon icon-chevrondown"></i>
                </label>
                {this.state.show && this.state.showField === "country" && (
                  <FormDropdown
                    selected={value => {
                      this.handleSelectionChange(onFormDataChange, value, {
                        country: "",
                        state: "",
                        preferredCentre: ""
                      });
                    }}
                    options={countries}
                    active={
                      this.props.formData["country"]
                        ? this.props.formData["country"]
                        : countries && countries.length && countries[0].name
                    }
                  />
                )}
              </div>

              <div className="dropdown-container">
                <label className={`arrow border-bottom`}>
                  <div className="dropdown-placeholder">
                    {this.props.formData["state"] && <label>State</label>}
                    <input
                      type="button"
                      value={
                        this.props.formData["state"]
                          ? this.props.formData["state"]
                          : "State"
                      }
                      className={`dropdown-btn ${
                        this.props.formData["state"] ? "" : "no-default-value"
                        }`}
                      onClick={e => this.handleToggle(e, "state")}
                    />
                  </div>
                  <i className="icon icon-chevrondown"></i>
                </label>
                {this.state.show && this.state.showField === "state" && (
                  <FormDropdown
                    selected={value => {
                      this.handleSelectionChange(onFormDataChange, value, {
                        state: "",
                        preferredCentre: ""
                      });
                    }}
                    options={states}
                    active={this.props.formData["state"]}
                  />
                )}
              </div>

              <div className="dropdown-container">
                <label className={`arrow border-bottom`}>
                  <div className="dropdown-placeholder">
                    {this.props.formData["preferredCentre"] && (
                      <label>Preferred centre</label>
                    )}
                    <input
                      type="button"
                      value={
                        this.props.formData["preferredCentre"]
                          ? this.props.formData["preferredCentre"]
                          : "Preferred centre"
                      }
                      className={`dropdown-btn ${
                        this.props.formData["preferredCentre"]
                          ? ""
                          : "no-default-value"
                        }`}
                      onClick={e => this.handleToggle(e, "centres")}
                    />
                  </div>
                  <i className="icon icon-chevrondown"></i>
                </label>
                {this.state.show &&
                  this.state.showField === "centres" &&
                  preferredCentres &&
                  preferredCentres !== {} && (
                    <FormDropdown
                      selected={value => {
                        this.handleSelectionChange(onFormDataChange, value, {
                          preferredCentre: ""
                        });
                      }}
                      options={preferredCentres}
                      active={this.props.formData["preferredCentre"]}
                    />
                  )}
              </div>
            </div>
          </div>
          <div className="row submit-section fixed-row-bottom">
            <div className="col">
              <button
                className="btn btn-dark-secondary btn-large"
                disabled={(this.props.formData["country"] && this.props.formData["state"] && this.props.formData["preferredCentre"]) ? false : true}
                onClick={() => {
                  this.handleNextStep(onStepChange, 3);
                }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LeasingStep2;
