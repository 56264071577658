import React, { Component } from "react";
import { Text, Image } from "@sitecore-jss/sitecore-jss-react";
import loadGoogleMapsApi from "load-google-maps-api";
import _ from "lodash";
import defaultImg from "../../../../../assets/image/transparent.png";
import TagManager from "react-gtm-module";
import Button from "../../../../Base/Button";
import HeroVideo from "../../../../Base/Video";

class Map extends Component {
  constructor(props) {
    super(props);

    this.map = null;
    this.googleMaps = null;
    this.markers = [];
    this.countries = {
      "United States": { lat: 37.09024, lng: -95.712891 },
      Australia: { lat: -28.024, lng: 140.887 },
    };
  }

  addMarkers(locations) {
    let me = this;
    const { onMarkerClick } = me.props;
    me.markers = locations.map((location) => {
      const {
        Latitude,
        Longitude,
        AssetName,
        MapPinColor
      } = location

      let hexColor = "#3054a5"

      if (MapPinColor && MapPinColor.value) {
        hexColor = MapPinColor.value;
      }
      const svgPinMarker = {
        path: "M7.758.86c-4.136 0-7.5 3.25-7.5 7.244 0 4.957 6.712 12.233 6.997 12.54a.692.692 0 0 0 1.005 0c.286-.307 6.998-7.583 6.998-12.54 0-3.994-3.365-7.243-7.5-7.243zm0 11.25a3.754 3.754 0 0 1-3.75-3.75 3.754 3.754 0 0 1 3.75-3.75 3.754 3.754 0 0 1 3.75 3.75 3.754 3.754 0 0 1-3.75 3.75z",
        fillColor: hexColor,
        fillOpacity: 1,
        strokeColor: hexColor,
        strokeWeight: 1,
        scale: 1,
      };
      let marker = new me.googleMaps.Marker({
        position: {
          lat: parseFloat(Latitude.value),
          lng: parseFloat(Longitude.value),
        },
        title: AssetName.value,
        map: me.map,
        icon: svgPinMarker
      });
      marker.addListener("click", function () {
        me.handleMarkerClick(onMarkerClick, location);
      });
      return marker;
    });
  }

  clearMarkers() {
    if (this.markers && this.markers.length > 0)
      this.markers.map((marker) => {
        marker.setMap(null);
      });
  }

  handleMarkerClick(markerClickCallback, location) {
    // this.map.setCenter({
    //   lat: parseFloat(location.Latitude.value),
    //   lng: parseFloat(location.Longitude.value) - parseFloat(0.0012),
    // });
    // this.map.setZoom(18);

    if (markerClickCallback) {
      markerClickCallback(location);
    }
  }

  componentDidMount() {
    let me = this;
    const { locations, country, apiKey } = this.props;
    loadGoogleMapsApi({
      key: apiKey,
      apiUrl: "https://maps.googleapis.com/maps/api/js",
    })
      .then(function (googleMaps) {
        me.map = new googleMaps.Map(document.getElementById("map"), {
          center: me.countries[country],
          zoom: 4,
          styles: [
            // styles: [
            //   {
            //     stylers: [
            //       {
            //         saturation: -100,
            //       },
            //     ],
            //   },
            // ],
            {
              featureType: "administrative",
              stylers: [
                { saturation: -100 },
              ]
            },
            {
              featureType: "landscape",
              stylers: [
                { saturation: -100 },
              ]
            },
            {
              featureType: "poi",
              stylers: [
                { saturation: -100 },
              ]
            },
            {
              featureType: "road",
              stylers: [
                { saturation: -100 },
              ]
            },
            {
              featureType: "transit",
              stylers: [
                { saturation: -100 },
              ]
            },
          ],
        });
        me.googleMaps = googleMaps;
        me.addMarkers(locations);
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  componentDidUpdate(prevProps) {
    const { locations, country } = this.props;

    if (
      prevProps.country !== country ||
      !_.isEqual(prevProps.locations, locations)
    ) {
      if (this.map) {
        this.map.setCenter(this.countries[country]);
        this.map.setZoom(4);
        this.clearMarkers();
        this.addMarkers(locations);
      }
    }
  }

  renderModal = () => {
    if (this.props.selectedLocation) {
      const {
        ThumbnailUrl,
        ThumbnailType,
        AssetName,
        Address,
        Summary,
        AssetFeatures,
        ItemURL,
      } = this.props.selectedLocation;

      const isVideo =
        ThumbnailType &&
          ThumbnailType.value &&
          ThumbnailType.value.toLowerCase() === "video"
          ? true
          : false;

      return (
        <div className="map-modal">
          <div className="image">
            {ThumbnailUrl && ThumbnailUrl.value ? (
              isVideo ? (
                <HeroVideo data={ThumbnailUrl.value} hasControls />
              ) : (
                <Image
                  media={{ ...ThumbnailUrl.value, src: defaultImg }}
                  data-src={ThumbnailUrl.value}
                  className="img-fluid lazyload"
                />
              )
            ) : (
              <Image
                media={{ src: defaultImg }}
                className="img-fluid lazyload"
              />
            )}

            <i
              onClick={() => {
                this.props.resetSelectedLocation();
              }}
              className="icon icon-close"
            />
          </div>
          <div className="details">
            <div className="address">
              <Text field={Address} />
            </div>
            <div className="assetname">
              <Text field={AssetName} />
            </div>
            <div className="summary">
              <Text field={Summary} />
            </div>
            <div className="assetfeatures">
              {AssetFeatures &&
                AssetFeatures.length > 0 &&
                AssetFeatures.map((item, index) => {
                  return (
                    <span key={`featuresId-${index}`}>
                      <Image media={item.Icon} />
                      <Text field={item.Name} />
                    </span>
                  );
                })}
            </div>
            <div className="buttons">
              {ItemURL && ItemURL.value && ItemURL.value.href &&
                <a
                  {...ItemURL.value}
                  href={ItemURL.value.href + ItemURL.value.querystring}
                  onClick={() =>
                    TagManager.dataLayer({
                      dataLayer: {
                        event: "assetListingViewAsset",
                      },
                      dataLayerName: "dataLayer",
                    })
                  }
                  className="btn btn-light-primary btn-medium"
                >
                  <span className="btn-label">{"View"}</span>
                  <i className="icon icon-chevron-up-2" />
                </a>
              }
              <Button
                onClick={() => this.props.showEnquireModal()}
                color={"light-secondary"}
                size={"medium"}
                label={"Enquire"}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  render() {
    return (
      <div className="map-wrapper">
        {this.renderModal()}
        <div id="map" />
      </div>
    );
  }
}

export default Map;
