import React from "react";
import { Text, Image } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";

class FeatureKeyStatsKeyStatsMap extends React.Component {
  constructor(props) {
    super(props);
  }

  renderList(keyStatsList) {
    if (
      keyStatsList &&
      keyStatsList.length > 0
    ) {
      return keyStatsList.map((item, index) => {
        const {
          Title: Title = null,
          Value: Value = null,
          Suffix = null
        } = item.fields;
        // const lastIndex = Value.value.length - 1;
        // const valueNumber = Value.value.substring(0, lastIndex);
        // const valuePercent = Value.value.substring(lastIndex);
        return (
          <div className="item" key={`keyId-${index}`}>
            {Title.value && Value.value && (
              <div>
                <div className="name align-bottom">
                  <Text field={Title} />
                </div>
                <div className="value">
                  <span><Text field={Value} /></span>
                  <span className="percent"><Text field={Suffix} /></span>
                </div>
              </div>
            )}
          </div>
        );
      });
    }
  }

  validateDisplay(keyStatsList, demographicsLegendList, map) {
    let display = "hide";
    if (
      this.props.rendering.dataSource &&
      this.props.rendering.dataSource !== "" &&
      keyStatsList &&
      demographicsLegendList &&
      map &&
      map.value &&
      map.value.src !== ""
    ) {
      display = "show";
    }
    return display;
  }

  render() {
    const {
      SectionId = null,
      IsOverlapping = null,
      Title = null,
      Subtext = null,
      Map: map = null,
      MapOrientation = null,
      KeyStatsList = null,
      DemographicsLegendList = null
    } = this.props.fields || {};

    return (this.validateDisplay(KeyStatsList, DemographicsLegendList, map) === 'show' &&
      <BaseComponent
        setVisible={this.validateDisplay(KeyStatsList, DemographicsLegendList, map)}
        margin="standard"
        sectionId={SectionId && SectionId.value ? SectionId.value : ""}
      >
        <div
          className={
            "key-stat-map-container " +
            (IsOverlapping && IsOverlapping.value ? "overlap" : "")
          }
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-7 col-lg-6 order-xl-1 order-lg-1 order-md-1 order-12">
                <div className="hero">
                  <Image
                    media={map}
                    className={
                      MapOrientation && MapOrientation.value === "Vertical"
                        ? "map-vertical"
                        : "map-horizontal"
                    }
                  />
                </div>
                <div className="stat-map-legend-wrapper">
                  {/* <div className="stat-map-content"> */}
                  <div className="row stat-map-content">
                    {DemographicsLegendList &&
                      DemographicsLegendList.map(
                        (item, index) => {
                          const {
                            Name: Name = null,
                            Colour: Colour = null
                          } = item.fields;
                          return (
                            <div
                              className="col-12 col-md-6 stat-map-content-text"
                              key={`keyId-${index}`}
                            >
                              <div className="row no-gutters">
                                <div className="col-auto">
                                  <div
                                    className="legend"
                                    style={{ backgroundColor: Colour.value }}
                                  />
                                </div>

                                <div className="col">
                                  <div className="text">
                                    <Text field={Name} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md order-xl-12 order-lg-12 order-md-12 order-1">
                <div className="title">
                  <Text field={Title} />
                </div>
                <div className="subtext">
                  <Text field={Subtext} />
                </div>
                <div>
                  <div className="item-container">{this.renderList(KeyStatsList)}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BaseComponent>
    );
  }
}

export default FeatureKeyStatsKeyStatsMap;
