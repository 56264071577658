import React from "react";
import { Text, Link } from "@sitecore-jss/sitecore-jss-react";
import TagManager from "react-gtm-module";

const FooterLinks = (props) => {
  const { FooterMainLinks } = props.data || {};

  return (
    <div className="row footer-links-wrapper">
      {FooterMainLinks &&
        FooterMainLinks.length > 0 &&
        FooterMainLinks.map((item, index) => {
          let {
            Title: Title = null,
            FooterMain: FooterMain = null,
            MenuLink: MenuLink = null,
          } = item.fields;
          let footerLinksLayout;
          let noFooterLinks = true;

          if (Title && Title.value && FooterMain && FooterMain.length > 0) {

            let footerMainLayout =
              FooterMain.map((linkitem, linkindex) => {
                let { MainLink: MainLink = null } = linkitem.fields;
                let footerMainLinkLayout;
                if (
                  MainLink &&
                  MainLink.value &&
                  MainLink.value.href &&
                  MainLink.value.text
                ) {
                  noFooterLinks = false
                  footerMainLinkLayout = (
                    <div
                      key={`footerLinkId-${linkindex}`}
                      className="content"
                    >
                      <Link
                        field={{
                          ...MainLink.value,
                          href:
                            MainLink.value.href +
                            MainLink.value.querystring,
                        }}
                        onClick={() =>
                          TagManager.dataLayer({
                            dataLayer: {
                              event: "clickedModuleCta",
                              moduleName: "Retailer Footer",
                              moduleCtaLink:
                                MainLink.value.href +
                                MainLink.value.querystring,
                            },
                            dataLayerName: "dataLayer",
                          })
                        }
                      />

                      {MainLink.value.text.toLowerCase() === "careers" && (
                        <a
                          {...MainLink.value}
                          onClick={() =>
                            TagManager.dataLayer({
                              dataLayer: {
                                event: "clickedModuleCta",
                                moduleName: "Retailer Footer",
                                moduleCtaLink: MainLink.value.href,
                              },
                              dataLayerName: "dataLayer",
                            })
                          }
                        >
                          <i className="icon icon-external" />
                        </a>
                      )}
                    </div>
                  );
                }
                return footerMainLinkLayout;
              })


            if (MenuLink && MenuLink.value && MenuLink.value.href) {
              noFooterLinks = false
            }

            footerLinksLayout = (
              <div
                key={`footerItem-${index}`}
                className={`col-6 col-lg-4 item-${index + 1} footer-links-wrapper__item
                ${noFooterLinks ? "is-hidden" : ""}`}
              >
                <div className="title">
                  {MenuLink !== null ? (
                    <a
                      href={MenuLink.value.href}
                      onClick={() =>
                        TagManager.dataLayer({
                          dataLayer: {
                            event: "clickedModuleCta",
                            moduleName: "Retailer Footer",
                            moduleCtaLink: MenuLink.value.href,
                          },
                          dataLayerName: "dataLayer",
                        })
                      }
                    >
                      <Text field={Title} />
                    </a>
                  ) : (
                    <Text field={Title} />
                  )}
                </div>
                {footerMainLayout}
              </div>
            );
          }
          return footerLinksLayout;
        })}
    </div>
  );
};

export default FooterLinks;
