import React from "react";
import BaseComponent from "../../../Base/BaseComponent";
import PartnerItems from "../PartnerItems";

const FeaturePartnersPartnerListing = props => {
  const {
    Partners = null,
    SectionId = null
  } = props.fields || {};
  return (
    <BaseComponent margin="standard" sectionId={SectionId && SectionId.value ? SectionId.value : ""}>
      {Partners &&
        <div className="partner-listing-wrapper container">
          <PartnerItems data={Partners} />
        </div>
      }
    </BaseComponent>
  );
};

export default FeaturePartnersPartnerListing;
