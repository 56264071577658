import React from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import SectionDivider from "../../../Base/SmallModules/SectionDivider";
import { getImage } from "../../../../helper/ImageHelper";

const FeatureFitoutTimeline = props => {
  const { Description = null, TimelineActivities = null, SectionId = null } =
    props.fields || {};
  return (
    <BaseComponent
      margin="standard"
      sectionId={SectionId && SectionId.value ? SectionId.value : ""}
    >
      {TimelineActivities.length !== undefined &&
        TimelineActivities.length !== 0 && (
          <div className="fitout-timeline-wrapper container">
            <SectionDivider margin="24" />
            {Description && Description.value && (
              <div className="description">
                <Text field={Description} />
              </div>
            )}
            {TimelineActivities.map((item, index) => {
              const {
                Title: Title = null,
                Duration: Duration = null,
                Description: Description = null,
                Image: Image = null
              } = item.fields || {};
              return (
                <div key={`timelineId-${index}`} className="tile-wrapper">
                  <div className="number">{index + 1}</div>
                  <div className="row item-wrapper no-bottom-margin">
                    <div className="col col-12 col-md-6">
                      <div className="title">
                        <Text field={Title} />
                      </div>
                      <div className="time">
                        <Text field={Duration} />
                      </div>
                      <div className="summary">
                        <RichText field={Description} />
                      </div>
                    </div>
                    <div className="col">
                      <div className="photo">
                        <div className="image-range">
                          <div className="thumbnail">
                            <img
                              {...getImage(null, null, Image)}
                              className="lazyload"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
    </BaseComponent>
  );
};

export default FeatureFitoutTimeline;
