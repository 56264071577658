import React from "react";
import { Text, Image } from "@sitecore-jss/sitecore-jss-react";
import { getImage } from "../../../../helper/ImageHelper";
import defaultImg from "../../../../assets/image/transparent.png";

const TilePartners = props => {
  const {
    PartnerImage: PartnerImage = null,
    PartnerName: PartnerName = null,
    PartnerDescription: PartnerDescription = null,
    PartnerWebsiteUrl: PartnerWebsiteUrl = null,
    PartnerWebsiteText: PartnerWebsiteText = null
  } = props.item.fields || {};

  let imageValue = getImage(null, null, PartnerImage);
  return (
    <div>
      <div className="item-image">
        <div className="row">
          <div className="col-12 image-range">
            <div className="thumbnail">
              <Image
                media={{ ...imageValue, src: defaultImg }}
                data-src={imageValue.src}
                className="lazyload"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="item-name">
        <Text field={PartnerName} />
      </div>
      <div className="item-summary">
        <Text field={PartnerDescription} />
      </div>
      <div className="item-visitwebsite">
        <a
          href={PartnerWebsiteUrl.value}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Text field={PartnerWebsiteText} />
        </a>
        <a
          href={PartnerWebsiteUrl.value}
          target="_blank"
          rel="noopener noreferrer"
          className="item-visitwebsite-icon"
        >
          <i className="icon icon-external" />
        </a>
      </div>
    </div>
  );
};

export default TilePartners;
