import React, { Component } from "react";
import BaseComponent from "../../../Base/BaseComponent";
import TilePlaces from "../../../Base/Tiles/TilePlaces";
import ModuleHeader from "../../../Base/ModuleHeader";
import Button from "../../../Base/Button";
import TagManager from "react-gtm-module";

class FeatureAssetsAssetListHighlights extends Component {
  constructor(props) {
    super(props);
    this.state = { gate: false };
  }

  setVisible = (dataSource, featuredCentres) => {
    if (
      !dataSource ||
      dataSource === "" ||
      !featuredCentres ||
      !featuredCentres.length
    ) {
      return "hide";
    }
    return "show";
  };

  showItems = (featuredCentres) => {
    return (
      <div className="row">
        {featuredCentres &&
          featuredCentres.map((item, index) => {
            let {
              Thumbnail = null,
              Location = null,
              Name = null,
              SummaryStatement = null,
              AssetFeatures = null,
              AssetLink = null,
            } = item.fields || {};
            return (
              <div
                key={`highlightId-${index}`}
                className={`col-12 col-md-4 col-lg-3 tile-range ${
                  !this.state.gate ? "item-show" : ""
                }`}
              >
                <TilePlaces
                  data={{
                    Thumbnail,
                    Address: Location,
                    AssetName: Name,
                    Summary: SummaryStatement,
                    AssetFeatures,
                    ItemURL: AssetLink,
                  }}
                  moduleName="Asset Listing Highlights"
                />
              </div>
            );
          })}
      </div>
    );
  };

  showButton = () => {
    if (!this.state.gate) {
      return (
        <div className="btn-wrapper">
          <Button
            onClick={() => {
              this.setState({ gate: true });
              TagManager.dataLayer({
                dataLayer: {
                  event: "clickedModuleCta",
                  moduleName: "Asset Listing Highlights",
                  moduleCtaLink: "Show More",
                },
                dataLayerName: "dataLayer",
              });
            }}
            label={"Show More"}
            color={"light-secondary"}
            size={"medium"}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  showFeatured = (featuredAsset) => {
    if (featuredAsset && featuredAsset.fields) {
      const {
        Thumbnail = null,
        Location = null,
        Name = null,
        SummaryStatement = null,
        AssetFeatures = null,
        AssetLink = null,
      } = featuredAsset.fields;
      return (
        <div className="row">
          <div className="col-12 image-range">
            <a
              href={AssetLink && AssetLink.value ? AssetLink.value : ""}
              onClick={() =>
                TagManager.dataLayer({
                  dataLayer: {
                    event: "clickedModuleCard",
                    moduleName: "Asset Listing Highlights",
                    moduleCardLink:
                      AssetLink && AssetLink.value ? AssetLink.value : "",
                  },
                  dataLayerName: "dataLayer",
                })
              }
            >
              <TilePlaces
                data={{
                  Thumbnail,
                }}
                type="featured"
                moduleName="Asset Listing Highlights"
              />
            </a>
          </div>
          <div className="col-12">
            <TilePlaces
              data={{
                Address: Location,
                AssetName: Name,
                Summary: SummaryStatement,
                AssetFeatures,
                AssetUrl: AssetLink,
              }}
              type="featured"
              moduleName="Asset Listing Highlights"
            />
          </div>
        </div>
      );
    } else return null;
  };

  render() {
    const {
      Title = null,
      ViewAll = null,
      FeaturedAsset = null,
      FeaturedCentres = null,
    } = this.props.fields || {};
    const { dataSource } = this.props.rendering;
    return (
      this.setVisible(dataSource, FeaturedCentres) === "show" && (
        <BaseComponent
          setVisible={this.setVisible(dataSource, FeaturedCentres)}
          margin="standard"
        >
          <div className="assetlisthighlights-wrapper">
            <div className="container">
              <ModuleHeader
                title={Title && Title.value ? Title.value : ""}
                data={ViewAll}
                moduleName="Asset Listing Highlights"
                isStandard={true}
              />
              {this.showFeatured(FeaturedAsset)}
              <div className={`list-wrapper ${!this.state.gate ? "has-show-btn": ""}`}>
                {this.showItems(FeaturedCentres)}
                {this.showButton()}
              </div>
            </div>
          </div>
        </BaseComponent>
      )
    );
  }
}

export default FeatureAssetsAssetListHighlights;
