import React from "react";
import SecondaryLinks from "../../../Base/SmallModules/SecondaryLinks";

const FeatureESGESGQuickLinks = props => {
  const {
    Links
  } = props.fields || {};
  if (
    props.rendering.dataSource &&
    props.rendering.dataSource !== "" &&
    Links.length !== undefined &&
    Links.length !== 0
  ) {
    return (
      <div className="retailerquicklinks-wrapper">
        <SecondaryLinks data={props.fields} />
      </div>
    );
  } else {
    return null;
  }
};

export default FeatureESGESGQuickLinks;
