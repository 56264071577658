export const getUrlParameter = name => {
    // name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    var results = regex.exec(window.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };
  
  export const changeUrlParameter = (name, value) => {
    window.location.search = `?${name}=${value}&sc_mode=normal`;
  };
  
  
  export const addParameter = (url, param, value) => {
    // Using a positive lookahead (?=\=) to find the
    // given parameter, preceded by a ? or &, and followed
    // by a = with a value after than (using a non-greedy selector)
    // and then followed by a & or the end of the string
    var val = new RegExp('(\\?|\\&)' + param + '=.*?(?=(&|$))'),
      qstring = /\?.+$/;
  
    // Check if the parameter exists
    if (val.test(url)) {
      // if it does, replace it, using the captured group
      // to determine & or ? at the beginning
      return url.replace(val, '$1' + param + '=' + value);
    }
    else if (qstring.test(url)) {
      // otherwise, if there is a query string at all
      // add the param to the end of it
      return url + '&' + param + '=' + value;
    }
    else {
      // if there's no query string, add one
      return url + '?' + param + '=' + value;
    }
  }