import React from "react";
import BaseComponent from "../../../Base/BaseComponent";

class FeatureRetailerStorySocialShare extends React.Component {
  constructor(props) {
    super(props);
  }

  renderList = () => {
    const { Social = null } = this.props.fields || {};
    if (Social.length) {
      return Social.map((item, index) => {
        const { Type, Url } = item.fields;
        return (
          <div className="item" key={`socialId-${index}`}>
            <a href={Url.value} target="_blank">
              <i className={`icon icon-${Type.value}`} />
            </a>
          </div>
        );
      });
    }
  };

  render() {
    const { params } = this.props;
    return (
      <BaseComponent>
        <div
          className={`retailerstory-socialshare ${
            params ? (params.IsInsideContainer ? "inside-container" : "") : ""
            }`}
        >
          <div className="container">
            <div className="wrapper">
              <div className="retailerstory-socialshare__title font-markpro-bold">Share</div>
              {this.renderList()}
            </div>
          </div>
        </div>
      </BaseComponent>
    );
  }
}

export default FeatureRetailerStorySocialShare;
