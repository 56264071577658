import React, { Component } from "react";
import { Text, Image } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import ModuleHeader from "../../../Base/ModuleHeader";
import Button from "../../../Base/Button";
import defaultImg from "../../../../assets/image/transparent.png";
import TagManager from "react-gtm-module";

class FeatureLeadershipFeaturedLeadership extends Component {
  constructor(props) {
    super(props);
    this.leadershiplist = React.createRef();
    this.hideButton = false;
  }

  state = {
    hideButton: false,
  };

  showmorelist = () => {
    this.setState({ hideButton: true });
    this.leadershiplist.current.classList.remove("leadershiplist");
    TagManager.dataLayer({
      dataLayer: {
        event: "clickedModuleCta",
        moduleName: "Featured Leadership",
        moduleCtaLink: "View More",
      },
      dataLayerName: "dataLayer",
    });
  };

  setVisible = (LeadershipList) => {
    if (
      !this.props.rendering.dataSource ||
      this.props.rendering.dataSource === "" ||
      !(LeadershipList && LeadershipList.length)
    ) {
      return "hide";
    }
    return "show";
  };

  render() {
    const {
      Title = null,
      LeadershipList = null,
      LeadershipPageLink = null,
      ViewMoreText = null,
    } = this.props.fields || {};
    return (
      <BaseComponent margin="standard" setVisible={this.setVisible(LeadershipList)}>
        <div className="container">
          <div className="leadership-container">
            <ModuleHeader
              title={Title && Title.value ? Title.value : ""}
              data={LeadershipPageLink}
              moduleName="Featured Leadership"
              isStandard={true}
            />
            <div className="row leadershiplist--wrapper leadershiplist" ref={this.leadershiplist}>
              {LeadershipList && LeadershipList.length > 0 &&
                LeadershipList.map((items, index) => {
                  return (
                    <div
                      className="col-lg-3 col-md-4 col-xs-12 leadership-content-wrapper tile-range"
                      key={"leadershiplistID" + index}
                    >
                      <div className="thumbnail">
                        <Image
                          media={{
                            ...items.fields.ProfileImage.value,
                            src: defaultImg,
                          }}
                          data-src={items.fields.ProfileImage.value.src}
                          className="leadership-image mx-auto d-block lazyload"
                        />
                      </div>
                      <div className="content-name">
                        <Text field={items.fields.ProfileName} />
                      </div>
                      <div className="content-description">
                        <Text field={items.fields.ProfilePosition} />
                      </div>
                    </div>
                  );
                })}
            </div>
            {LeadershipList &&
              LeadershipList.length > 2 &&
              <div className="view-more">
                <Button
                  color={"light-secondary"}
                  size={"medium"}
                  onClick={this.showmorelist}
                  className={
                    "view-more-button" +
                    (this.state.hideButton ? " hidden" : "")
                  }
                >
                  <Text field={ViewMoreText} />
                </Button>
              </div>
            }
          </div>
        </div>
      </BaseComponent>
    );
  }
}

export default FeatureLeadershipFeaturedLeadership;
