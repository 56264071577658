import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";

class FeatureRetailerStoryTags extends React.Component {
  constructor(props) {
    super(props);
  }

  renderList = (tags) => {
    if (tags.length) {
      return tags.map((item, index) => {
        const { TagName } = item.fields;
        return (
          <div className="item" key={`tagsId-${index}`}>
            <Text field={TagName} />
          </div>
        );
      });
    }
  };

  setVisible = (tags) => {
    if (!tags.length) {
      return "hide";
    }
    return "show";
  };
  render() {
    const { Tags = null } = this.props.fields || {};
    return (
      <BaseComponent setVisible={this.setVisible(Tags)}>
        <div className="retailerstory-tags">
          <div className="container">
            <div className="wrapper">
              <div className="label">Tags</div>
              {this.renderList(Tags)}
            </div>
          </div>
        </div>
      </BaseComponent>
    );
  }
}

export default FeatureRetailerStoryTags;
