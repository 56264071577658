import React, { Component } from "react";
import { Text, Image } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import ModuleHeader from "../../../Base/ModuleHeader";
import Button from "../../../Base/Button";
import defaultImg from "../../../../assets/image/transparent.png";
import TagManager from "react-gtm-module";

class FeatureAssetsAssetsDiscoverCentre extends Component {
  constructor(props) {
    super(props);
    this.state = { gate: false };
  }

  setVisible = (featuredCentres) => {
    if (featuredCentres && featuredCentres.length > 0 && this.props.rendering.dataSource) {
      return "show";
    }
    return "hide";
  };

  render() {
    const {
      Title = null,
      ViewAll = null,
      FeaturedCentres = null,
      SectionId = null,
      BackgroundColor = null
    } = this.props.fields || {};
    let { RemoveDefaultMargin = "" } = this.props.params || {};
    let margin =
      RemoveDefaultMargin && RemoveDefaultMargin === "1" ? "" : "standard";

    let showItems = () => {
      return (
        <div className="row item-wrapper">
          {FeaturedCentres &&
            FeaturedCentres.length > 0 &&
            FeaturedCentres.map((item, index) => {
              let {
                Thumbnail: thumbnail = null,
                Location: location = null,
                Name: name = null,
                SummaryStatement: summary = null,
                AssetFeatures: features = null,
                AssetLink: link = null,
              } = item.fields || {};
              let PartialFeatures = [];
              //let NewFeatures = [];
              if (features && features.length) {
                features.map((item) => {
                  if (
                    item.fields.Icon &&
                    item.fields.Icon.value &&
                    item.fields.Icon.value.src &&
                    item.fields.Name &&
                    item.fields.Name.value
                  ) {
                    PartialFeatures.push(item);
                  }
                });
              }
              return (
                <div
                  key={`myId-${index}`}
                  className={`col col-12 col-md-4 tile-range ${!this.state.gate ? "item-show" : ""
                    }`}
                >
                  <a
                    href={link.value}
                    onClick={() =>
                      TagManager.dataLayer({
                        dataLayer: {
                          event: "clickedModuleCard",
                          moduleName: "Asset Discover Centre",
                          moduleCardLink: link.value,
                        },
                        dataLayerName: "dataLayer",
                      })
                    }
                  >
                    <div className="item-container">
                      <div className="item-image thumbnail">
                        <Image
                          media={{ ...thumbnail.value, src: defaultImg }}
                          data-src={thumbnail.value.src}
                          className="lazyload"
                        />
                      </div>
                      <div className="item-text">
                        <div className="item-location">
                          <Text field={location} />
                        </div>
                        <div className="item-centre">
                          <Text field={name} />
                        </div>
                        <div className="item-summary">
                          <Text field={summary} />
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              );
            })}
        </div>
      );
    };
    let showButton = () => {
      if (!this.state.gate) {
        return (
          <div className="btn-wrapper">
            <Button
              onClick={() => {
                this.setState({ gate: !this.state.gate });
                TagManager.dataLayer({
                  dataLayer: {
                    event: "clickedModuleCta",
                    moduleName: "Asset Discover Centre",
                    moduleCtaLink: "Show More",
                  },
                  dataLayerName: "dataLayer",
                });
              }}
              label={"Show More"}
              color={"light-secondary"}
              size={"medium"}
            />
          </div>
        );
      } else {
        return null;
      }
    };
    return (
      <BaseComponent
        sectionId={SectionId && SectionId.value ? SectionId.value : ""}
        setVisible={this.setVisible(FeaturedCentres)}
        margin={margin}
      >
        <div className={`discoverplaces-wrapper 
        ${BackgroundColor && BackgroundColor.value && BackgroundColor.value.toLowerCase() === "white" ? "is-bg-white" : ""}`}>
          <div className="container">
            <ModuleHeader
              title={Title && Title.value ? Title.value : ""}
              data={ViewAll}
              moduleName="Asset Discover Centre"
              isStandard={BackgroundColor && BackgroundColor.value && BackgroundColor.value.toLowerCase() === "white" ? true : false}
            />
            <div className="places-wrapper">
              {showItems()}
              {showButton()}
            </div>
          </div>
        </div>
      </BaseComponent>
    );
  }
}

export default FeatureAssetsAssetsDiscoverCentre;
