import React from "react";
import TagManager from "react-gtm-module";
import SectionDivider from "../SmallModules/SectionDivider";

const ModuleHeader = (props) => {
  let { title, data, url, text, moduleName, isStandard } = props || null;
  let moduleHeaderLayout = null;
  let targetVal = "";
  if (data && data.value && data.value.href) {
    url = data.value.href + data.value.querystring;
    text = data.value.text;
    targetVal = data.value.target;
  }

  if (title) {
    moduleHeaderLayout = (
      <div className={`small-header-wrapper ${isStandard ? "is-standard" : ""}`}>
        <SectionDivider margin="28" />
        <div className="small-header-container">
          <div className="title">
            <a
              href={url}
              className="d-block d-md-none"
              onClick={() =>
                TagManager.dataLayer({
                  dataLayer: {
                    event: "clickedModuleCta",
                    moduleName,
                    moduleCtaLink: url,
                  },
                  dataLayerName: "dataLayer",
                })
              }
              target={targetVal}
            >
              {title}
            </a>
            <span className="d-none d-md-block">{title}</span>
          </div>
          {url && text && (
            <div className="cta-link-wrapper">
              <a
                href={url}
                onClick={() =>
                  TagManager.dataLayer({
                    dataLayer: {
                      event: "clickedModuleCta",
                      moduleName,
                      moduleCtaLink: url,
                    },
                    dataLayerName: "dataLayer",
                  })
                }
                target={targetVal}
              >
                <span>{text}</span>
                <i className="icon icon-cta">
                  <span className="path1" />
                  <span className="path2" />
                </i>
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }
  return moduleHeaderLayout;
};

export default ModuleHeader;
