import React from 'react';
import BaseComponent from '../../../Base/BaseComponent';
import Highlight from '../../../Base/Highlight';

const FeatureGenericContentFeaturedImageHighlights = (props) => {

  const {
    SectionId,
    SectionName,
    FeaturedItems
  } = props.fields;

  let displaySubItems = false;
  let displayRange = 0;
  if (FeaturedItems && FeaturedItems.length && FeaturedItems.length >= 3) {
    displaySubItems = true;
    displayRange = FeaturedItems.length - (FeaturedItems.length % 3);
  }

  if (displaySubItems) {
    return (
      <BaseComponent
        margin="standard"
        sectionId={SectionId && SectionId.value ? SectionId.value : ""}
      >
        <div className='featured-image-highlight'>
          <div className="container">
            <div className="row highlights-wrapper">
              {FeaturedItems.map((item, index) => {
                if (index < displayRange) {
                  return (
                    <Highlight
                      key={`${SectionName && SectionName.value}${index}`}
                      index={index}
                      data={item.fields}
                      gtmEvent="clickedModuleCard"
                      gtmModuleName="Generic Image Highlight Sub Item"
                      imageSize="highlight-gih"
                    />
                  );
                }
              })}
            </div>
          </div>
        </div>
      </BaseComponent>
    )
  } else return null;
};

export default FeatureGenericContentFeaturedImageHighlights;
