export const composeSecondaryNav = route => {
  let navList = [];
  if (route.placeholders["jss-body"]) {
    route.placeholders["jss-body"].map(component => {
      const {
        placeholders,
        fields
      } = component

      const {
        SectionId = null,
        SectionName = null
      } = fields || {}

      if (SectionId && SectionId.value && SectionName && SectionName.value) {
        navList.push({
          SectionId: component.fields.SectionId.value,
          SectionName: component.fields.SectionName.value
        });
      }
      if (placeholders) {
        let placeholderObject = Object.keys(placeholders);
        if (placeholderObject.length > 0) {
          placeholderObject.map((item) => {
            let componentPlaceholder = placeholders[item][0];
            if (componentPlaceholder) {
              const {
                SectionId = null,
                SectionName = null
              } = componentPlaceholder.fields || {}

              if (SectionId && SectionId.value && SectionName && SectionName.value) {
                navList.push({
                  SectionId: SectionId.value,
                  SectionName: SectionName.value
                });
              }
            }
          })
        }
      }
    });
  }

  const { AssetName: assetName = null, PageTitle: pageTitle = null } =
    route.fields || {};

  return {
    navList,
    assetName,
    pageTitle
  };
};

export const getHeaderBgColor = route => {

  const {
    HeaderBackgroundColor,
    TransparentHeader: transparentHeader = null,
    UseWhiteColorforContent: useWhiteColorforContent = {}
  } = route.fields || {};

  // const {
  //   Colour: colour = null,
  //   Hex: hex = null
  // } = headerBackgroundColor.fields || {}


  // fields: {
  //   Colour: { value: 'Tan-Light' },
  //   Hex: { value: '#FBF5F0' }
  // }
  // Button Blue '#354552'
  // Racing Green Light #233f2f
  // Light Pink #B098A3
  // Tan Light #FBF5F0
  // Tan #E6C7A7

  // default colors
  let colour = HeaderBackgroundColor && HeaderBackgroundColor.fields ? HeaderBackgroundColor.fields.Colour.value : 'Tan-Light';
  let bgColor = 'header-light'; //black for content
  let bgColorHex = HeaderBackgroundColor && HeaderBackgroundColor.fields ? HeaderBackgroundColor.fields.Hex.value : '#FBF5F0'; //hex.value //tan-light for header-bg


  // class types
  // header-light
  // header-dark
  // header-white
  // header-clear

  // if transparent return header-clear else check color to determine class type

  if (transparentHeader && transparentHeader.value) {
    bgColor = "header-clear";
    bgColorHex = ""
  }
  else if (colour && colour.value && colour.value === "White") bgColor = "header-white"
  else if (useWhiteColorforContent && useWhiteColorforContent.value) {
    if (useWhiteColorforContent.value) bgColor = "header-dark"
    else bgColor = "header-light"
  }

  return {
    bgColor, bgColorHex
  };
};

export const headerCutout = route => {
  const { NoCutout = null } = route.fields || {};
  let noCutout = false;
  if (NoCutout && NoCutout.value) {
    noCutout = true;
  }
  return { noCutout };
};

export const getPageName = route => {
  let pageName = route.templateName;
  if (route.templateName === "Generic Content Page") {
    pageName = route.name;
  }
  return pageName;
};
