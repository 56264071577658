import React from "react";
import BaseComponent from "../../../Base/BaseComponent";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import Highlight from "../../../Base/Highlight";

class FeatureEnquireEnquiryHighlights extends React.Component {
  constructor(props) {
    super(props);
  }

  handleOnClickEnquire(type) {
    let modal = "";
    if (type.toLowerCase() === "long-term") {
      modal = document.getElementsByClassName("modal-enquire-form-leasing")[0];
      const longTermBtn = modal.querySelector(".js-long-term-leasing");
      this.triggerEvent(longTermBtn, "click");
    } else if (type.toLowerCase() === "short-term") {
      modal = document.getElementsByClassName("modal-enquire-form-leasing")[0];
      const shortTermBtn = modal.querySelector(".js-short-term-leasing");
      this.triggerEvent(shortTermBtn, "click");
    } else if (type.toLowerCase() === "media") {
      modal = document.getElementsByClassName("modal-enquire-form-media")[0];
    }

    if (modal) {
      modal.style.display = "block";
      modal.classList.add("enquire-modal-open");
      if (modal.ownerDocument) {
        modal.ownerDocument.body.classList.add("scroll-disabled-body");
      }
    }
  }

  // Manual Trigger Event
  triggerEvent = (element, event) => {
    var evt;
    if (document.createEvent) {
      evt = document.createEvent("HTMLEvents");
      evt.initEvent(event, true, true);
      evt.eventName = event;
      element.dispatchEvent(evt);
    } else {
      evt = document.createEventObject();
      evt.eventName = event;
      evt.eventType = event;
      element.fireEvent("on" + evt.eventType, evt);
    }
  };

  render() {
    const { Title = null, Enquiries = null } = this.props.fields || {};
    return (
      <BaseComponent
        margin={"standard"}
        setVisible={Enquiries && Enquiries.length > 0 ? "" : "hide"}
      >
        <div className="enquiry-highlights">
          <div className="enquiry-highlights__header">
            <div className="container">
              {Title && Title.value && (
                <div className="enquiry-highlights__title">
                  <Text field={Title} />
                </div>
              )}
            </div>
          </div>
          <div className="container">
            <div className="row highlights-wrapper">
              {Enquiries &&
                Enquiries.length > 0 &&
                Enquiries.map((item, index) => {
                  return (
                    <Highlight
                      key={`EnquiryHighlights${index}`}
                      index={index}
                      data={item.fields}
                      gtmEvent="clickedModuleCard"
                      gtmModuleName="Enquiry Highlights"
                      handleOnClickEnquire={(type) =>
                        this.handleOnClickEnquire(type)
                      }
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </BaseComponent>
    );
  }
}

export default FeatureEnquireEnquiryHighlights;
