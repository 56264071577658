import React, { Component } from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import $ from "jquery";

class FeatureGenericContentRetailerObjectBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    //this.appendScript();
  }

  appendScript() {
    const { Object: object } = this.props.fields;
    if (object && object.value) {
      let parser = new DOMParser();
      let scripts = parser
        .parseFromString(object.value, "text/html")
        .body.querySelectorAll("script");
      if (scripts && scripts.length) {
        for (let i = 0; i < scripts.length; i++) {
          $("head").append(String(scripts[i].outerHTML));
        }
      }
    }
  }

  render() {
    const { Object: object, SectionId = null, FixedHeight } = this.props.fields;
    if (object && object.value) {
      return (
        <BaseComponent
          margin="standard"
          sectionId={SectionId && SectionId.value ? SectionId.value : ""}
        >
          <div className={`object-block container ${FixedHeight && FixedHeight.value ? 'fixed-height' : ''}`}>
            <RichText field={object} />
          </div>
        </BaseComponent>
      );
    } else return null;
  }
}

export default FeatureGenericContentRetailerObjectBlock;
