import React, { Fragment } from "react";
import { Link, Text } from "@sitecore-jss/sitecore-jss-react";
import HeaderImageFormat from "../../../Base/HeaderImageFormat/index.js";
import { displayPageHeader } from "../../../../helper/HeaderHelper.js";
import TagManager from "react-gtm-module";
import { getAncestorElement } from "../../../../helper/GetAncestorElement.js";
import UpDownArrows from "../../../../assets/image/arrows.svg";
import Flickity from 'react-flickity-component'

class FeatureRetailerNavigationTopNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNavMenu: false,
      placesActive: false,
      displayPageHeader: false,
      isStoryPage: false,
      isHomePage: false,
      isPropertiesListing: false,
      isExpanded: false,
      isMobile: false,
      reportActive: null,
    };
    this.mobileNav = React.createRef();
    this.flkty = React.createRef();
  }

  componentDidMount() {
    const { IsBreadcrumbsSticky = null } = this.props.fields || {};
    if (window && window.location) {
      const path = window.location.pathname.toLowerCase();
      if (path.indexOf("/places/") !== -1) {
        this.setState({ placesActive: true });
      } else if (path.length === 1) {
        this.setState({
          isHomePage: true
        })
      }

      const isListing = new RegExp(/(news|stories|case(\-|%20)studies)/i);
      const re = new RegExp(/(news|stories|case(\-|%20)studies)($|\/$)/i);

      if (isListing.test(path)) {
        this.setState({ isStoryPage: !re.test(path) });
      }

      this.setState({ displayPageHeader: displayPageHeader(path) });

      if ("/properties".match(path) || "/properties/".match(path)) {
        const retailerHeroWrapper = document.querySelector(".retailerhero-wrapper");
        const retailerGenericHeader = document.querySelector(".retailer-generic-header-wrapper");

        if (!retailerHeroWrapper && !retailerGenericHeader) {
          this.setState({
            displayPageHeader: false,
            isPropertiesListing: true
          })
        }
      }

      if (document.querySelector(".report-list.hide-esg")) {
        this.onPageRefresh();
      }

      if (IsBreadcrumbsSticky && IsBreadcrumbsSticky.value) {
        this.stickyNavBar();
      }

      this.hideMobile();
      window.addEventListener("resize", this.hideMobile);

      window.addEventListener("click", this.jumpToScroll);

      const windowHash = window.location.hash.replace("#", '');
      if (windowHash) {
        const componentElement = document.getElementById(windowHash);
        setTimeout(() => {
          if (componentElement.getBoundingClientRect().top !== 0) {
            const secondNavLink = document.getElementsByClassName(windowHash)[0];
            if (!secondNavLink) {
              let yPos = componentElement.getBoundingClientRect().top + window.pageYOffset;
              window.scrollTo({ top: yPos, behavior: 'smooth' });
            }
          }
        }, 2500);
      }
    }

    const parent = document.querySelectorAll(".nav-item");
    if (parent && parent.length) {
      for (let i = 0; i < parent.length; i++) {
        const sub = parent[i].querySelector(".navbar-nav-items");
        if (sub && !sub.hasChildNodes()) {
          const hasIcon = parent[i].querySelector(".subnav-item");
          hasIcon && hasIcon.remove();
          sub.remove();
        }
      }
    }
  }

  componentDidUpdate() {
    // check flkty in instance
    if (this.flkty) {
      // current
      if (this.flkty.current === null) return
      if (this.flkty.cells) this.flkty.selectCell(this.state.reportActive, false, true)

    }
  }
  onPageRefresh = () => {
    const reportNav = document.querySelector(".report-list.hide-esg");

    reportNav.style.opacity = 0;
    window.onload = () => { reportNav.style.opacity = 1; };

    setTimeout(() => {
      if (reportNav.style.opacity == 0) {
        reportNav.style.opacity = 1;
      }
    }, 2000);
  }

  hideMobile = () => {
    const reportNav = document.querySelector(".report-list.hide-esg");
    const reportNavVisible = document.querySelector(".report-list");

    if (document.body.classList.contains("scroll-disable")) { document.body.classList.remove("scroll-disable"); }

    if (reportNav || reportNavVisible) {
      if (reportNav) {
        reportNav.style.opacity = 0;
        if (window.matchMedia('only screen and (max-width: 767px)').matches) {
          reportNav.style.top = "-" + (reportNav.clientHeight + 1) + "px";
          this.setState({ isMobile: true })
        } else {
          reportNav.style.top = "-200px";
          this.setState({ isMobile: false })
        }

        setTimeout(() => {
          if (reportNav.style.opacity == 0) {
            reportNav.style.opacity = 1;
          }
        }, 500);
      } else if (reportNavVisible) {
        reportNavVisible.style.opacity = 0;
        if (window.matchMedia('only screen and (max-width: 767px)').matches) {
          reportNavVisible.style.top = "-" + (reportNavVisible.clientHeight + 1) + "px";
          this.setState({ isMobile: true })
        } else {
          reportNavVisible.style.top = "-200px";
          this.setState({ isMobile: false })
        }

        setTimeout(() => {
          if (reportNavVisible.style.opacity == 0) {
            reportNavVisible.style.opacity = 1;
          }
        }, 500);
      }
    }
  }

  jumpToScroll = () => {
    const jumpTo = document.getElementsByClassName("jump-to")[0];

    if(jumpTo) {
      jumpTo.addEventListener("click", jumpClick());
    } 

    function jumpClick() {
      if (window.matchMedia('only screen and (max-width: 767px)').matches) {
        const reportNav = document.querySelector(".report-list.hide-esg");

        if (!reportNav) {
          document.body.classList.add("scroll-disable");
        } else {
          document.body.classList.remove("scroll-disable");
        }
      }
    }
  }

  stickyNavBar = () => {
    let scrollPos = 0;
    let navbar = document.getElementsByClassName("breadcrumbs")[0];
    let stickyNavbar = document.getElementsByClassName("sticky-breadcrumbs")[0];
    const reportList = document.querySelector(".report-list");
    const mobileNavSticky = document.querySelector(".report-nav-mobile");

    window.addEventListener("scroll", handleScroll);

    function handleScroll() {
      stickyFunction();
      if (stickyNavbar.classList.value.indexOf("is-sticky") > -1) {
        if (scrollDirection()) {
          stickyNavbar.classList.add("is-sticky");
          stickyNavbar.style.top = "0px";
          if (window.matchMedia('only screen and (max-width: 767px)').matches) {
            mobileNavSticky.classList.remove("hideSticky");
            mobileNavSticky.style.top = stickyNavbar.clientHeight + "px";
          }
        } else {
          stickyNavbar.style.top = "-" + navbar.clientHeight + "px";
          stickyNavbar.classList.remove("is-sticky");
          reportList.classList.add("hide-esg");
          if (window.matchMedia('only screen and (max-width: 767px)').matches) {
            reportList.style.top = "-" + (reportList.clientHeight + 1) + "px";
            mobileNavSticky.classList.add("hideSticky");
            mobileNavSticky.style.top = "-" + (mobileNavSticky.clientHeight + 1) + "px";
          } else {
            reportList.style.top = "-200px";
          }
        }
      }
    }

    function stickyFunction() {
      let additionalHeight = 0;
      let sticky;
      if (window.outerWidth < 768) {
        additionalHeight = 75;
      } else {
        additionalHeight = 144;
      }
      sticky = navbar.offsetTop + navbar.clientHeight + additionalHeight;
      if (window.pageYOffset >= sticky) {
        stickyNavbar.classList.add("is-sticky");
        stickyNavbar.style.top = "0px";
        if (window.matchMedia('only screen and (max-width: 767px)').matches) {
          mobileNavSticky.classList.remove("hideSticky");
          mobileNavSticky.style.top = stickyNavbar.clientHeight + "px";
        }
      } else {
        stickyNavbar.style.top = "-" + navbar.clientHeight + "px";
        stickyNavbar.classList.remove("is-sticky");
        reportList.classList.add("hide-esg");
        if (window.matchMedia('only screen and (max-width: 767px)').matches) {
          reportList.style.top = "-" + (reportList.clientHeight + 1) + "px";
          mobileNavSticky.classList.add("hideSticky");
          mobileNavSticky.style.top = "-" + (mobileNavSticky.clientHeight + 1) + "px";
        } else {
          reportList.style.top = "-200px";
        }
      }
    }

    function scrollDirection() {
      let isScrollUp = false;
      if (document.body.getBoundingClientRect().top > scrollPos) {
        isScrollUp = false;
      } else {
        isScrollUp = true;
      }
      scrollPos = document.body.getBoundingClientRect().top;
      return isScrollUp;
    }
  };

  showNavMenu = () => {
    this.setState({ showNavMenu: true });
    if (
      this.mobileNav &&
      this.mobileNav.current &&
      this.mobileNav.current.ownerDocument
    ) {
      this.mobileNav.current.ownerDocument.body.classList.add(
        "scroll-disabled"
      );
    }
  };

  hideNavMenu = () => {
    this.setState({ showNavMenu: false });
    if (
      this.mobileNav &&
      this.mobileNav.current &&
      this.mobileNav.current.ownerDocument
    ) {
      this.mobileNav.current.ownerDocument.body.classList.remove(
        "scroll-disabled"
      );

      const el = document.querySelectorAll(".is-nav-active");
      if (el) {
        for (let i = 0; i < el.length; i++) {
          el[i].classList.remove("is-nav-active");
        }
      }
    }
  };

  handleClick = (e, hasSubNav) => {
    const element = e.target;
    const list = getAncestorElement(element, "nav-item");
    const opened = document.querySelector(".is-nav-active");
    if (
      element.nodeName !== "A" &&
      list &&
      window.outerWidth < 990 &&
      hasSubNav
    ) {
      if (list.querySelector(".navbar-nav-items")) {
        if (opened && opened !== list) {
          opened.classList.toggle("is-nav-active");
        }

        list.classList.toggle("is-nav-active");
      }
    }
  };

  render() {
    const {
      NavigationFields = null,
      LogoLightTheme = null,
      LogoDarkTheme = null,
      Breadcrumbs = null,
      IsBreadcrumbsSticky = null,
      ESGReportNavigation = null,
      ESGReportNavJumpToText = null,
    } = this.props.fields || {};
    const {
      showNavMenu,
      isStoryPage,
      displayPageHeader,
      placesActive,
      isHomePage,
      isPropertiesListing
    } = this.state;
    const { bgColor = null, bgColorHex = null, noCutout = null } = this.props.headerparams || {};
    const { pageTitle } = this.props.params || {};

    const flickityOptions = {
      imagesLoaded: true,
      pageDots: false,
      wrapAround: false,
      draggable: false,
      selectedAttraction: 0.04,
      adaptiveHeight: false,
      accessibility: false,
      cellAlign: 'left',
      initialIndex: 0,
      watchCSS: true,
      contain: true,
      groupCells: true
    };

    const showESGReports = () => {
      // this.hideMobile();
      const reportListHide = document.querySelector(".report-list.hide-esg");
      const reportList = document.querySelector(".report-list");
      const activeReport = reportList.querySelector('.is-active').firstElementChild.focus()
      let stickyNavbar = document.getElementsByClassName("sticky-breadcrumbs")[0];
      let mobileNavSticky = document.getElementsByClassName("report-nav-mobile")[0];

      if (reportListHide) {
        this.setState({ isExpanded: true });
        reportListHide.classList.remove("hide-esg");
        if (this.state.isMobile) {
          reportListHide.style.top = (stickyNavbar.clientHeight + mobileNavSticky.clientHeight) + "px";
        } else {
          reportListHide.style.top = stickyNavbar.clientHeight + "px";
        }
      } else if (reportList) {
        this.setState({ isExpanded: false });
        reportList.classList.add("hide-esg");
        if (this.state.isMobile) {
          reportList.style.top = "-" + reportList.clientHeight + "px";
        } else {
          reportList.style.top = "-200px";
        }
      }
    };

    return (
      <div
        className={`top-navigation-container ${showNavMenu ? "fixed" : ""} ${bgColor
          ? bgColor === "header-clear"
            ? showNavMenu
              ? "header-white"
              : "header-clear"
            : bgColor
          : ""
          }
        ${isHomePage ? "is-home-page" : ""}
        ${isPropertiesListing ? "is-properties-listing" : ""}
        `}

        style={{ backgroundColor: bgColorHex ? bgColorHex : '' }}
      >
        <nav className="navbar navbar-expand-lg navbar-light ">
          <div className="container">
            {(bgColor === "header-light" ||
              bgColor === "header-white" ||
              bgColor === "header-clear") &&
              LogoLightTheme &&
              LogoLightTheme.value &&
              LogoLightTheme.value.src && (
                <HeaderImageFormat logo={LogoLightTheme.value} />
              )}
            {bgColor === "header-dark" &&
              LogoDarkTheme &&
              LogoDarkTheme.value &&
              LogoDarkTheme.value.src && (
                <HeaderImageFormat logo={LogoDarkTheme.value} />
              )}
            <div className={"mobile-nav" + (showNavMenu ? " hide" : "")}>
              <button className="nav-link search">
                {NavigationFields && NavigationFields.length
                  ? NavigationFields.map((item, index) => {
                    let { Link: ItemLink = null } = item.fields || {};
                    let navigationFieldsLayout;
                    if (
                      ItemLink &&
                      ItemLink.value &&
                      ItemLink.value.text &&
                      ItemLink.value.text.toLowerCase() === "search" &&
                      ItemLink.value.href
                    ) {
                      navigationFieldsLayout = (
                        <a
                          href={ItemLink.value.href}
                          key={`myID-${index}`}
                          onClick={() =>
                            TagManager.dataLayer({
                              dataLayer: {
                                event: "navigationClick",
                                navType: "Main Navigation",
                                navText: "Search",
                              },
                              dataLayerName: "dataLayer",
                            })
                          }
                        >
                          <i className="icon icon-search" />
                        </a>
                      );
                    }
                    return navigationFieldsLayout;
                  })
                  : ""}
              </button>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={this.showNavMenu}
              >
                <i className="icon icon-hamburger" />
              </button>
            </div>

            <div
              className={showNavMenu ? "show-close-icon" : "hide-close-icon"}
            >
              <button
                className="close-button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={this.hideNavMenu}
              >
                <i className="icon icon-close" />
              </button>
            </div>

            <div
              className={`collapse navbar-collapse ${bgColor
                ? bgColor === "header-clear"
                  ? "header-bg"
                  : bgColor
                : ""
                }`}
              id="navbarSupportedContent"
              ref={this.mobileNav}
              style={{ backgroundColor: bgColorHex ? bgColorHex : '' }}
            >
              <ul className="navbar-nav ml-auto">
                {NavigationFields && NavigationFields.length
                  ? NavigationFields.map((item, index) => {
                    const {
                      IsActive: IsActive = null,
                      Link: ItemLink = null,
                      LinkName: ItemName = null,
                      SubNavigations: SubNav = null,
                    } = item.fields || {};
                    const { href: href = null } = ItemLink.value || {};
                    const text = (ItemName && ItemName.value) || null;
                    return (
                      <React.Fragment key={`myFragmentID-${index}`}>
                        {text ? (
                          <React.Fragment>
                            <li
                              className={`nav-item ${(IsActive && IsActive.value) ||
                                (placesActive &&
                                  text.toLowerCase() === "places")
                                ? "active"
                                : ""
                                } ${text && text.toLowerCase() === "search"
                                  ? "d-none d-lg-block"
                                  : ""
                                } ${href ? "nav-link-hover" : ""}`}
                              key={`myID-${index}`}
                              onClick={(e) =>
                                this.handleClick(e, SubNav && SubNav.length)
                              }
                            >
                              {text && text.toLowerCase() === "search" ? (
                                href ? (
                                  <a
                                    href={href}
                                    className="nav-link"
                                    onClick={() =>
                                      TagManager.dataLayer({
                                        dataLayer: {
                                          event: "navigationClick",
                                          navType: "Main Navigation",
                                          navText: "Search",
                                        },
                                        dataLayerName: "dataLayer",
                                      })
                                    }
                                  >
                                    {text}
                                    <i className="icon icon-search ml-2" />
                                  </a>
                                ) : (
                                  <span className="nav-link">
                                    {text}
                                    <i className="icon icon-search ml-2" />
                                  </span>
                                )
                              ) : (
                                <Fragment>
                                  <div className="nav-link-wrapper">
                                    {href ? (
                                      <Link
                                        field={ItemLink}
                                        className="nav-link"
                                        onClick={() =>
                                          TagManager.dataLayer({
                                            dataLayer: {
                                              event: "navigationClick",
                                              navType: "Main Navigation",
                                              navText: text,
                                            },
                                            dataLayerName: "dataLayer",
                                          })
                                        }
                                      >
                                        {text}
                                      </Link>
                                    ) : (
                                      <span className="nav-link">{text}</span>
                                    )}
                                    {SubNav && SubNav.length ? (
                                      <i className="icon subnav-item" />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  {SubNav && SubNav.length ? (
                                    <ul className="navbar-nav-items">
                                      {SubNav.map((sub, index) => {
                                        const {
                                          LinkName: subname,
                                          Link: sublink,
                                        } = sub.fields;
                                        if (
                                          subname &&
                                          subname.value &&
                                          sublink &&
                                          sublink.value &&
                                          sublink.value.href
                                        ) {
                                          return (
                                            <Link
                                              key={"Main Navigation Sub" + index}
                                              field={sublink}
                                              className="nav-link-child"
                                              onClick={() =>
                                                TagManager.dataLayer({
                                                  dataLayer: {
                                                    event: "navigationClick",
                                                    navType:
                                                      "Main Navigation Sub",
                                                    navText: subname.value,
                                                  },
                                                  dataLayerName: "dataLayer",
                                                })
                                              }
                                            >
                                              <li
                                                className="nav-child"
                                                key={`subnavItem${index}`}
                                              >
                                                <Text field={subname} />
                                              </li>
                                            </Link>
                                          );
                                        } else return null;
                                      })}
                                    </ul>
                                  ) : (
                                    ""
                                  )}
                                </Fragment>
                              )}
                            </li>
                            <li className="nav-divider" />
                          </React.Fragment>
                        ) : (
                          ""
                        )}
                      </React.Fragment>
                    );
                  })
                  : ""}
              </ul>
            </div>
          </div>
        </nav>
        {Breadcrumbs && Breadcrumbs.length ? (
          <div className={`breadcrumbs ${isStoryPage ? "story-page" : ""}`}>
            <div className="container">
              <div className="breadcrumbs__wrapper">
                {Breadcrumbs &&
                  Breadcrumbs.map((item, index) => {
                    const { Link = null, LinkName = null } = item.fields;
                    let breadcrumbsLayout;
                    if (index === 0) {
                      breadcrumbsLayout = (
                        <span key={Link.value + LinkName.value + index}>
                          <a
                            href={Link.value}
                            onClick={() =>
                              TagManager.dataLayer({
                                dataLayer: {
                                  event: "navigationClick",
                                  navType: "Breadcrumbs",
                                  navText: (LinkName && LinkName.value) || "",
                                },
                                dataLayerName: "dataLayer",
                              })
                            }
                          >
                            {LinkName.value}
                          </a>
                        </span>
                      );
                    } else {
                      if (Link && Link.value) {
                        breadcrumbsLayout = (
                          <span key={Link.value + LinkName.value + index}>
                            <span>&nbsp;&nbsp;»&nbsp;&nbsp;</span>
                            <a
                              href={Link.value}
                              onClick={() =>
                                TagManager.dataLayer({
                                  dataLayer: {
                                    event: "navigationClick",
                                    navType: "Breadcrumbs",
                                    navText: (LinkName && LinkName.value) || "",
                                  },
                                  dataLayerName: "dataLayer",
                                })
                              }
                            >
                              {LinkName.value}
                            </a>
                          </span>
                        );
                      } else {
                        breadcrumbsLayout = (
                          <span key={Link.value + LinkName.value + index}>
                            <span>&nbsp;&nbsp;»&nbsp;&nbsp;</span>
                            <span>{LinkName.value}</span>
                          </span>
                        );
                      }
                    }
                    return breadcrumbsLayout;
                  })}
              </div>
            </div>
            {IsBreadcrumbsSticky && IsBreadcrumbsSticky.value && (
              <div className="sticky-breadcrumbs">
                <div className="container">
                  <div className="row">
                    <div className="breadcrumbs__wrapper col-12 col-md-5 col-lg-6 col-xl-7">
                      {Breadcrumbs &&
                        Breadcrumbs.map((item, index) => {
                          const { Link = null, LinkName = null } = item.fields;
                          let breadcrumbsLayout;
                          if (index === 0) {
                            breadcrumbsLayout = (
                              <span key={Link.value + LinkName.value + index}>
                                <a
                                  href={Link.value}
                                  onClick={() =>
                                    TagManager.dataLayer({
                                      dataLayer: {
                                        event: "navigationClick",
                                        navType: "Sticky Breadcrumbs",
                                        navText:
                                          (LinkName && LinkName.value) || "",
                                      },
                                      dataLayerName: "dataLayer",
                                    })
                                  }
                                >
                                  {LinkName.value}
                                </a>
                              </span>
                            );
                          } else {
                            if (Link && Link.value) {
                              breadcrumbsLayout = (
                                <span key={Link.value + LinkName.value + index}>
                                  <span>&nbsp;&nbsp;»&nbsp;&nbsp;</span>
                                  <a
                                    href={Link.value}
                                    onClick={() =>
                                      TagManager.dataLayer({
                                        dataLayer: {
                                          event: "navigationClick",
                                          navType: "Sticky Breadcrumbs",
                                          navText:
                                            (LinkName && LinkName.value) || "",
                                        },
                                        dataLayerName: "dataLayer",
                                      })
                                    }
                                  >
                                    {LinkName.value}
                                  </a>
                                </span>
                              );
                            } else {
                              breadcrumbsLayout = (
                                <span key={Link.value + LinkName.value + index}>
                                  <span>&nbsp;&nbsp;»&nbsp;&nbsp;</span>
                                  <span>{LinkName.value}</span>
                                </span>
                              );
                            }
                          }
                          return breadcrumbsLayout;
                        })}
                    </div>
                    {ESGReportNavigation && ESGReportNavigation.length > 0 &&
                      <div className="prev-next__wrapper col-12 col-md-7 col-lg-6 col-xl-5">
                        <div className="prev-next__button-wrapper">
                          {ESGReportNavigation.map((item, index) => {
                            const {
                              ReportTitle = null,
                              ReportNumber = null,
                              IsActive = null,
                            } = item.fields || {};

                            if (IsActive && IsActive.value) {
                              return (
                                <div>
                                  <div className="report-nav-title">
                                    <span id="report-number"> {IsActive && IsActive.value ? String(ReportNumber.value).padStart(2, '0') : ""} </span>
                                    <span id="report-title"> {IsActive && IsActive.value ? ReportTitle.value : ""} </span>
                                  </div>
                                  <div className="jump-to">
                                    <button
                                      onClick={showESGReports}
                                    >
                                      <span> {ESGReportNavJumpToText && ESGReportNavJumpToText.value ? ESGReportNavJumpToText.value : "Jump to"} </span>
                                      <img className="icon" src={UpDownArrows} alt="up-down icon" />
                                    </button>
                                  </div>
                                </div>)
                            }
                          })}
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>)} {/*mobile start*/}
            {IsBreadcrumbsSticky && IsBreadcrumbsSticky.value && (
              <div className={`report-nav-mobile hideSticky ${this.state.isMobile ? "" : "disabled"}`} >
                {ESGReportNavigation.map((item, index) => {
                  const {
                    ReportTitle = null,
                    ReportNumber = null,
                    IsActive = null,
                  } = item.fields || {};

                  if (IsActive && IsActive.value) {
                    return (
                      <div className="container">
                        <div className="report-nav-title">
                          <span id="report-number"> {IsActive && IsActive.value ? String(ReportNumber.value).padStart(2, '0') : ""} </span>
                          <span id="report-title"> {IsActive && IsActive.value ? ReportTitle.value : ""} </span>
                        </div>
                        <div className="jump-to">
                          <button
                            onClick={showESGReports}
                          >
                            <span> {ESGReportNavJumpToText && ESGReportNavJumpToText.value ? ESGReportNavJumpToText.value : "Jump to"} </span>
                            <img className="icon" src={UpDownArrows} alt="up-down icon" />
                          </button>
                        </div>
                      </div>)
                  }
                })}
              </div>
            )} {/*mobile end*/}
            {IsBreadcrumbsSticky && IsBreadcrumbsSticky.value && (
              <div className="report-list hide-esg">
                <div className="container">
                  <Flickity className="carousel" options={flickityOptions} flickityRef={ref => this.flkty = ref} reloadOnUpdate static>
                    {ESGReportNavigation.map((item, index) => {
                      const {
                        ReportTitle = null,
                        ReportNumber = null,
                        ReportImageUrl = null,
                        ReportImageAltText = null,
                        ReportLink = null,
                        IsActive = null,
                      } = item.fields || {};
                      if (IsActive && IsActive.value) if (index !== this.state.reportActive) this.setState({ reportActive: index }) //get/set active report

                      return (
                        <div key={"report-carousel" + index} className={`report-tile ${IsActive && IsActive.value ? "is-active" : ""}`}>
                          {ReportLink && ReportLink.value && this.state.isMobile ? (
                            <a
                              className="a-mobile"
                              href={ReportLink.value}
                              onClick={() =>
                                TagManager.dataLayer({
                                  dataLayer: {
                                    event: "navigationClick",
                                    navType: "Sticky Breadcrumbs",
                                    navText: ReportTitle.value,
                                    navLink: ReportLink.value
                                  },
                                  dataLayerName: "dataLayer",
                                })
                              }
                            >
                              <div className="thumbnail">
                                <img
                                  src={ReportImageUrl && ReportImageUrl.value ? ReportImageUrl.value : ""}
                                  className="carousel-cell"
                                  key={`carousel${index}`}
                                  alt={ReportImageAltText && ReportImageAltText.value ? ReportImageAltText.value : "esg-report_image"}
                                />
                              </div>
                              <div className="report-mobile-text">
                                <p id="report-tile-number"> {ReportNumber && ReportNumber.value ? String(ReportNumber.value).padStart(2, '0') : ""} </p>
                                <p id="report-tile-title"> {ReportTitle && ReportTitle.value ? ReportTitle.value : ""} </p>
                              </div>
                            </a>
                          ) : ReportLink && ReportLink.value && !this.state.isMobile ? (
                            <a
                              href={ReportLink.value}
                              onClick={() =>
                                TagManager.dataLayer({
                                  dataLayer: {
                                    event: "navigationClick",
                                    navType: "Sticky Breadcrumbs",
                                    navText: ReportTitle.value,
                                    navLink: ReportLink.value
                                  },
                                  dataLayerName: "dataLayer",
                                })
                              }
                            >
                              <div className="thumbnail">
                                <img
                                  src={ReportImageUrl && ReportImageUrl.value ? ReportImageUrl.value : ""}
                                  className="carousel-cell"
                                  key={`carousel${index}`}
                                  alt={ReportImageAltText && ReportImageAltText.value ? ReportImageAltText.value : "esg-report_image"}
                                />
                              </div>
                              <div className="report-tile-text">
                                <p id="report-tile-number"> {ReportNumber && ReportNumber.value ? String(ReportNumber.value).padStart(2, '0') : ""} </p>
                                <p id="report-tile-title"> {ReportTitle && ReportTitle.value ? ReportTitle.value : ""} </p>
                              </div>
                            </a>
                          ) : ""}
                        </div>
                      )
                    })}
                  </Flickity>
                </div>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
        {displayPageHeader && !isStoryPage && pageTitle && pageTitle.value && (
          <div className={`extended-header ${noCutout ? "default" : "full"} `}>
            <div className={`${bgColor ? bgColor : "header-light"}`} style={{ backgroundColor: bgColorHex ? bgColorHex : '' }}>
              <div className="container">
                <div className="header-title">
                  <Text field={pageTitle} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default FeatureRetailerNavigationTopNavigation;
