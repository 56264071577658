import React from "react";
import BaseComponent from "../../../Base/BaseComponent";
import ModuleHeader from "../../../Base/ModuleHeader";
import PartnerItems from "../PartnerItems";

const FeaturePartnersFeaturedDesignPartners = (props) => {
  const {
    Title = null,
    VisitAllPartnersText = null,
    VisitAllPartnersUrl = null,
    Partners = null,
    SectionId = null,
  } = props.fields || {};
  return (
    Partners.length !== undefined &&
    Partners.length !== 0 &&
    Partners.length === 4 && (
      <BaseComponent
        margin="standard"
        sectionId={SectionId && SectionId.value ? SectionId.value : ""}
      >
        <div className="partners-wrapper container">
          <ModuleHeader
            title={Title && Title.value ? Title.value : ""}
            url={
              VisitAllPartnersUrl && VisitAllPartnersUrl.value
                ? VisitAllPartnersUrl.value
                : ""
            }
            text={
              VisitAllPartnersText && VisitAllPartnersText.value
                ? VisitAllPartnersText.value
                : ""
            }
            moduleName="Featured Partners"
          />
          <PartnerItems data={Partners} />
        </div>
      </BaseComponent>
    )
  );
};

export default FeaturePartnersFeaturedDesignPartners;
