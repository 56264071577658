import React, { Component } from "react";

class BaseComponent extends Component {
  setMargin = () => {
    if (this.props.margin === "standard") {
      this.margin = "standard";
    } else if (this.props.margin === "footer") {
      this.margin = "footer";
    } else if (this.props.margin === "top-offset") {
      this.margin = "top-offset";
    } else if (this.props.margin === "top-combined") {
      this.margin = "top-combined";
    } else {
      this.margin = "";
    }

    return this.margin;
  };

  setVisible = () => {
    let visible = true;
    if (this.props.setVisible && this.props.setVisible === "hide") {
      visible = false;
    }
    return visible;
  };

  render() {
    if (this.setVisible()) {
      return (
        <div
          className={`base-component ${this.setMargin()} ${
            this.props.overlap ? this.props.overlap : ""
          }`}
          id={this.props.sectionId ? this.props.sectionId : undefined}
        >
          {this.props.children}
        </div>
      );
    }
    return "";
  }
}

export default BaseComponent;
