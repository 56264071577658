import React from "react";
import axios from "axios";
import BaseComponent from "../../../Base/BaseComponent";
import Filter from "../AssetListing/Filter";
import Map from "../AssetListing/Map";
import LazyLoading from "../../../Base/LazyLoading";
import TagManager from "react-gtm-module";
import { getUrlParameter } from "../../../../helper/UrlHelper";
import { addClassLazyload } from "../../../../helper/AddClassLazyloadHelper";
import ModuleHeader from "../../../Base/ModuleHeader";
import { RichText } from "@sitecore-jss/sitecore-jss-react";

class FeatureAssetsLocationsMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLocation: null,
      view: "map",
      currentCountry: "",
      assetCheckbox: ["All"],
      stateCheckbox: ["All"],
      listResult: {},
      showLoader: false,
    };
  }

  componentDidMount() {
    this.getQueries();
  }

  getQueries = () => {
    let qCountry = getUrlParameter("country");
    let country = "";
    let qState = getUrlParameter("state");
    let state = "";

    let qType = getUrlParameter("type");
    let type = "";

    if (qCountry && qCountry.toLowerCase() === "us") {
      country = "United States";
      state = ["US"];
      qState = "";
    } else if (qState) {
      country = "Australia";
      let b = [];
      qState.split(",").forEach((item) => {
        b.push(item.toUpperCase());
      });
      state = b;
      qState = b;
    } else if (qCountry && qCountry.toLowerCase() === "au") {
      country = "Australia";
      state = this.getAustralianStates(
        this.props && this.props.fields && this.props.fields.Locations
      );
    } else {
      state = ["All"];
      qState = "";
    }

    if (qType) {
      let a = [];
      qType.split(",").forEach((item) => {
        a.push(item.charAt(0).toUpperCase() + item.slice(1));
      });
      type = a;
      qType = a;
    } else {
      type = ["All"];
      qType = "";
    }

    if (qCountry || qType || qState) {
      this.setState(
        {
          currentCountry: country,
          stateCheckbox: state,
          assetCheckbox: type,
        },
        () => this.getListData(country, qType, qState)
      );
    } else this.getListData();
  };

  getAustralianStates = (s) => {
    let states = [];
    let list = [];

    if (s && s.length && s[0] && s[0].fields && s[0].fields.StateFilterTokens) {
      list = s[0].fields.StateFilterTokens;

      list.forEach((item) => {
        if (
          item &&
          item.fields &&
          item.fields.Value &&
          item.fields.Value.value
        ) {
          states.push(item.fields.Value.value);
        }
      });
    }

    return states;
  };

  getListData = async (country = "", assetFilter = "", stateFilter = "") => {
    let contextInfo = window.document.getElementsByName("ContextItem")[0];
    let pageId = contextInfo.value;
    let dbName = contextInfo.dataset.targetDb;
    let apiUrl = `/api/sitecore/assetlisting/searchlocation?pageId=${pageId}&dbName=${dbName}&country=${country}&assetFilter=${assetFilter}&stateFilter=${stateFilter}`;

    this.setState({
      showLoader: true,
    });

    let response = await axios.get(apiUrl);
    if (response) {
      this.setState(
        {
          listResult: response.data,
          showLoader: false,
        },
        () => addClassLazyload("list-wrapper")
      );
    }
  };

  applyFilter = () => {
    const { assetCheckbox, stateCheckbox, currentCountry } = this.state;
    let assetFilter, stateFilter;

    if (assetCheckbox.indexOf("All") > -1) {
      assetFilter = "";
    } else {
      assetFilter = assetCheckbox.filter((el) => el !== "All").join();
    }

    if (
      stateCheckbox.indexOf("All") > -1 ||
      (stateCheckbox && stateCheckbox.indexOf("US") > -1)
    ) {
      stateFilter = "";
    } else {
      stateFilter = stateCheckbox.filter((el) => el !== "All").join();
    }
    this.getListData(currentCountry, assetFilter, stateFilter);

    TagManager.dataLayer({
      dataLayer: {
        event: "assetListingFilter",
        assetFilter,
        stateFilter,
      },
      dataLayerName: "dataLayer",
    });
  };

  onCheckboxChange = (checkbox, property, value) => {
    this.setState(
      {
        [checkbox]: value
          ? [...this.state[checkbox], property]
          : this.state[checkbox].filter((el) => el !== property),
      },
      () => {
        if (property === "All" && value) {
          this.setState(
            {
              [checkbox]: ["All"],
            },
            () => this.applyFilter()
          );
        } else {
          if (this.state[checkbox].indexOf("All") > -1) {
            this.setState(
              {
                [checkbox]: this.state[checkbox].filter((el) => el !== "All"),
              },
              () => this.applyFilter()
            );
          } else {
            if (!this.state[checkbox].length) {
              this.setState(
                {
                  [checkbox]: ["All"],
                  currentCountry:
                    checkbox == "stateCheckbox"
                      ? ""
                      : this.state.currentCountry,
                },
                () => {
                  this.applyFilter();
                }
              );
            } else {
              if (checkbox == "stateCheckbox") {
                let newStates = this.state.stateCheckbox;
                if (property === "US") {
                  this.setState({ stateCheckbox: ["US"] }, () =>
                    this.applyFilter()
                  );
                } else {
                  if (newStates.indexOf("US") > -1) {
                    newStates.splice(newStates.indexOf("US"), 1);
                    this.setState({ stateCheckbox: newStates }, () =>
                      this.applyFilter()
                    );
                  } else this.applyFilter();
                }
              } else this.applyFilter();
            }
          }
        }
      }
    );
  };

  showEnquireModal = () => {
    document.getElementsByClassName(
      "modal-enquire-form-leasing"
    )[0].style.display = "block";
  };

  resetCheckbox = () => {
    this.getListData("Australia");
    this.setState({
      stateCheckbox: ["All"],
      assetCheckbox: ["All"],
      selectedLocation: null,
      currentCountry: "Australia"
    });;
  };

  resetSelectedLocation = () => {
    this.setState({ selectedLocation: null });
  };

  onMarkerClick = (location) => {
    this.setState({ selectedLocation: location }, () =>
      addClassLazyload("map-wrapper")
    );
    TagManager.dataLayer({
      dataLayer: {
        event: "assetListingMarker",
        assetMarker:
          location && location.AssetName && location.AssetName.value
            ? location.AssetName.value
            : "Not specified",
      },
      dataLayerName: "dataLayer",
    });
  };

  renderView = (apiKey) => {
    const { listResult, selectedLocation, currentCountry } = this.state;

    let locations =
      listResult &&
      listResult.AssetLists &&
      listResult.AssetLists.map((item) => {
        return { ...item };
      });
    if (locations) {
      return (
        <Map
          locations={locations}
          apiKey={apiKey && apiKey.value ? apiKey.value : ""}
          onMarkerClick={this.onMarkerClick}
          selectedLocation={selectedLocation}
          resetSelectedLocation={this.resetSelectedLocation}
          country={currentCountry || "Australia"}
          showEnquireModal={this.showEnquireModal}
        />
      );
    }
  };

  render() {
    const {
      currentCountry,
      stateCheckbox,
      assetCheckbox,
      showLoader,
    } = this.state;
    const {
      Countries = null,
      AssetTypes = null,
      Title = null,
      DoneText = null,
      ResetText = null,
      ApiKey = null,
      Description = null,
    } = this.props.fields || {};
    if (Countries && Countries.length && AssetTypes && AssetTypes.length) {
      let AssetFilterTokens = [];
      let StateFilterTokens = [];

      AssetTypes.forEach(
        (item) =>
          item &&
          item.fields &&
          item.fields.Value &&
          item.fields.Value.value &&
          AssetFilterTokens.push(item)
      );

      Countries[0] &&
        Countries[0].fields &&
        Countries[0].fields.States &&
        Countries[0].fields.States.length &&
        Countries[0].fields.States.forEach(
          (item) =>
            item &&
            item.fields &&
            item.fields.Abbreviation &&
            item.fields.Abbreviation.value &&
            StateFilterTokens.push({
              fields: {
                Value: item.fields.Abbreviation,
              },
            })
        );
      const locations = [
        {
          fields: {
            Country: {
              value: "Australia",
            },
            AssetFilterTokens,
            StateFilterTokens,
          },
        },
      ];
      return (
        <BaseComponent margin="standard">
          <div className="assetlocation">
            <div className="container">
              <ModuleHeader title={Title && Title.value ? Title.value : ""} />
              {Description && Description.value && (
                <div className="assetlocation-description">
                  <RichText field={Description} />
                </div>
              )}
            </div>
            <div className="filter" id="filter-container">
              <Filter
                PageTitle={Title}
                currentCountry={currentCountry}
                changeCountry={(currentCountry) => {
                  this.setState(
                    { currentCountry, selectedLocation: null }
                    // this.resetCheckbox
                  );
                  TagManager.dataLayer({
                    dataLayer: {
                      event: "assetListingLocation",
                      assetLocation: currentCountry,
                    },
                    dataLayerName: "dataLayer",
                  });
                }}
                locations={locations}
                DoneText={DoneText}
                ResetText={ResetText}
                currentView="map"
                hideView
                stateCheckbox={stateCheckbox}
                assetCheckbox={assetCheckbox}
                onCheckboxChange={this.onCheckboxChange}
                resetCheckbox={() => {
                  this.resetCheckbox();
                  TagManager.dataLayer({
                    dataLayer: {
                      event: "assetListingResetFilter",
                    },
                    dataLayerName: "dataLayer",
                  });
                }}
                applyFilter={this.applyFilter}
              />
            </div>
            <div className="container">
              <div className="view">
                {!showLoader && this.renderView(ApiKey)}
                {showLoader && <LazyLoading />}
              </div>
            </div>
          </div>
        </BaseComponent>
      );
    } else return null;
  }
}

export default FeatureAssetsLocationsMap;
