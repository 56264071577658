import React, { Component } from "react";
import { Link } from "@sitecore-jss/sitecore-jss-react";
import defaultImg from "../../../../../assets/image/transparent.png";

class MediaStep1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      advertisingOpportunity: this.props.formData["advertisingOpportunity"]
    };
  }

  handleNextStep = (onStepChangeFunc, step) => {
    onStepChangeFunc(step, { formType: "media" });
  };

  handleOpportunityClick = (onFormDataChange, value) => {
    const selected = this.state.advertisingOpportunity === value ? "" : value;
    onFormDataChange({ advertisingOpportunity: selected });
    this.setState({ advertisingOpportunity: selected });
  };

  render() {
    const props = this.props;
    const advertisingOpportunity = [
      "",
      "In-centre media",
      "Custom campaigns",
      "Digital promotion"
    ];
    return (
      <div className="form-wrapper">
        <div className="container">
          <div className="row">
            <div className="col step">STEP 1 OF 2</div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-7 title">
              Which type of advertising do you have in mind?
            </div>
          </div>
          <div className="row justify-content-start large-button-section">
            <div className="col-lg-auto col-12">
              <button
                className={
                  "btn btn-dark-primary large " +
                  (this.props.formData["advertisingOpportunity"] ===
                    advertisingOpportunity[1]
                    ? "active"
                    : "")
                }
                onClick={() => {
                  this.handleOpportunityClick(
                    props.onFormDataChange,
                    advertisingOpportunity[1]
                  );
                }}
              >
                <div className="button-content-wrapper">
                  <div className="select-icon-wrapper">
                    <i className="icon icon-check" />
                  </div>
                  <div className="button-image-wrapper">
                    <img
                      alt={props.staticImage.alt}
                      src={defaultImg}
                      data-src={props.staticImage.src}
                      className="lazyload" />
                  </div>
                  <div className="button-title">
                    <div>In-centre media</div>
                  </div>
                  <div className="button-description">
                    Utilise banners, screens and decals in high-traffic areas.
                  </div>
                </div>
              </button>
            </div>
            <div className="col-lg-auto col-12">
              <button
                className={
                  "btn btn-dark-primary large " +
                  (this.props.formData["advertisingOpportunity"] ===
                    advertisingOpportunity[2]
                    ? "active"
                    : "")
                }
                onClick={() => {
                  this.handleOpportunityClick(
                    props.onFormDataChange,
                    advertisingOpportunity[2]
                  );
                }}
              >
                <div className="button-content-wrapper">
                  <div className="select-icon-wrapper">
                    <i className="icon icon-check" />
                  </div>
                  <div className="button-image-wrapper">
                    <img
                      alt={props.partnershipImage.alt}
                      src={defaultImg}
                      data-src={props.partnershipImage.src}
                      className="lazyload" />
                  </div>
                  <div className="button-title">
                    <div>Custom campaigns</div>
                  </div>
                  <div className="button-description">
                    Spread your message across multiple channels and locations.
                  </div>
                </div>
              </button>
            </div>
            <div className="col-lg-auto col-12">
              <button
                className={
                  "btn btn-dark-primary large " +
                  (this.props.formData["advertisingOpportunity"] ===
                    advertisingOpportunity[3]
                    ? "active"
                    : "")
                }
                onClick={() => {
                  this.handleOpportunityClick(
                    props.onFormDataChange,
                    advertisingOpportunity[3]
                  );
                }}
              >
                <div className="button-content-wrapper">
                  <div className="select-icon-wrapper">
                    <i className="icon icon-check" />
                  </div>
                  <div className="button-image-wrapper">
                    <img
                      alt={props.digitalImage.alt}
                      src={defaultImg}
                      data-src={props.digitalImage.src}
                      className="lazyload" />
                  </div>
                  <div className="button-title">
                    <div>Digital promotion</div>
                  </div>
                  <div className="button-description">
                    Leverage our website, eDM and social media audiences.
                  </div>
                </div>
              </button>
            </div>
          </div>
          <div className="row learn-more-section">
            <div className="col">
              <u>
                <Link field={props.learnMore} />
              </u>
            </div>
          </div>
          <div className="row submit-section justify-content-start fixed-row-bottom">
            <div className="col-12">
              <button
                className="btn btn-dark-secondary btn-small"
                disabled={
                  (this.props.formData["advertisingOpportunity"] ===
                    advertisingOpportunity[1] ||
                    this.props.formData["advertisingOpportunity"] ===
                    advertisingOpportunity[2] ||
                    this.props.formData["advertisingOpportunity"] ===
                    advertisingOpportunity[3]) ? false : true
                }
                onClick={() => {
                  this.handleNextStep(props.onStepChange, 2);
                }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MediaStep1;
