import React from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import Button from "../../../Base/Button";
import { getImage } from "../../../../helper/ImageHelper";

class FeatureEnquireLeasingInquiryHighlight extends React.Component {
  constructor(props) {
    super(props);
  }

  handleEnquireButton(type) {
    let modal = "";
    if (type.toLowerCase().indexOf("media") !== -1) {
      modal = document.getElementsByClassName("modal-enquire-form-media")[0];
    } else {
      modal = document.getElementsByClassName("modal-enquire-form-leasing")[0];
    }
    modal.style.display = "block";
    modal.classList.add("enquire-modal-open");
    modal.classList.add("enquire-old");
    if (modal.ownerDocument) {
      modal.ownerDocument.body.classList.add("scroll-disabled-body");
    }
  }

  setVisible = () => {
    if (
      !this.props.rendering.dataSource ||
      this.props.rendering.dataSource === ""
    ) {
      return "hide";
    }
    return "show";
  };

  render() {
    const {
      Title = null,
      Content = null,
      Image: image = null,
      ImageUrl = null,
      ImageAltText = null,
      LeasingCaption = null,
      CTAPosition = null,
      SectionId = null,
      ImageAlignment = null,
      FormType = null
    } = this.props.fields || {};

    let mode = "";
    let {
      MergeWithNextModule = false,
      BackgroundColor = "",
      RemoveDefaultMargin = ""
    } = this.props.params || {};
    let margin =
      RemoveDefaultMargin && RemoveDefaultMargin === "1" ? "" : "standard";

    switch (BackgroundColor) {
      case "Dark":
        mode = "bg-dark";
        break;
      case "Gray":
        mode = "bg-gray";
        break;
      default:
        mode = "bg-none";
    }
    return (
      this.setVisible() === "show" && (
        <BaseComponent
          margin={MergeWithNextModule ? "top-combined" : margin}
          setVisible={this.setVisible()}
          sectionId={SectionId && SectionId.value ? SectionId.value : ""}
        >
          <div
            className={
              "leasing-inquiry-highlight-wrapper " +
              mode +
              (MergeWithNextModule ? " top-combined " : "")
            }
          >
            <div className="container">
              <div className="row">
                <div
                  className={
                    (mode == "bg-none"
                      ? "col col-md-8 col-lg-8 col-xl-8 col-sm-12 col-12 order-12 "
                      : "col ") +
                    (ImageAlignment && ImageAlignment.value == "Left"
                      ? "order-md-12 order-lg-12 order-xl-12 details-right"
                      : "order-md-1 order-lg-1 order-xl-1")
                  }
                >
                  <div className="row">
                    <div className="top-border"></div>
                    <div className="col title">
                      <Text field={Title} />
                    </div>
                  </div>
                  <div className="row enquire-body align-items-start">
                    <div className="col content">
                      <React.Fragment>
                        <RichText field={Content} />
                      </React.Fragment>
                    </div>
                    {LeasingCaption && LeasingCaption.value && (
                      <div
                        className={
                          "col button-wrapper " +
                          (CTAPosition && CTAPosition.value == "Below"
                            ? "col-sm-12 col-12"
                            : "col-md-12 col-lg-4 col-xl-4 col-sm-12 col-12")
                        }
                      >
                        {/* TODO correct the argument passed in handleEnquireButton */}
                        <Button
                          onClick={() => {
                            this.handleEnquireButton(
                              FormType && FormType.value ? FormType.value : ""
                            );
                          }}
                          color={mode == "bg-dark" ? "dark-primary" : "light-primary"}
                          size={"large"}
                          icon={"chevron-up-2"}
                        >
                          <Text field={LeasingCaption} />
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={
                    (mode == "bg-none"
                      ? "col-md-4 col-lg-4 col-xl-4 col-12 order-1 "
                      : " ") +
                    (ImageAlignment && ImageAlignment.value == "Left"
                      ? "order-md-1 order-lg-1 order-xl-1 img-responsive"
                      : "order-md-12 order-lg-12 order-xl-12 img-responsive")
                  }
                >
                  <div className="image-wrapper">
                    <img
                      {...getImage(ImageUrl, ImageAltText, image)}
                      className="image lazyload"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BaseComponent>
      )
    );
  }
}

export default FeatureEnquireLeasingInquiryHighlight;
