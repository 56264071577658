import React from "react";

class FormRadioGroup extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          option: props.value,
          showError: false
      }
    }

    validate() {
        this.setState({
            showError: this.state.option === ''
        });
    }

    onOptionChanged(e) {
        this.setState({
            option: e.currentTarget.value
        });
        this.props.onChange(e.currentTarget.value);
    }

    render() {
        const {
            radioGroupName = "",
            options = [],
            errorMessage
        } = this.props;

        return(
            <div className = "form-radio-group">
                <label className="radio-group-label">
                    {radioGroupName}
                </label>
                <div className = "radio-group-options">
                    {options.map((option, index)=>{
                        return (
                            <label className="field-radio" key={index}>{option.label}
                                <input 
                                    type="radio"
                                    name={option.name}
                                    value={option.value}
                                    checked={this.state.option === option.value}
                                    onChange={(e)=>{this.onOptionChanged(e)}}
                                />
                                <span className="checkmark"/>
                            </label>
                        )
                    })}
                </div>
                <label className={"radio-group-error-message " + (this.state.showError ? "hide" : "")}>
                    {errorMessage}
                </label>
            </div>
        )
    }
}

export default FormRadioGroup;