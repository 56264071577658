import React, { Component } from "react";

class Level extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: true,
      showList: true,
      selectedLevel: ""
    };
  }

  componentDidUpdate() {
    this.initializeSelectedLevel();
  }

  initializeSelectedLevel() {
    if (
      this.props.data &&
      this.props.data.length > 0 &&
      !this.state.selectedLevel
    ) {
      // set the initial level selected
      this.setState({
        ...this.state,
        selectedLevel: this.props.data[0].levelId
      });
    }
  }

  transformData(data) {
    //transform data to named array for easy lookup
    let levels = {};
    if (data && data.length > 0) {
      data.map(level => {
        levels[level.levelId] = {
          shortLabel: level.shortLabel,
          longLabel: level.longLabel
        };
      });
    } else {
      levels = null;
    }
    return levels;
  }

  handleLevelSelection(levelId) {
    this.setState({
      ...this.state,
      selectedLevel: levelId,
      showList: true,
      collapse: true
    });
    this.props.onLevelChange(levelId);
  }

  showOptionList() {
    this.setState({
      ...this.state,
      showList: true
    });
  }

  toggleCollapse() {
    this.setState({
      ...this.state,
      collapse: !this.state.collapse
    });
  }

  createLevelList() {
    const levels = this.transformData(this.props.data);
    const { selectedLevel } = this.state;
    let listItems = [];

    for (let levelId in levels) {
      listItems.push(
        <button type="button"
          key={`levelId-${levelId}`}
          className={'mapcontrols-list-item ' + (levelId == selectedLevel ? 'is-selected' : '')}
          onClick={() => {
            this.handleLevelSelection(levelId);
          }}
        >
          <span className="map-controls-level-short">{levels[levelId].shortLabel}</span>
          <span className="map-controls-level-long">{levels[levelId].longLabel}</span>
        </button>
      );
    }
    return <div className="mapcontrols-level-list">{listItems}</div>;
  }

  render() {
    let { selectedLevel, showList, collapse } = this.state;
    const levels = this.transformData(this.props.data);
    return (
      <div className="mapcontrols-level">
        <a className={'mapcontrols-level-button ' + (collapse ? "is-collapse" : "")}
          onClick={() => {
            this.toggleCollapse();
          }}
          type="button"
        >
          <span className="map-controls-icon-container">
            <span className="map-controls-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                <polygon points="28 3 25 0 8 18 25 36 28 33 14 18 28 3"></polygon></svg>
            </span>
          </span>
          {collapse && (
            <span className="map-controls-level-label">{levels && selectedLevel && levels[selectedLevel].shortLabel}</span>
          )}
        </a>
        {!collapse && (
          <div>
            {showList && this.createLevelList()}
          </div>
        )}
      </div>
    );
  }
}

export default Level;
