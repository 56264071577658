import React from "react";
import { Text, Image } from "@sitecore-jss/sitecore-jss-react";
import defaultImg from "../../../../assets/image/transparent.png";
import TagManager from "react-gtm-module";

const TileStories = (props) => {
  const { Thumbnail, Title, Tags, Date, ItemURL } = props.data;
  return ItemURL && ItemURL.value ? (
    <div className="tile">
      <a
        href={ItemURL.value}
        onClick={() =>
          TagManager.dataLayer({
            dataLayer: {
              event: "clickedModuleCard",
              moduleName: props.moduleName,
              moduleCardLink: ItemURL.value,
            },
            dataLayerName: "dataLayer",
          })
        }
      >
        <div className="thumbnail">
          <Image
            media={{ ...Thumbnail.value, src: defaultImg }}
            data-src={Thumbnail.value.src}
            className="lazyload"
          />
        </div>
        <div className="details">
          <div className="tags-and-date">
            {Tags && Tags.value ? (
              <span className="tags">{Tags.value}</span>
            ) : (
              ""
            )}
            {Tags && Tags.value && Date && Date.value && (
              <span className="date"> | </span>
            )}
            {Date && Date.value && <span className="date">{Date.value}</span>}
          </div>
          <div className="title">
            <Text field={Title} />
          </div>
        </div>
      </a>
    </div>
  ) : (
    ""
  );
};

export default TileStories;
