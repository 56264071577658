import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import { getAncestorElement } from "../../../../helper/GetAncestorElement";
import TagManager from "react-gtm-module";

class FeatureRetailerStoryChapterList extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      if (window) {
        const chapterListContainer = document.getElementsByClassName(
          "retailerstory-storychapterlist"
        )[0];
        if (chapterListContainer) {
          const twoColumnContainer = getAncestorElement(
            chapterListContainer,
            "two-col-layout"
          );
          if (twoColumnContainer) {
            this.stickyChapterList(twoColumnContainer);
          }
        }
        const windowHash = window.location.hash;
        if (windowHash) {
          let chapterListCta = document.querySelector(
            'a[href = "' + windowHash + '"]'
          );
          if (chapterListCta) {
            chapterListCta.click();
          }
        }
      }
    }, 100);
  }

  stickyChapterList = (twoColumnContainer) => {
    let navbar = twoColumnContainer;
    let stickyNavbar = twoColumnContainer.querySelector(".placeholder-wrapper");
    window.addEventListener("scroll", handleScroll);
    function handleScroll() {
      stickyFunction();
    }

    function stickyFunction() {
      let sticky = navbar.offsetTop;
      const stickyBreadcrumbs = document.getElementsByClassName(
        "sticky-breadcrumbs"
      )[0];

      if (window.pageYOffset >= sticky) {
        let topValue = 0;
        let sticky2 =
          navbar.offsetTop +
          navbar.clientHeight -
          stickyNavbar.clientHeight -
          80;
        stickyNavbar.classList.add("is-sticky");

        if (stickyBreadcrumbs) {
          if (window.outerWidth < 1200) {
            topValue = stickyBreadcrumbs.clientHeight - 3;
          } else {
            topValue = stickyBreadcrumbs.clientHeight - 23;
          }
        }

        stickyNavbar.style.top = topValue + "px";
        stickyNavbar.style.width = twoColumnContainer.offsetWidth - 30 + "px";

        if (window.pageYOffset >= sticky2) {
          stickyNavbar.classList.add("is-sticky-absolute");
        } else {
          stickyNavbar.classList.remove("is-sticky-absolute");
        }
      } else {
        stickyNavbar.style.top = "0px";
        stickyNavbar.classList.remove("is-sticky");
      }
    }
  };

  handleOnClick = (event) => {
    const indicator = document.getElementsByClassName(
      "retailerstory-storychapterlist__indicator"
    )[0];
    indicator.style.top = event.target.offsetTop + "px";
    indicator.style.height = event.target.clientHeight + "px";
    TagManager.dataLayer({
      dataLayer: {
        event: "storiesChapters",
        chapterText: event.target.innerText,
      },
      dataLayerName: "dataLayer",
    });
  };

  renderList = () => {
    const { ChapterList = null } = this.props.fields || {};

    if (ChapterList) {
      return ChapterList.map((item, index) => {
        const { ChapterTitle, ChapterId } = item.fields;
        let chapterListCtaLayout;
        if (ChapterTitle && ChapterTitle.value) {
          chapterListCtaLayout = (
            <a
              key={`${ChapterId.value}-${index}`}
              href={`#${ChapterId.value}`}
              className="retailerstory-storychapterlist__cta"
              onClick={this.handleOnClick}
            >
              <Text field={ChapterTitle} />
            </a>
          );
        }
        return chapterListCtaLayout;
      });
    }
  };

  // renderListMobile = () => {
  //   if (this.props.params && this.props.params.chapterList && this.props.params.chapterList.length) {
  //     return this.props.params.chapterList.map((item, index) => {
  //       const { Heading, ChapterId } = item;
  //       return (
  //         <option value={`#${ChapterId.value}`} key={`optionId-${index}`}>
  //           {Heading.value}
  //         </option>
  //       );
  //     });
  //   }
  // };

  setVisible = () => {
    if (
      !this.props.rendering.dataSource ||
      this.props.rendering.dataSource === "" ||
      !this.props.fields ||
      !this.props.fields.ChapterList ||
      !this.props.fields.ChapterList.length
    ) {
      return "hide";
    }
    return "show";
  };

  render() {
    return (
      this.setVisible() === "show" && (
        <BaseComponent setVisible={this.setVisible()}>
          <div className="retailerstory-storychapterlist">
            <div className="wrapper desktop">
              <div className="retailerstory-storychapterlist__title">
                Chapters
              </div>
              <div className="retailerstory-storychapterlist__container">
                {this.renderList()}
                <div className="retailerstory-storychapterlist__indicator" />
              </div>
            </div>

            {/* <div className="wrapper mobile">
            <div className="select">
              <select
                defaultValue={"default"}
                onChange={e => (window.location = e.target.value)}
              >
                <option value="default" disabled>
                  Chapters
                </option>
                {this.renderListMobile()}
              </select>
            </div>
          </div> */}
          </div>
        </BaseComponent>
      )
    );
  }
}

export default FeatureRetailerStoryChapterList;
