import React from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";

const FoundationLayoutTwoColumn7030 = props => {
  return (
    <BaseComponent margin="standard">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8 col-xl-8 two-col-layout">
            <div className="placeholder-wrapper">
              <Placeholder
                name="jss-two-column-70-30-left"
                rendering={props.rendering}
              />
            </div>
          </div>
          <div className="col-12 col-lg-4 col-xl-4 two-col-layout">
            <div className="placeholder-wrapper">
              <Placeholder
                name="jss-two-column-70-30-right"
                rendering={props.rendering}
              />
            </div>
          </div>
        </div>
      </div>
    </BaseComponent>
  );
};

export default FoundationLayoutTwoColumn7030;
