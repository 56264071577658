import React, { Component } from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";

class FeatureOfficeOfficeLocations extends Component {
  constructor(props) {
    super(props);
    this.checkbox = React.createRef();
    if (props.fields.OfficeDetails &&
      props.fields.OfficeDetails[0] &&
      props.fields.OfficeDetails[0].fields &&
      props.fields.OfficeDetails[0].fields.Name
    ) {
      this.defaultValue = props.fields.OfficeDetails[0].fields.Name.value;
      this.defaultDetails = props.fields.OfficeDetails[0].fields;
    }
  }

  state = {
    defaultValue: null,
    defaultDetails: null
  };

  onSelectChange = Value => {
    this.setState({ defaultValue: Value.Name.value, defaultDetails: Value });
    this.checkbox.current.checked = false;
    this.defaultValue = Value.Name.value;
  };

  setVisible = () => {
    if (!this.props.rendering.dataSource || this.props.rendering.dataSource === "") {
      return "hide";
    }
    return "show";
  };

  render() {
    const {
      Heading = null,
      OfficeDetails = null
    } = this.props.fields || {};
    return (this.setVisible() === 'show' &&
      <BaseComponent
        setVisible={this.setVisible()}
      >
        <div className="nearest-offices-container">
          <div className="nearest-offices-title">
            <Text field={Heading} />
          </div>
          <div className="content-wrapper desktop-view">
            {OfficeDetails.map((items, index) => {
              return (
                <div
                  className="nearest-offices-content"
                  key={`officeID` + index}
                >
                  <div className="nearest-offices-name">
                    <Text field={items.fields.Name} />
                  </div>
                  <div className="nearest-offices-address">
                    <RichText field={items.fields.Address} />
                  </div>
                  {items.fields.TelephoneNo && items.fields.TelephoneNo.value &&
                    <div className="nearest-offices-telephone">
                      <a href={`tel:${items.fields.TelephoneNo.value}`}>
                        <Text field={items.fields.TelephoneNo} />
                      </a>
                    </div>
                  }
                  <div className="nearest-offices-faxno">
                    <Text field={items.fields.FaxNo} />
                  </div>
                  <div className="nearest-offices-email">
                    <Text field={items.fields.Email} />
                  </div>
                </div>
              );
            })}
          </div>

          <div className="content-wrapper mobile-view">
            <ul id="offices-dropdown">
              <li>
                <input
                  id="officeLocation"
                  type="checkbox"
                  name="menu"
                  ref={this.checkbox}
                />
                <label htmlFor="officeLocation">
                  {this.state.defaultValue
                    ? this.state.defaultValue
                    : this.defaultValue}
                  <i className="icon icon-chevrondown" />
                </label>
                <ul className="offices-dropmenu">
                  {OfficeDetails.map((items, index) => {
                    if (this.defaultValue !== items.fields.Name.value) {
                      return (
                        <li
                          key={`officedetailsId` + index}
                          onClick={() => this.onSelectChange(items.fields)}
                        >
                          <Text field={items.fields.Name} />
                        </li>
                      );
                    }
                  })}
                </ul>
              </li>
            </ul>
            {!this.state.defaultDetails && this.defaultDetails && (
              <div className="nearest-offices-content">
                <div className="nearest-offices-address">
                  <RichText field={this.defaultDetails.Address} />
                </div>
                {this.defaultDetails.TelephoneNo && this.defaultDetails.TelephoneNo.value &&
                  <div className="nearest-offices-telephone">
                    <a href={`tel:${this.defaultDetails.TelephoneNo.value}`}>
                      <Text field={this.defaultDetails.TelephoneNo} />
                    </a>
                  </div>
                }
                <div className="nearest-offices-faxno">
                  <Text field={this.defaultDetails.FaxNo} />
                </div>
                <div className="nearest-offices-email">
                  <Text field={this.defaultDetails.Email} />
                </div>
              </div>
            )}

            {this.state.defaultDetails && this.defaultDetails && (
              <div className="nearest-offices-content">
                <div className="nearest-offices-address">
                  <RichText field={this.state.defaultDetails.Address} />
                </div>
                {this.state.defaultDetails.TelephoneNo && this.state.defaultDetails.TelephoneNo.value &&
                  <div className="nearest-offices-telephone">
                    <a href={`tel:${this.state.defaultDetails.TelephoneNo.value}`}>
                      <Text field={this.state.defaultDetails.TelephoneNo} />
                    </a>
                  </div>
                }
                <div className="nearest-offices-faxno">
                  <Text field={this.state.defaultDetails.FaxNo} />
                </div>
                <div className="nearest-offices-email">
                  <Text field={this.state.defaultDetails.Email} />
                </div>
              </div>
            )}
          </div>
        </div>
      </BaseComponent>
    );
  }
}

export default FeatureOfficeOfficeLocations;
