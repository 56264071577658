import React, { Component } from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import _ from "lodash";
import $ from "jquery";
import defaultImg from "../../../../assets/image/transparent.png";

class FeatureGenericContentRetailerObjectBlockGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prevScrollLeft: 0,
      activePage: 1,
      totalPage: 0,
      isControlClick: false,
      currentWindowWidth: 0
    };

    this.objectGalleryScrollRef = React.createRef();
    this.objectGalleryScrollHandler = _.debounce(this.objectGalleryScrollHandler, 150);
    this.objectGalleryControlsOnClickHandler = _.debounce(this.objectGalleryControlsOnClickHandler, 150);
  }

  componentDidMount() {
    const {
      Gallery
    } = this.props.fields;

    if (document && Gallery && Gallery.length > 0) {
      let objectBlockGalleryLength = Gallery.length;
      this.setState({
        totalPage: objectBlockGalleryLength,
        currentWindowWidth: window.outerWidth
      })
      window.addEventListener("resize", this.objectGalleryResizeHandler);
      //this.appendScript(Gallery);
    }
  }

  appendScript(objectGallery) {
    if (objectGallery && objectGallery.length > 0) {

      objectGallery.map((item) => {
        let objectValue = item.fields.Object.value
        if (objectValue) {
          let parser = new DOMParser();
          let scripts = parser
            .parseFromString(objectValue, "text/html")
            .body.querySelectorAll("script");
          if (scripts && scripts.length) {
            for (let i = 0; i < scripts.length; i++) {
              $("head").append(String(scripts[i].outerHTML));
            }
          }
        }
      })
    }
  }

  objectGalleryResizeHandler = () => {
    if (this.state.currentWindowWidth !== window.outerWidth) {
      this.objectGalleryScrollRef.current.scrollLeft = 0;
      this.setState({
        activePage: 1,
        prevScrollLeft: 0,
      })
      this.objectGalleryScrollHandler(this.objectGalleryScrollRef, true)
    }
    this.setState({
      currentWindowWidth: window.outerWidth
    })
  }

  objectGalleryScrollHandler = (scroll, isWindow) => {
    const {
      prevScrollLeft,
      isControlClick,
      activePage
    } = this.state;

    let scrollCurrent = scroll.current;

    if (isWindow) {
      scrollCurrent.scrollLeft = 0;
      this.setState({
        activePage: 1,
        prevScrollLeft: 0,
      })
    }
    else if (window.outerWidth <= 991 && !isControlClick) {
      let activeScroll = 0;
      let addValue = (scrollCurrent.clientWidth / 2) - 6;
      let divideValue = (scrollCurrent.clientWidth / 2) + 4
      let newActivePage = 1;
      if (prevScrollLeft < scrollCurrent.scrollLeft) {
        activeScroll = Math.floor((scrollCurrent.scrollLeft + addValue) / divideValue);
      } else {
        activeScroll = Math.floor(scrollCurrent.scrollLeft / divideValue);
      }

      scrollCurrent.scrollLeft = activeScroll * divideValue;

      if (scrollCurrent.scrollWidth - scrollCurrent.clientWidth == scrollCurrent.scrollLeft) {
        if (prevScrollLeft < scrollCurrent.scrollLeft) {
          newActivePage = activeScroll + 1;
        } else {
          newActivePage = activePage;
        }
      } else {
        newActivePage = activeScroll + 1
      }

      this.setState({
        activePage: newActivePage,
        prevScrollLeft: scrollCurrent.scrollLeft
      });
    }
    this.setState({
      isControlClick: false
    });
  }

  objectGalleryControlsOnClickHandler = (isControl, direction, thumbnailPage) => {
    const {
      activePage
    } = this.state;

    let activeItemValue = activePage;
    let activeScroll = 0;
    let scrollWrapper = this.objectGalleryScrollRef.current;
    let scrollMultiplier;

    if (isControl) {
      if (direction === "left" && this.state.activePage > 1) {
        activeItemValue = activePage - 1
      } else if (
        direction === "right" &&
        this.state.activePage < this.state.totalPage
      ) {
        activeItemValue = activePage + 1
      }
    } else {
      activeItemValue = thumbnailPage
    }


    if (window.outerWidth <= 991) {
      scrollMultiplier = (scrollWrapper.clientWidth / 2) + 4;
    } else if (window.outerWidth < 1200) {
      scrollMultiplier = 240;
    } else {
      scrollMultiplier = 285;
    }

    activeScroll = (activeItemValue - 1) * scrollMultiplier
    scrollWrapper.scrollLeft = activeScroll;

    this.setState({
      activePage: activeItemValue,
      prevScrollLeft: activeScroll,
      isControlClick: scrollWrapper.scrollWidth - scrollWrapper.clientWidth == scrollWrapper.scrollLeft ? false : true
    });
  }

  objectGalleryBarDisplay = (activeItem, totalPage) => {
    const total = activeItem * (100 / totalPage);
    return (
      <hr
        className="line"
        style={{
          backgroundImage: `-webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${total}%, rgb(48, 48, 48)), color-stop(${total}%, rgb(150, 150, 150)))`,
          background: `-ms-linear-gradient(left, rgba(48,48,48,1) ${total}%, rgba(150,150,150,1) ${total}%, rgba(150,150,150,1) 100%)`,
        }}
      />
    );
  }

  render() {
    const {
      Header,
      Gallery
    } = this.props.fields;

    const {
      activePage,
      totalPage,
    } = this.state;

    return (
      Gallery &&
      Gallery.length > 0 &&
      <BaseComponent
        margin={'standard'}>
        <div className="container">
          <div className="object-block-gallery">
            {Header &&
              Header.value &&
              <div className="object-block-gallery__title">
                <Text field={Header} />
              </div>
            }
            <div className="object-block-gallery__main">
              {Gallery.map((item, index) => {
                const { Object } = item.fields;
                if (Object && Object.value) {
                  return (
                    <div className={`object-block-gallery__main--item
                        ${index + 1 === activePage ? "is-active" : ""}
                          `}
                      key={`object-block-gallery__main-item-${index}`}>
                      <RichText field={Object} />
                    </div>
                  )
                } else return;

              })
              }
            </div>
            <div className="object-block-gallery__thumbnails"
              ref={this.objectGalleryScrollRef}
              onScroll={() => this.objectGalleryScrollHandler(this.objectGalleryScrollRef, false)}
            >
              {Gallery.map((item, index) => {
                const { Object, Thumbnail, ThumbnailAltText, Caption } = item.fields;

                if (Object && Object.value) {
                  return (
                    <div className="object-block-gallery__thumbnails--item"
                      key={`object-block-gallery__thumbnails--item-${index}`}
                      onClick={() => {
                        this.objectGalleryControlsOnClickHandler(false, "", index + 1)
                      }}
                    >
                      <div className={`object-block-gallery__thumbnails--item-image
                            ${index + 1 === activePage ? "is-active" : ""}
                            `}>
                        <img
                          alt={ThumbnailAltText && ThumbnailAltText.value ? ThumbnailAltText.value : ""}
                          src={defaultImg}
                          data-src={Thumbnail.value}
                          className="lazyload"
                        />
                      </div>
                      {Caption &&
                        Caption.value &&
                        <div className="object-block-gallery__thumbnails--item-label">
                          <Text field={Caption} />
                        </div>
                      }
                    </div>
                  )
                } else return;

              })
              }
            </div>
            {(totalPage > activePage || totalPage === activePage) &&
              <div className="object-block-gallery__controls">
                <div className="object-block-gallery__controls--left">
                  <button
                    disabled={activePage === 1 ? true : false}
                    className="btn btn-link"
                    type="button"
                    onClick={() => this.objectGalleryControlsOnClickHandler(true, "left")}
                  >
                    <i className="icon icon-chevronleft" />
                  </button>
                </div>

                <div className="object-block-gallery__controls--center">
                  <span>
                    {activePage < 10 ? "0" : ""}
                    {activePage}
                  </span>
                  {this.objectGalleryBarDisplay(activePage, totalPage)}
                  <span>
                    {totalPage < 10 ? "0" : ""}
                    {totalPage}
                  </span>
                </div>

                <div className="object-block-gallery__controls--right">
                  <button
                    disabled={activePage === totalPage ? true : false}
                    className="btn btn-link"
                    type="button"
                    onClick={() => this.objectGalleryControlsOnClickHandler(true, "right")}
                  >
                    <i className="icon icon-chevronright" />
                  </button>
                </div>
              </div>
            }
          </div>
        </div>
      </BaseComponent>
    )

  }
}

export default FeatureGenericContentRetailerObjectBlockGallery;
