import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import TagManager from "react-gtm-module";

const FooterSocial = (props) => {
  const { SocialTitle = null, SocialLinks = null } = props.data || {};
  if (SocialTitle && SocialTitle.value && SocialLinks && SocialLinks.length) {
    return (
      <div className="row footer-social-wrapper align-items-center">
        <div className="col-auto title">
          <Text field={SocialTitle} />
        </div>
        {SocialLinks.map((item, index) => {
          const {
            Icon: Icon = null,
            SocialLink: SocialLink = null,
          } = item.fields;
          if (
            Icon &&
            Icon.value &&
            SocialLink &&
            SocialLink.value &&
            SocialLink.value.href
          ) {
            return (
              <div key={`footersocial-${index}`} className="col-auto">
                <a
                  {...SocialLink.value}
                  onClick={() =>
                    TagManager.dataLayer({
                      dataLayer: {
                        event: "clickedModuleCta",
                        moduleName: "Retailer Footer",
                        moduleCtaLink:
                          (SocialLink &&
                            SocialLink.value &&
                            SocialLink.value.href) ||
                          "",
                      },
                      dataLayerName: "dataLayer",
                    })
                  }
                >
                  <i className={`icon icon-${Icon.value}`} />
                </a>
              </div>
            );
          } else return null;
        })}
      </div>
    );
  } else {
    return null;
  }
};

export default FooterSocial;
