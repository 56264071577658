import React, { Component } from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import FormCheckbox from "../../../../Base/Form/FormCheckbox";

class Filter extends Component {
  renderTokens = (locations) => {
    const { AssetFilterTokens } =
      locations && locations.length && locations[0] && locations[0].fields;

    if (AssetFilterTokens && AssetFilterTokens.length) {
      return (
        <div className="row list">
          <div className="col-auto">
            <FormCheckbox
              value="All"
              label="All"
              checked={this.props.assetCheckbox.indexOf("All") > -1}
              type="assetCheckbox"
              onCheckboxChange={this.props.onCheckboxChange}
            />
          </div>
          {AssetFilterTokens.map((item, index) => {
            const { Value } = item.fields;
            return (
              <div className="col-auto">
                <FormCheckbox
                  key={`assetCbId-${index}`}
                  value={Value.value}
                  label={Value.value}
                  checked={this.props.assetCheckbox.indexOf(Value.value) > -1}
                  type="assetCheckbox"
                  onCheckboxChange={this.props.onCheckboxChange}
                />
              </div>
            );
          })}
        </div>
      );
    }
  };
  renderStates = (locations) => {
    const { StateFilterTokens } =
      locations && locations.length && locations[0] && locations[0].fields;

    if (StateFilterTokens && StateFilterTokens.length) {
      return (
        <div className="row list">
          <div className="col-auto">
            <FormCheckbox
              value="All"
              label="All"
              checked={this.props.stateCheckbox.indexOf("All") > -1}
              type="stateCheckbox"
              onCheckboxChange={(type, value, checked) => {
                this.props.onCheckboxChange(type, value, checked);
                this.props.changeCountry("");
              }}
            />
          </div>
          {StateFilterTokens.map((item, index) => {
            const { Value } = item.fields;
            return (
              <div className="col-auto">
                <FormCheckbox
                  key={`stateCbId-${index}`}
                  value={Value.value}
                  label={Value.value}
                  checked={this.props.stateCheckbox.indexOf(Value.value) > -1}
                  type="stateCheckbox"
                  onCheckboxChange={(type, value, checked) => {
                    this.props.onCheckboxChange(type, value, checked);
                    this.props.changeCountry("Australia");
                  }}
                />
              </div>
            );
          })}
          {/* <div className="col-auto">
            <FormCheckbox
              value="US"
              label="US"
              checked={this.props.stateCheckbox.indexOf("US") > -1}
              type="stateCheckbox"
              onCheckboxChange={(type, value, checked) => {
                this.props.onCheckboxChange(type, value, checked);
                this.props.changeCountry("United States");
              }}
            />
          </div> */}
        </div>
      );
    }
  };

  render() {
    const { locations, hideView, bgColor, bgColorHex } = this.props;

    return (
      <div className="assetlisting-filter">
        <div className={`controls-wrapper__mobile ${bgColor}`} style={{ backgroundColor: bgColorHex ? bgColorHex : '' }}>
          <div className="container">

            <div className="controls-wrapper">
              <div className="page-title-mobile">
                <Text field={this.props.PageTitle} />
              </div>
              <div className="item-controls">
                <div className="item">
                  <div className="label">
                    <Text field={this.props.ViewText} />
                  </div>
                  <i
                    onClick={() => this.props.changeView("list")}
                    className={`icon icon-grid ${this.props.currentView === "list" ? "accent" : ""
                      }`}
                  />
                  <i
                    onClick={() => this.props.changeView("map")}
                    className={`icon icon-location ${this.props.currentView === "map" ? "accent" : ""
                      }`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="tokens-wrapper">
            <div className="row">
              <div className="col-12 col-md-9 col-lg-10 filter-items">
                {this.renderTokens(locations)}
              </div>
              {hideView ? (
                ""
              ) : (
                <div className={`col controls-wrapper`}>
                  <div className="item-controls">
                    <div className="item">
                      <div className="label">
                        <Text field={this.props.ViewText} />
                      </div>
                      <i
                        onClick={() => this.props.changeView("list")}
                        className={`icon icon-grid ${this.props.currentView === "list" ? "accent" : ""
                          }`}
                      />
                      <i
                        onClick={() => this.props.changeView("map")}
                        className={`icon icon-location ${this.props.currentView === "map" ? "accent" : ""
                          }`}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="states-wrapper">
            <div className="row">
              <div className="col-12 col-md-9 col-lg-10 filter-items">
                {this.renderStates(locations)}
              </div>
              <div className="col controls-wrapper">
                <div className="item reset">
                  <div onClick={(e) => this.props.resetCheckbox()}>
                    <Text field={this.props.ResetText} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Filter;
