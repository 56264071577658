import React from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import ModuleHeader from "../../../Base/ModuleHeader";
import TagManager from "react-gtm-module";

const FeatureESGESGReportHighlights = (props) => {
  let {
    Heading,
    CTA,
    Description,
    DisplayWholeReports: full,
    Highlights,
  } = props.fields;
  full = (full && full.value) || false;
  if (!full) {
    Highlights = Highlights.slice(0, 6);
  }
  return (
    <BaseComponent margin="standard">
      <div className="container">
        <div className="ESG-report-highlights-wrapper">
          {full ? (
            <div className="description">
              <RichText field={Description} />
            </div>
          ) : (
            <ModuleHeader
              title={Heading.value}
              moduleName="ESG Report Highlights"
              isStandard={true}
            />
          )}
          <div
            className={`ESG-report-highlights-tile-wrapper ${
              full ? "page" : "module"
            }`}
          >
            <div className="row">
              {Highlights.map((item, index) => {
                const {
                  HighlightTitle: title,
                  HighlightSubtitle: subtitle,
                  HighlightIcon: icon,
                  HighlightLink: link,
                  HighlightTheme: theme,
                } = item.fields;
                let colValue = 3;
                if (index == 0 || index % 6 == 0 || index % 6 == 5) {
                  colValue = 6;
                }
                return (
                  <div
                    className={`col-${
                      full ? "12" : "9 col-md-4"
                    } col-lg-${colValue}`}
                    key={`ESG-RH-Item${index}`}
                  >
                    <a
                      {...(link && link.value) || ""}
                      onClick={() =>
                        TagManager.dataLayer({
                          dataLayer: {
                            event: "clickedModuleCard",
                            moduleName: "ESG Report Highlights",
                            moduleCardLink:
                              (link && link.value && link.value.href) || "",
                          },
                          dataLayerName: "dataLayer",
                        })
                      }
                    >
                      <div
                        className={`ESG-report-highlight-tile ${(theme &&
                          theme.value) ||
                          "theme-2"} ${colValue == 3 ? "small" : "large"}`}
                      >
                        <div className="title">
                          <Text field={title} />
                        </div>
                        <div className="subtitle">
                          <Text field={subtitle} />
                        </div>
                        <div className="view">
                          {(link && link.value && link.value.text) ||
                            "View case study"}
                          <i className="icon icon-arrow-right-thick" />
                        </div>
                      </div>
                      {icon && icon.value && (
                        <i className={`icon icon-${icon.value}`} />
                      )}
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
          {!full && CTA && CTA.value && CTA.value.href && CTA.value.text && (
            <div className="cta-link-wrapper">
              <a
                href={CTA.value.href}
                onClick={() =>
                  TagManager.dataLayer({
                    dataLayer: {
                      event: "clickedModuleCta",
                      moduleName: "ESG Report Highlights",
                      moduleCtaLink: CTA.value.href,
                    },
                    dataLayerName: "dataLayer",
                  })
                }
              >
                <span>{CTA.value.text}</span>
                <i className="icon icon-cta">
                  <span className="path1" />
                  <span className="path2" />
                </i>
              </a>
            </div>
          )}
        </div>
      </div>
    </BaseComponent>
  );
};

export default FeatureESGESGReportHighlights;
