import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import defaultImg from "../../../../assets/image/transparent.png";
import BaseComponent from "../../../Base/BaseComponent";
import SectionDivider from "../../../Base/SmallModules/SectionDivider";
import TagManager from "react-gtm-module";

const FeatureESGESGReportLinks = (props) => {
  const { Title = null, Details = null } = props.fields || {};
  if (
    props.rendering.dataSource &&
    props.rendering.dataSource !== "" &&
    Details.length !== undefined &&
    Details.length !== 0
  ) {
    return (
      <BaseComponent margin="standard">
        <div className="container">
          <SectionDivider margin="24" />
          <div className="report-links__wrapper">
            <div className="report-links__section-title font-markpro-bold">
              <Text field={Title} />
            </div>
            <div className="row">
              {Details.map((item, index) => {
                const {
                  ReportImage = null,
                  ReportImageAltText = null,
                  ReportTitle = null,
                  ReportDescription = null,
                  LinkUrl = null,
                  LinkText = null,
                } = item.fields || {};
                return (
                  <div className="col-md-4" key={ReportTitle.value + index}>
                    <a
                      className="report-links__details"
                      href={LinkUrl.value}
                      onClick={() =>
                        TagManager.dataLayer({
                          dataLayer: {
                            event: "clickedModuleCard",
                            moduleName: "ESG Report Links",
                            moduleCardLink: LinkUrl.value,
                          },
                          dataLayerName: "dataLayer",
                        })
                      }
                    >
                      <div className="report-links__image">
                        <img
                          alt={
                            ReportImageAltText && ReportImageAltText.value
                              ? ReportImageAltText.value
                              : "report-link-image"
                          }
                          src={defaultImg}
                          data-src={
                            ReportImage && ReportImage.value
                              ? ReportImage.value
                              : ""
                          }
                          className="lazyload"
                        />
                      </div>
                      <div className="report-links__texts">
                        {ReportTitle && ReportTitle.value && (
                          <div className="report-links__title">
                            <Text field={ReportTitle} />
                          </div>
                        )}
                        {ReportDescription && ReportDescription.value && (
                          <div className="report-links__description font-markpro-regular">
                            <Text field={ReportDescription} />
                          </div>
                        )}
                        {LinkText && LinkText.value && (
                          <div className="report-links__cta font-markpro-bold">
                            <span>{LinkText.value}</span>
                            <i className="icon icon-arrow-right-thick" />
                          </div>
                        )}
                      </div>
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </BaseComponent>
    );
  } else {
    return null;
  }
};

export default FeatureESGESGReportLinks;
