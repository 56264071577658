import React from "react";
import {
  Placeholder,
  VisitorIdentification
} from "@sitecore-jss/sitecore-jss-react";
import Helmet from "react-helmet";
import TagManager from "react-gtm-module";
import "./assets/app.css";
import {
  composeSecondaryNav,
  getHeaderBgColor,
  headerCutout,
  getPageName
} from "./helper/ParamsHelper";
import { get } from "lodash";
import objectFitImages from "object-fit-images";
import "lazysizes";
import BackToTop from "./components/Base/BackToTop/index.js";

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.gtmId = "GTM-K46JWXX";
    this.state = {
      getHeaderParams: null,
      getHeaderBackground: null,
      getBodyParams: null,
      getFooterParams: {
        pageName: null
      },
      url: "",
      domain: ""
    };
  }

  componentDidMount() {
    const route = this.props.route;
    this.paramsHelper(route);
    TagManager.initialize({ gtmId: this.gtmId });

    if (document) {
      // document.querySelector('meta[property="og:url"]').setAttribute("content", window.location.href);
      // document.querySelector('meta[property="og:image"]').setAttribute("content", window.location.origin + get(route, "fields.MetaImage.value.src", ""));
      // document.querySelector('meta[name="twitter:url"]').setAttribute("content", window.location.href);
      // document.querySelector('meta[name="twitter:image"]').setAttribute("content", window.location.origin + get(route, "fields.MetaImage.value.src", ""));

      this.setState({
        url: window.location.href,
        domain: window.location.origin
      });
      // Canonical URL
      let canonicalSrc = get(route, "fields.CanonicalUrl.value", "");
      if (!canonicalSrc) {
        canonicalSrc = document.URL;
      }
      document.getElementById("canonical").href = canonicalSrc;

      objectFitImages();
      this.isSafariBrowser();
    }
  }

  isSafariBrowser = () => {
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1;
    if(isSafariBrowser) {
      document.querySelector("body").classList.add("is-safari");
    }
  }

  paramsHelper = route => {
    this.setState({
      getHeaderParams: { ...composeSecondaryNav(route) },
      getHeaderBackground: {
        ...getHeaderBgColor(route),
        ...headerCutout(route)
      },
      getFooterParams: { pageName: getPageName(route) }
    });
  };

  render() {
    const {
      getHeaderParams,
      getHeaderBackground,
      getBodyParams,
      getFooterParams,
      url,
      domain
    } = this.state;
    const { route, context } = this.props;

    return (
      <React.Fragment>
        {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
        <Helmet>
          <link
            rel="stylesheet"
            href="https://use.fontawesome.com/releases/v5.8.1/css/all.css"
            integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf"
            crossorigin="anonymous"
          />
          <title>
            {route.fields &&
            route.fields.PageTitle &&
            route.fields.PageTitle.value
              ? route.fields.PageTitle.value
              : "Page"}
          </title>
          {route.fields &&
            route.fields.MetaDescription &&
            route.fields.MetaDescription.value && (
              <meta
                name="description"
                content={route.fields.MetaDescription.value}
              />
            )}
          {route.fields &&
            route.fields.RobotsTag &&
            route.fields.RobotsTag.value && (
              <meta name="robots" content={route.fields.RobotsTag.value} />
            )}

          <meta
            property="og:title"
            content={get(route, "fields.PageTitle.value", "")}
          />
          <meta
            property="og:description"
            content={get(route, "fields.MetaDescription.value", "")}
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={url} />
          <meta
            property="og:image"
            content={domain + get(route, "fields.MetaImage.value.src", "")}
          />
          {context.retailerPageInfo && context.retailerPageInfo.siteName && (
            <meta property="og:site_name" itemProp="name" content={context.retailerPageInfo.siteName}/>
          )}

          <meta
            name="twitter:title"
            content={get(route, "fields.PageTitle.value", "")}
          />
          <meta
            name="twitter:description"
            content={get(route, "fields.MetaDescription.value", "")}
          />
          <meta name="twitter:card" content="website" />
          <meta name="twitter:url" content={url} />
          <meta
            name="twitter:image"
            content={domain + get(route, "fields.MetaImage.value.src", "")}
          />
        </Helmet>

        {/*
      VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
      If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
      For XM (CMS-only) apps, this should be removed.

      VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
    */}
        <VisitorIdentification />

        {/* root placeholder for the app, which we add components to using route data */}
        <input
          type="hidden"
          value={route.itemId}
          data-target-db={route.databaseName}
          name="ContextItem"
        />

        <Placeholder
          name="jss-header"
          rendering={route}
          params={getHeaderParams}
          headerparams={getHeaderBackground}
        />
        <Placeholder
          name="jss-body"
          rendering={route}
          params={getBodyParams}
          headerparams={getHeaderBackground}
        />
        <Placeholder
          name="jss-footer"
          rendering={route}
          params={getFooterParams}
        />

        <BackToTop />
      </React.Fragment>
    );
  }
}
export default Layout;
