import React, { Component } from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";

class Checkbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isInvalid: true,
            isTouched: false
        };
    }

    render() {
        const {
            className = null,
            id = null,
            name = null,
            uniqueKey = null,
            handleCheckboxClick = null,
            checked = false,
            isRequired = false,
            errorMessage = null,
            isSubmitted = false,
        } = this.props || {};

        const {
            isInvalid,
            isTouched
        } = this.state;

        return (
            <div className="form-group form-checkbox" key={uniqueKey}>
                <label>
                    <input
                        type="checkbox"
                        className={`${className} ${checked ? "is-active" : ""}`}
                        id={id}
                        name={id}
                        value={name}
                        onClick={e => {
                            handleCheckboxClick(e)
                            this.setState({
                                isInvalid: !e.target.checked,
                                isTouched: true
                            })
                        }}
                        autoComplete="off"
                    />
                    <span className={`${((isTouched || isSubmitted) && isRequired && isInvalid) ? "is-required form-control-error isInvalid" : ""}`}>
                        <RichText field={{ value: name }} />
                    </span>
                </label>
                {(isTouched || isSubmitted) &&
                    isRequired &&
                    isInvalid &&
                    <div className="form-checkbox__error">
                        {errorMessage}
                    </div>
                }

            </div>
        )
    }
};

export default Checkbox;