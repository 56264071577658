import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { Link } from "react-scroll";
import TagManager from "react-gtm-module";

class FeatureRetailerNavigationSecondLevelNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSection: "",
      showMenu: false,
      active: "",
    };
  }

  componentDidMount() {
    const { navList } = this.props.params || {};
    window.onscroll = function () {
      stickyFunction();
    };

    let navbar = document.getElementById("second-navbar");
    let sticky = navbar.offsetTop;

    function stickyFunction() {
      if (window.pageYOffset >= sticky) {
        navbar.classList.add("sticky");
      } else {
        navbar.classList.remove("sticky");
      }
    }

    if (navList && navList.length > 0) {
      this.setState({
        ...this.state,
        currentSection: navList[0].SectionName,
        active: navList[0].SectionId,
      });
    }

    if (window) {
      const windowHash = window.location.hash.replace("#", '');
      if (windowHash) {
        const componentElement = document.getElementById(windowHash);
        setTimeout(() => {
          if (componentElement.getBoundingClientRect().top > 56 || componentElement.getBoundingClientRect().top < 54) {
            const secondNavLink = document.getElementsByClassName(windowHash)[0];
            if (secondNavLink) {
              let yPos = componentElement.getBoundingClientRect().top + window.pageYOffset;
              window.scrollTo({ top: yPos, behavior: 'smooth' });
            }
          }

          setTimeout(() => {
            const windowHash = window.location.hash.replace("#", '');
            if (windowHash) {
              let navListCta = document.querySelector('.' + windowHash);
              if (navListCta) {
                navListCta.click();
              }
            }
          }, 1500);
        }, 1000);
      }
    }
  }

  render() {
    const { navList = null, assetName = null, pageTitle = null } =
      this.props.params || {};

    const { bgColor = null, bgColorHex = null } = this.props.headerparams || {};

    return (
      <div className={`second-level-nagivation-container ${bgColor}`} style={{ backgroundColor: bgColorHex ? bgColorHex : '' }}>
        {/* <div className="container">
          <div className="header">
            <Text
              field={
                assetName && assetName.value
                  ? assetName
                  : pageTitle && pageTitle.value
                    ? pageTitle
                    : null
              }
            />
          </div>
        </div> */}
        <div id="second-navbar" className={bgColor} style={{ backgroundColor: bgColorHex ? bgColorHex : '' }}>
          <div className="container">
            <div className="links">
              <div className="link-list">
                {navList &&
                  navList.map((item, index) => (
                    <div
                      className={`link-item ${this.state.active === item.SectionId ? "active" : ""
                        }`}
                      key={`myID-${index}`}
                    >
                      <Link
                        className={`item ${item.SectionId}`}
                        to={item.SectionId}
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={window && window.outerWidth >= 992 ? -55 : 0}
                        onClick={() => {
                          this.setState({ active: item.SectionId });
                          const element = document.getElementById(item.SectionId);
                          setTimeout(() => {
                            if (element.getBoundingClientRect().top > 56 || element.getBoundingClientRect().top < 54) {
                              let offsetVal = 0;

                              if (window && window.outerWidth >= 992) {
                                offsetVal = 55
                              }
                              let yPos = element.getBoundingClientRect().top + window.pageYOffset - offsetVal;
                              window.scrollTo({ top: yPos, behavior: 'smooth' });
                            }
                          }, 1500);
                          TagManager.dataLayer({
                            dataLayer: {
                              event: "navigationClick",
                              navType: "Secondary Navigation",
                              navText: item.SectionName,
                            },
                            dataLayerName: "dataLayer",
                          });
                        }}
                      >
                        {item.SectionName}
                      </Link>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className={`second-level-bg ${bgColor}`} style={{ backgroundColor: bgColorHex ? bgColorHex : '' }}></div>
        {/* mobile mode 2nd level navigation */}
        <div className="second-level-nagivation-mobile">
          <div className="container">
            {navList && navList.length > 0 && (
              <label className="select">
                <select
                  onChange={(e) => {
                    window.location.href = e.target.value;
                    TagManager.dataLayer({
                      dataLayer: {
                        event: "navigationClick",
                        navType: "Secondary Navigation",
                        navText:
                          e && e.target && e.target.value
                            ? e.target.value.substring(1)
                            : "",
                      },
                      dataLayerName: "dataLayer",
                    });
                  }}
                >
                  {navList &&
                    navList.map((item, index) => (
                      <option
                        value={"#" + item.SectionId}
                        key={`myID-${index}`}
                      >
                        {item.SectionName}
                      </option>
                    ))}
                </select>
              </label>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default FeatureRetailerNavigationSecondLevelNavigation;
