import React from "react";

class FormInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const {
            fieldRef,
            id,
            isRequired,
            errorMessage,
            handleOnChange,
            handleOnBlur,
            handleOnpFocus,
            hasError,
            inputValue,
            placeholder,
        } = this.props;

        return (
            <div className="form-group form-input">
                <input
                    type="text"
                    ref={fieldRef}
                    id={id}
                    required={isRequired ? true : false}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    onFocus={handleOnpFocus}
                    className={`form-input__control 
                        ${inputValue ? "form-input__control--has-value" : ""}
                        ${hasError ? "form-input__control--error isInvalid" : ""}
                    `}
                    autoComplete="off"
                />
                <label htmlFor={id} className="form-input__placeholder">
                    {placeholder}
                    {isRequired && " *"}
                </label>
                {hasError &&
                    <div className="form-input__error">
                        <i className=" form-input__error--icon icon icon-danger">!</i>
                        <label className="form-input__error--label">
                            {errorMessage}
                        </label>
                    </div>
                }
            </div>
        )
    }
}

export default FormInput;