import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";

class FeatureRetailerStoryQuote extends React.Component {
  constructor(props) {
    super(props);
  }

  setVisible = () => {
    if (!this.props.rendering.dataSource || this.props.rendering.dataSource === "") {
      return "hide";
    }
    return "show";
  };

  render() {
    const { Quote = null } = this.props.fields || {};
    return (this.setVisible() === 'show' &&
      <BaseComponent
        margin="standard"
        setVisible={this.setVisible()}
      >
        <div className="retailerstory-quote">
          <div className="container clearfix">
            <div className="content float-right">
              <Text field={Quote} />
            </div>
          </div>
        </div>
      </BaseComponent>
    );
  }
}

export default FeatureRetailerStoryQuote;
