import React from "react";
import { Image } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Base/BaseComponent";
import CustomPlayer from "../../../Base/CustomPlayer";
import defaultImg from "../../../../assets/image/transparent.png";

class FeatureRetailerStoryStoryHero extends React.Component {
  constructor(props) {
    super(props);
  }

  isVisible = (image, video) => {
    let display = "hide";

    if ((image && image.value && image.value.src) ||
      (video && video.value)) {
      display = "show"
    }

    return display;
  };

  renderStoryHero = (image, video, source) => {
    let heroLayout = "";
    if (source && source.value.toLowerCase() === "video" && video && video.value) {
      heroLayout =
        <div className="retailerstory-storyhero__video">
          <CustomPlayer data={video.value} />
        </div>
    } else if (image && image.value && image.value.src) {
      heroLayout =
        <div className="retailerstory-storyhero__image">
          <Image
            media={{ ...image.value, src: defaultImg }}
            data-src={image.value.src}
            className="hero-image-generic lazyload"
          />
        </div>
    }

    return heroLayout;
  }

  render() {
    const {
      HeroImage = null,
      HeroVideo = null,
      HeroSource = null,
    } = this.props.fields || {};

    return (
      <BaseComponent isVisible={this.isVisible(HeroImage, HeroVideo)}>
        <div className="retailerstory-storyhero">
          <div className="container">
            {this.renderStoryHero(HeroImage, HeroVideo, HeroSource)}
          </div>
        </div>
      </BaseComponent>
    );
  }
}

export default FeatureRetailerStoryStoryHero;
