import React from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import TagManager from "react-gtm-module";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { getAncestorElement } from "../../../helper/GetAncestorElement";
import FormInput from "../Form/FormInput";
import Checkbox from "../Checkbox";

class DownloadForm extends React.Component {
    constructor(props) {
        super(props);
        this.inputField = React.createRef();
        this.state = {
            formFieldState: props.FormFields,
            defaultFormFields: JSON.stringify(props.FormFields),
            complete: false,
            submitted: false,
            showForm: true,
            isSuccess: false,
            postData: [],
            show: false,
            showField: null,
            verifiedRecaptcha: false,
            expiredRecaptcha: false,
            load: false,
            recaptchaLoaded: false,
            pageUrl: ""
        };
    }

    componentDidMount = () => {
        setTimeout(() => {
            this.setState({ load: true });
        }, 1500);
        if (window) {
            this.setState({
                pageUrl: window.location.href
            })
        }
    };

    recaptchaLoaded = () => {
        console.log("Successful Load Recaptcha");
        this.setState({ recaptchaLoaded: true });
    };

    recaptchaExpired = () => {
        this.setState({
            verifiedRecaptcha: false,
            expiredRecaptcha: true,
        });
    };
    recaptchaVerifyResponse = (response) => {
        if (response) {
            this.setState({
                verifiedRecaptcha: true,
                expiredRecaptcha: false,
            });
        } else {
            this.setState({ verifiedRecaptcha: false });
        }
    };

    checkFields = () => {
        let data = this.state.formFieldState;
        for (let i = 0; i < data.length; i++) {
            if (data[i].fields.Valid !== true) {
                this.setState({ complete: false });
                break;
            } else {
                this.setState({ complete: true });
            }
        }
    };

    validateField = (e, fields) => {
        let { Name = null } = fields || {};
        const {
            formFieldState
        } = this.state;
        formFieldState.map((items) => {
            let {
                Type,
                Name: fieldName = null,
                IsRequired,
                FormatValidation,
                Value = null,
                Valid = null,
                Touched = null
            } = items.fields || {};

            if (Type.value === "Checkbox Field") {
                if (IsRequired.value) {
                    if (fieldName.value === Name.value) {
                        items.fields.Value = e.target.checked;
                        items.fields.Valid = e.target.checked;
                    }
                } else if (fieldName.value === Name.value) {
                    items.fields.Value = e.target.checked;
                } else {
                    items.fields.Valid = true;
                }
            }
            else if (fieldName.value === Name.value) {
                items.fields.Value = e.target.value;
                items.fields.Touched = true;

                if (e.target.value) {
                    if (FormatValidation.value) {
                        let regExp = new RegExp(FormatValidation.value);
                        if (regExp.test(String(e.target.value).toLowerCase())) {
                            items.fields.Valid = true;
                        } else {
                            items.fields.Valid = false;
                        }
                    } else {
                        items.fields.Valid = true;
                    }
                } else {
                    if (IsRequired.value) {
                        items.fields.Valid = false;
                    } else {
                        items.fields.Valid = true
                    }
                }
            } else {
                if (Value) {
                    if (FormatValidation.value) {
                        let regExp = new RegExp(FormatValidation.value);
                        if (regExp.test(String(Value).toLowerCase())) {
                            items.fields.Valid = true;
                        } else {
                            items.fields.Valid = false;
                        }
                    } else {
                        items.fields.Valid = true;
                    }
                } else {
                    if (IsRequired.value) {
                        items.fields.Valid = false;
                    } else {
                        items.fields.Valid = true
                    }
                }
            }

            this.setState({ formFieldState: formFieldState });
        });

        this.checkFields();
    };

    handleClickClose = () => {
        document.body.classList.remove("scroll-disable");
        this.setState({
            successMessage: null,
            verifiedRecaptcha: false,
            expiredRecaptcha: false,
            submitted: false,
            complete: false
        })
        this.props.onClickClose(JSON.parse(this.state.defaultFormFields));
    }

    handleSubmitBtn = async (e) => {
        e.preventDefault();
        const {
            formFieldState,
            complete,
            verifiedRecaptcha,
            pageUrl,
        } = this.state;
        const {
            fileId,
            gtmComponent
        } = this.props
        let formsWrapper = e.target;
        let formModalWrapper = getAncestorElement(formsWrapper, "download-form__modal");
        let submitBtn = formModalWrapper.querySelector(".submit-btn");
        let recaptchaWrapper = formModalWrapper.querySelector(".form-recaptcha");


        this.checkFields();
        this.setState({ submitted: true });

        setTimeout(() => {
            let invalidField = formsWrapper.querySelector(".isInvalid");
            if (invalidField) {
                invalidField.scrollIntoView();
            }
        }, 250);

        if (recaptchaWrapper) {
            if (!verifiedRecaptcha) {
                recaptchaWrapper.classList.add("is-required");
                return;
            }
        }
        if (!complete) return;

        submitBtn.setAttribute("disabled", "");

        let dataFields = [];
        const url = `/api/sitecore/downloadguidelines/SendFile`;

        // key compose field data
        formFieldState.map((item) => {
            const {
                Type,
                Name,
                Value
            } = item.fields;
            if (Type.value === "Checkbox Field") {
                dataFields.push({
                    FieldName: Name.value,
                    Value: Value ? Value.toString() : "false",
                });
            } else {
                if (Value) {
                    dataFields.push({
                        FieldName: Name.value,
                        Value: Value,
                    });
                }
            }
        });

        let postData = {
            FileId: fileId,
            Fields: dataFields,
        };

        console.log(postData)
        await axios({
            url,
            method: "POST",
            data: postData,
        })
            .then((response) => {
                if (response && response.data && response.data.status) {
                    if (response.data.status.toLowerCase() === "success") {
                        this.setState({
                            showForm: false,
                            isSuccess: true,
                            submitted: false,
                        });

                        TagManager.dataLayer({
                            dataLayer: {
                                event: "downloadFormSubmit",
                                downloadComponent: gtmComponent,
                                downloadPageUrl: pageUrl
                            },
                            dataLayerName: "dataLayer",
                        });
                    }

                    else {
                        this.setState({
                            showForm: false,
                            isSuccess: false,
                            submitted: false,
                        });
                    }
                    formModalWrapper.scrollIntoView();
                }
            })
            .catch((error) => {
                submitBtn.removeAttribute("disabled");
                console.error(error);
                this.setState({
                    showForm: false,
                    isSuccess: false,
                    submitted: false,
                });
            });
    };

    handleOnInputFocus = (e) => {
        if (window.outerWidth < 992) {
            const inputElem = e.target;
            const formModalWrapper = getAncestorElement(inputElem, "download-form__modal");
            const formInputWrapper = getAncestorElement(inputElem, "form-group");
            if(formModalWrapper && formInputWrapper) {
                let inputTop = formInputWrapper.offsetTop + inputElem.clientHeight + 24;
                formModalWrapper.scrollTop = inputTop;
                formModalWrapper.scrollIntoView({ block: "start", behavior: "smooth" });
            }
        }
    };

    renderFormFields = (fieldList) => {
        return fieldList.map((items, index) => {
            const {
                Type,
                Name,
                IsRequired,
                Touched,
                Valid,
                Value,
                Placeholder,
                RequiredValidationMessage,
            } = items.fields;

            let fieldLayout = "";

            switch (Type.value) {
                case "Heading Field":
                    fieldLayout = (
                        <div className="download-form__modal--header" key={"fieldId" + index}>
                            <Text field={Name} />
                        </div>
                    );
                    break;
                case "Text Field":
                case "Email Address Field":
                case "Phone Number field":
                    fieldLayout = (
                        <FormInput
                            key={"fieldId" + index}
                            fieldRef={this.inputField}
                            id={Name.value}
                            isRequired={IsRequired.value ? true : false}
                            handleOnChange={(e) => this.validateField(e, items.fields)}
                            handleOnBlur={(e) => this.validateField(e, items.fields)}
                            handleOnpFocus={this.handleOnInputFocus}
                            hasError={(this.state.submitted || Touched) && Valid !== true && (IsRequired.value || Valid === false)}
                            placeholder={Placeholder.value}
                            errorMessage={RequiredValidationMessage.value}
                            inputValue={Value}
                        />
                    );
                    break;
                case "Checkbox Field":
                    fieldLayout = (
                        <Checkbox
                            key={"fieldId" + index}
                            id={Name.value}
                            name={Placeholder.value}
                            handleCheckboxClick={(event) => {
                                this.validateField(event, items.fields);
                            }}
                            isSubmitted={this.state.submitted}
                            isRequired={IsRequired.value}
                            errorMessage={RequiredValidationMessage.value}
                        />
                    );
                    break;
                default:
                    fieldLayout = "";
            }

            return fieldLayout;
        });
    };

    render() {
        const {
            FormFields = null,
            EnableRecaptcha = null,
            PublicKey = null,
            PrivacyPolicy = null,
            SuccessMessageHeader,
            SuccessMessage,
            FailedMessage,
            showModal,
            modalDownloadLink
        } = this.props || {};

        const {
            formFieldState,
            load,
            verifiedRecaptcha,
            expiredRecaptcha,
            isSuccess,
            showForm
        } = this.state;

        if (showModal) {
            return (
                <div className="download-form__modal">
                    <div className="container">
                        <div className="download-form__modal--close">
                            <button className="download-form__modal--close__btn"
                                onClick={this.handleClickClose}
                            >
                                <i className="icon icon-close-2" />
                            </button>
                        </div>
                        {showForm ?
                            <div className="download-form__modal--form-container">
                                <form onSubmit={this.handleSubmitBtn} noValidate className="download-form__modal--form">
                                    {FormFields &&
                                        FormFields.length > 0 &&
                                        this.renderFormFields(formFieldState)}
                                    {load &&
                                        EnableRecaptcha &&
                                        EnableRecaptcha.value &&
                                        PublicKey &&
                                        PublicKey.value && (
                                            <div
                                                className={
                                                    "form-group form-recaptcha " +
                                                    (verifiedRecaptcha
                                                        ? "verifiedRecaptcha "
                                                        : " ") +
                                                    (expiredRecaptcha ? "is-required" : " ")
                                                }
                                            >
                                                <ReCAPTCHA
                                                    size="normal"
                                                    sitekey={
                                                        PublicKey && PublicKey.value
                                                            ? PublicKey.value
                                                            : "6LeqAfYUAAAAAGK1oZ1NzfVUFeC4kqJOe16XklGg"
                                                    }
                                                    onChange={this.recaptchaVerifyResponse}
                                                    onExpired={this.recaptchaExpired}
                                                    asyncScriptOnLoad={this.recaptchaLoaded}
                                                />
                                                <div className="form-recaptcha__error">
                                                    {"ReCaptcha is required"}
                                                </div>
                                            </div>
                                        )}
                                    {PrivacyPolicy && PrivacyPolicy.value && (
                                        <div className="form-group form-text">
                                            <RichText field={PrivacyPolicy} />
                                        </div>
                                    )}
                                    <div className="download-form__modal--button">
                                        <button
                                            className="btn btn-large btn-light-primary submit-btn"
                                            type="submit"
                                        >
                                            <span className="btn-label">{"Submit"}</span>
                                            <i className="icon icon-chevron-up-2" />
                                        </button>
                                    </div>
                                </form>
                            </div>
                            :
                            <div className="download-form__modal--success">
                                <div className="download-form__modal--header">
                                    <RichText field={isSuccess ? SuccessMessageHeader : FailedMessage} />
                                </div>
                                {isSuccess &&
                                    <div>
                                        <div className="download-form__modal--subheader">
                                            <RichText field={SuccessMessage} />
                                        </div>
                                        <a href={modalDownloadLink} className="btn btn-light-primary btn-large">
                                            <span className="btn-label">
                                                {"Download"}
                                            </span>
                                            <i className="icon icon-download" />
                                        </a>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>

            );
        } else return null;
    }
}

export default DownloadForm